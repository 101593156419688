"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnaffirmedErrors = exports.getAffirmedErrors = exports.parseErrorResponse = exports.CsvValidationErrors = void 0;
const maybe_1 = require("@freckle/maybe");
exports.CsvValidationErrors = {
    csvUnknownFileEncoding: 'csv-unknown-file-encoding',
    csvParseError: 'csv-parse-exception',
    csvMissingColumn: 'csv-missing-column',
    schoolNotFound: 'school-not-found',
    duplicateId: 'duplicate-id',
    duplicateEmail: 'duplicate-email',
    studentNotEnrolled: 'student-not-enrolled',
    enrollmentStudentNotFound: 'enrollment-student-not-found',
    enrollmentCourseNotFound: 'enrollment-course-not-found',
    courseTeacherNotFound: 'course-teacher-not-found',
    malformedLanguage: 'malformed-language',
    unsupportedLanguage: 'unsupported-language',
    all() {
        return [
            exports.CsvValidationErrors.csvUnknownFileEncoding,
            exports.CsvValidationErrors.csvParseError,
            exports.CsvValidationErrors.csvMissingColumn,
            exports.CsvValidationErrors.schoolNotFound,
            exports.CsvValidationErrors.duplicateId,
            exports.CsvValidationErrors.duplicateEmail,
            exports.CsvValidationErrors.studentNotEnrolled,
            exports.CsvValidationErrors.enrollmentStudentNotFound,
            exports.CsvValidationErrors.enrollmentCourseNotFound,
            exports.CsvValidationErrors.courseTeacherNotFound,
            exports.CsvValidationErrors.malformedLanguage,
            exports.CsvValidationErrors.unsupportedLanguage,
        ];
    },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const safeParseResponseValidationErrors = (response) => {
    try {
        if (response !== undefined && response.responseText !== undefined) {
            const validationErrors = JSON.parse(response.responseText).validationErrors;
            return Array.isArray(validationErrors) ? validationErrors : [];
        }
        return null;
    }
    catch (err) {
        console.warn('Error parsing JSON from response', response);
        return null;
    }
};
function parseErrorResponse(response) {
    const mValidationErrors = safeParseResponseValidationErrors(response);
    if (mValidationErrors === null) {
        return [{ tag: 'unaffirmed', error: { name: 'Unknown error', message: 'This operation failed.' } }];
    }
    else {
        return mValidationErrors.map(error => {
            const isAffirmed = exports.CsvValidationErrors.all().includes(error === null || error === void 0 ? void 0 : error.name);
            return { tag: isAffirmed ? 'affirmed' : 'unaffirmed', error };
        });
    }
}
exports.parseErrorResponse = parseErrorResponse;
function getAffirmedErrors(errors) {
    return (0, maybe_1.mapMaybes)(errors, error => (error.tag === 'affirmed' ? error.error : null));
}
exports.getAffirmedErrors = getAffirmedErrors;
function getUnaffirmedErrors(errors) {
    return (0, maybe_1.mapMaybes)(errors, error => (error.tag === 'unaffirmed' ? error.error : null));
}
exports.getUnaffirmedErrors = getUnaffirmedErrors;
