"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnswerCorrectness = exports.parseMathQuestionGraphLinearMetadata = void 0;
const parser_1 = require("@freckle/parser");
const equation_1 = require("@freckle/educator-entities/ts/math/common/logic/equation");
const common_parser_1 = require("@freckle/educator-entities/ts/math/common/models/questions/common-parser");
const rawGraphLinearEquationCoefficientsAttrs = (0, parser_1.record)({
    coefficientX1: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-x-1'),
    coefficientX0: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-x-0'),
});
const parseGraphLinearEquationCoefficients = (0, parser_1.firstOf)((0, parser_1.record)({
    graphLinearEquationCoefficients: (0, parser_1.field)(rawGraphLinearEquationCoefficientsAttrs, 'graph-linear-equation-coefficients'),
}), (0, parser_1.record)({
    graphLinearEquationCoefficients: (0, parser_1.field)((0, parser_1.obfuscated)(rawGraphLinearEquationCoefficientsAttrs), 'obfuscated-graph-linear-equation-coefficients'),
}));
const parseMathQuestionGraphLinearAttrs = (0, parser_1.merge)(common_parser_1.parseCommonMathGraphQuestionAttrs, parseGraphLinearEquationCoefficients);
exports.parseMathQuestionGraphLinearMetadata = (0, parser_1.record)({
    answerType: (0, parser_1.field)((0, parser_1.literal)('graph_linear'), 'answer-type'),
    content: (0, parser_1.onSelf)(parseMathQuestionGraphLinearAttrs),
});
// Return :: Double (either 0 or 100)
function getAnswerCorrectness(metadata, answer) {
    const { graphLinearEquationCoefficients } = metadata;
    const allCorrect = (0, equation_1.validatePointsForLinearEquation)(answer.points, graphLinearEquationCoefficients);
    return allCorrect ? 100 : 0;
}
exports.getAnswerCorrectness = getAnswerCorrectness;
