"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Usage = void 0;
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const date_helper_1 = require("@freckle/educator-entities/ts/common/helpers/date-helper");
const button_1 = require("@freckle/educator-materials/ts/components/button");
const school_picker_1 = require("@freckle/school/ts/common/components/common/school-picker");
const date_range_picker_1 = require("@freckle/school/ts/common/components/common/date-range-picker");
const grade_picker_1 = require("@freckle/school/ts/pages/growth/components/grade-picker");
const summary_1 = require("@freckle/school/ts/common/models/summary");
const idp_picker_1 = require("@freckle/school/ts/common/components/common/idp-picker");
const practice_type_picker_1 = require("@freckle/school/ts/common/components/common/practice-type-picker");
const periscope_embed_1 = require("@freckle/school/ts/common/components/common/periscope-embed");
const usage_module_scss_1 = require("./usage.module.scss");
function Usage(props) {
    const { schools, initSchoolId, summary, onChangeSchool, onAllSchools } = props;
    const { t } = (0, translate_1.useSafeTranslation)();
    const [schoolId, setSchoolId] = React.useState(initSchoolId);
    const [grade, setGrade] = React.useState();
    const [idpOrigins, setIdpOrigins] = React.useState({ tag: 'all' });
    const [practiceTypes, setPracticeTypes] = React.useState({ tag: 'none' });
    const [startDate, setStartDate] = React.useState(date_helper_1.defaultStartDate);
    const [endDate, setEndDate] = React.useState(date_helper_1.defaultEndDate);
    const periscopeEmbedData = {
        tag: 'school_usage',
        schoolId,
        grade,
        idpOrigins,
        practiceTypes,
        startDate,
        endDate,
    };
    const [currentSchoolId, showingAllSchools] = schoolId !== undefined ? [schoolId, false] : [0, true];
    return (React.createElement("div", { className: usage_module_scss_1.usageOverview },
        React.createElement(typography_1.H1, null, t('USAGE_H1_HEADING')),
        React.createElement(button_1.Button, { addClass: usage_module_scss_1.printButton, style: "secondary", onClick: window.print },
            React.createElement("i", { className: "fa fa-print" }),
            " ",
            t('USAGE_BUTTON_PRINT')),
        React.createElement("div", { className: usage_module_scss_1.filters },
            React.createElement(school_picker_1.SchoolPicker, { schools: schools, currentSchoolId: currentSchoolId, onChange: schoolId => {
                    onChangeSchool(schoolId);
                    setSchoolId(schoolId);
                }, onAllSchools: () => {
                    onAllSchools();
                    setSchoolId(undefined);
                }, showingAllSchools: showingAllSchools }),
            React.createElement(grade_picker_1.GradePicker, { currentGrade: grade, onChangeGrade: grade => {
                    setGrade(grade);
                } }),
            React.createElement(date_range_picker_1.DateRangePicker, { startDate: startDate, endDate: endDate, onChangeStartDate: setStartDate, onChangeEndDate: setEndDate }),
            React.createElement(idp_picker_1.IdpPicker, { summary: summary, getCountForIdp: summary_1.getStudentCountForIdp, currentIdps: idpOrigins, onChange: setIdpOrigins }),
            React.createElement(practice_type_picker_1.PracticeTypePicker, { selected: practiceTypes, onChange: setPracticeTypes })),
        React.createElement(periscope_embed_1.PeriscopeEmbed, { data: periscopeEmbedData })));
}
exports.Usage = Usage;
