"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
const React = __importStar(require("react"));
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const exhaustive_1 = require("@freckle/exhaustive");
const maybe_1 = require("@freckle/maybe");
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const container_1 = require("@freckle/educator-materials/ts/components/container");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const alert_module_scss_1 = require("./alert.module.scss");
function Alert(props) {
    const { dataTest, children, type, icon: mIcon, dismissible, onDismiss, addClass } = props;
    const { icon, containerStyle } = getIconAndContainerStyles(type, mIcon);
    const [isDismissed, setDismissed] = React.useState(false);
    function onClose() {
        setDismissed(true);
        if (onDismiss !== undefined) {
            onDismiss();
        }
    }
    const iconNode = icon !== null && icon !== undefined ? React.createElement("div", { className: alert_module_scss_1.iconWrapper }, icon) : null;
    return (React.createElement(React.Fragment, null, !isDismissed && (React.createElement(container_1.Container, { dataTest: dataTest, addClass: (0, classnames_1.addMaybeClassName)(containerStyle, addClass), size: "sm" },
        iconNode,
        React.createElement(typography_1.Text, { style: "body-2", addClass: alert_module_scss_1.textStyle, htmlElement: "div" }, children),
        (dismissible === true || onDismiss !== undefined) && (React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faTimes, className: alert_module_scss_1.dismissIcon, "data-test": "close-button", 
            // @ts-ignore this doesn't seem to exist on `FontAwesomeIcon`'s types
            onClick: onClose }))))));
}
exports.Alert = Alert;
function getIconAndContainerStyles(type, mIcon) {
    switch (type) {
        case 'warning':
            return {
                icon: (React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: (0, maybe_1.fromMaybe)(() => free_solid_svg_icons_1.faExclamationTriangle, mIcon), className: alert_module_scss_1.warningIcon })),
                containerStyle: alert_module_scss_1.warningContainer,
            };
        case 'danger':
            return {
                icon: (React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: (0, maybe_1.fromMaybe)(() => free_solid_svg_icons_1.faExclamationCircle, mIcon), className: alert_module_scss_1.dangerIcon })),
                containerStyle: alert_module_scss_1.dangerContainer,
            };
        case 'success':
            return {
                icon: (React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: (0, maybe_1.fromMaybe)(() => free_solid_svg_icons_1.faCheckCircle, mIcon), className: alert_module_scss_1.successIcon })),
                containerStyle: alert_module_scss_1.successContainer,
            };
        case 'info':
            return {
                icon: (React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: (0, maybe_1.fromMaybe)(() => free_solid_svg_icons_1.faInfoCircle, mIcon), className: alert_module_scss_1.infoIcon })),
                containerStyle: alert_module_scss_1.infoContainer,
            };
        case 'premium':
            return {
                icon: (0, maybe_1.maybe)(() => null, icon => React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: icon, className: alert_module_scss_1.premiumIcon }), mIcon),
                containerStyle: alert_module_scss_1.premiumContainer,
            };
        default:
            return (0, exhaustive_1.exhaustive)(type, 'AlertTypeT');
    }
}
