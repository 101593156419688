"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Languages = void 0;
const parser_1 = require("@freckle/parser");
const supported_language_1 = require("./supported-language");
exports.Languages = Object.assign({ parseT() {
        return (0, parser_1.map)((0, parser_1.string)(), 'LangT', supported_language_1.SupportedLanguages.unsafeParse);
    }, format: supported_language_1.SupportedLanguages.mkMatch({
        onEn: () => 'English',
        onEs: () => 'Spanish',
        onEnGB: () => 'British English',
    }) }, supported_language_1.SupportedLanguages);
