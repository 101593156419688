"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSocialStudiesWritingMetadata = exports.parseSocialStudiesReadingMetadata = void 0;
const parser_1 = require("@freckle/parser");
const articles_1 = require("@freckle/educator-entities/ts/common/models/generic-assignment-session/articles");
exports.parseSocialStudiesReadingMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('social_studies_articles_reading'),
    contents: articles_1.parseResumableArticleContents,
});
exports.parseSocialStudiesWritingMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('social_studies_articles_writing'),
    contents: articles_1.parseArticleContents,
});
