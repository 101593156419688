"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Summary = void 0;
const React = __importStar(require("react"));
const exhaustive_1 = require("@freckle/exhaustive");
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const summary_panel_1 = require("@freckle/school/ts/manage-teachers/components/summary/summary-panel");
const idp_sync_popover_1 = require("@freckle/school/ts/manage-teachers/components/summary/idp-sync-popover");
const routes_1 = require("@freckle/school/ts/common/routers/routes");
const teacher_signup_popover_1 = require("./teacher-signup-popover");
const summary_module_scss_1 = require("./summary.module.scss");
function Summary(props) {
    const { currentSchoolIds, summary, scrollToTabs } = props;
    const { teacherCounts, studentCounts, courseCounts } = summary;
    const { t } = (0, translate_1.useSafeTranslation)();
    const sortFirstType = getSortFirstType(summary);
    return (React.createElement("div", { className: summary_module_scss_1.panels },
        React.createElement(summary_panel_1.SummaryPanel, { title: t('MANAGE_TEACHERS_TEACHER_SUMMARY_PANEL_TITLE'), linkUrl: dashboardWithTab(currentSchoolIds, 'teacher-tab'), counts: [
                {
                    type: 'admin',
                    count: teacherCounts.upload,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'teacher-tab', {
                        tag: routes_1.TEACHER_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['csv'],
                    }),
                },
                {
                    type: 'teacher',
                    count: teacherCounts.manual + teacherCounts.unknown + teacherCounts.idpSync,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'teacher-tab', {
                        tag: routes_1.TEACHER_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['none'],
                    }),
                    popOver: React.createElement(teacher_signup_popover_1.TeacherSignupPopover, { id: "teacher-summary-teacher-signup" }),
                },
                {
                    type: 'classlink',
                    count: teacherCounts.classlink,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'teacher-tab', {
                        tag: routes_1.TEACHER_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['class-link'],
                    }),
                },
                {
                    type: 'clever',
                    count: teacherCounts.clever,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'teacher-tab', {
                        tag: routes_1.TEACHER_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['clever'],
                    }),
                },
                {
                    type: 'renaissance',
                    count: teacherCounts.renaissance,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'teacher-tab', {
                        tag: routes_1.TEACHER_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['renaissance'],
                    }),
                },
            ], scrollToTabs: scrollToTabs, sortFirstType: sortFirstType }),
        React.createElement(summary_panel_1.SummaryPanel, { title: t('MANAGE_TEACHERS_STUDENT_SUMMARY_PANEL_TITLE'), linkUrl: dashboardWithTab(currentSchoolIds, 'student-tab'), counts: [
                {
                    type: 'admin',
                    count: studentCounts.upload,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'student-tab', {
                        tag: routes_1.STUDENT_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['csv'],
                    }),
                },
                {
                    type: 'teacher',
                    count: studentCounts.manual + studentCounts.unknown,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'student-tab', {
                        tag: routes_1.STUDENT_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['none'],
                    }),
                    popOver: React.createElement(teacher_signup_popover_1.TeacherSignupPopover, { id: "student-summary-teacher-sign-up" }),
                },
                {
                    type: 'classlink',
                    count: studentCounts.classlink,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'student-tab', {
                        tag: routes_1.STUDENT_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['class-link'],
                    }),
                },
                {
                    type: 'clever',
                    count: studentCounts.clever,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'student-tab', {
                        tag: routes_1.STUDENT_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['clever'],
                    }),
                },
                {
                    type: 'renaissance',
                    count: studentCounts.renaissance,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'student-tab', {
                        tag: routes_1.STUDENT_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['renaissance'],
                    }),
                },
                {
                    type: 'idpsync',
                    count: studentCounts.idpSync,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'student-tab', {
                        tag: routes_1.STUDENT_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['none'],
                    }),
                    popOver: React.createElement(idp_sync_popover_1.IDPSyncPopover, { id: "student-summary-idp-sync" }),
                },
            ], scrollToTabs: scrollToTabs, sortFirstType: sortFirstType }),
        React.createElement(summary_panel_1.SummaryPanel, { title: t('MANAGE_TEACHERS_COURSE_SUMMARY_PANEL_TITLE'), linkUrl: dashboardWithTab(currentSchoolIds, 'course-tab'), counts: [
                {
                    type: 'admin',
                    count: courseCounts.upload,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'course-tab', {
                        tag: routes_1.COURSE_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['csv'],
                    }),
                },
                {
                    type: 'teacher',
                    count: courseCounts.teacher,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'course-tab', {
                        tag: routes_1.COURSE_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['none'],
                    }),
                    popOver: React.createElement(teacher_signup_popover_1.TeacherSignupPopover, { id: "course-summary-teacher-signup" }),
                },
                {
                    type: 'classlink',
                    count: courseCounts.classlink,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'course-tab', {
                        tag: routes_1.COURSE_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['class-link'],
                    }),
                },
                {
                    type: 'clever',
                    count: courseCounts.clever,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'course-tab', {
                        tag: routes_1.COURSE_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['clever'],
                    }),
                },
                {
                    type: 'renaissance',
                    count: courseCounts.renaissance,
                    linkUrl: dashboardWithTab(currentSchoolIds, 'course-tab', {
                        tag: routes_1.COURSE_FILTER_QUERY_PARAM_TAG,
                        identityProviders: ['renaissance'],
                    }),
                },
            ], scrollToTabs: scrollToTabs, sortFirstType: sortFirstType })));
}
exports.Summary = Summary;
function dashboardWithTab(currentSchoolIds, activeTab, additionalFilters) {
    return routes_1.Routes.dashboard({
        schoolIds: currentSchoolIds,
        activeTab,
        additionalFilters,
    });
}
function getSortFirstType({ mostRecentRosterSyncEvent }) {
    if (mostRecentRosterSyncEvent === null || mostRecentRosterSyncEvent === undefined) {
        return null;
    }
    else {
        switch (mostRecentRosterSyncEvent.syncedBy.tag) {
            case 'idp':
                switch (mostRecentRosterSyncEvent.syncedBy.contents) {
                    case 'clever':
                        return 'clever';
                    case 'class-link':
                        return 'classlink';
                    case 'renaissance':
                        return 'renaissance';
                    default:
                        return null;
                }
            case 'school_admin':
                return 'admin';
            case 'console_agent':
                return null;
            case 'impersonation':
                return null;
            default:
                return (0, exhaustive_1.exhaustive)(mostRecentRosterSyncEvent.syncedBy.tag);
        }
    }
}
