"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnswerCorrectness = exports.formatInequality = exports.parseMathQuestionGraphLinearInequalityMetadata = void 0;
const parser_1 = require("@freckle/parser");
const exhaustive_1 = require("@freckle/exhaustive");
const equation_1 = require("@freckle/educator-entities/ts/math/common/logic/equation");
const common_parser_1 = require("@freckle/educator-entities/ts/math/common/models/questions/common-parser");
const rawLinearEquationCoefficientsAttrs = (0, parser_1.record)({
    coefficientX1: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-x-1'),
    coefficientX0: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-x-0'),
});
// This is almost the same as the in graph-question-linear but the field names are different
const parseGraphLinearInequalityEquationCoefficients = (0, parser_1.firstOf)((0, parser_1.record)({
    graphLinearInequalityEquationCoefficients: (0, parser_1.field)(rawLinearEquationCoefficientsAttrs, 'graph-linear-inequality-equation-coefficients'),
}), (0, parser_1.record)({
    graphLinearInequalityEquationCoefficients: (0, parser_1.field)((0, parser_1.obfuscated)(rawLinearEquationCoefficientsAttrs), 'obfuscated-graph-linear-inequality-equation-coefficients'),
}));
const rawGraphLinearInequalityInequalityAttrs = (0, parser_1.oneOf)('InequalityT', [
    'lt',
    'le',
    'gt',
    'ge',
]);
const parseGraphLinearInequalityInequality = (0, parser_1.firstOf)((0, parser_1.record)({
    graphLinearInequalityInequality: (0, parser_1.field)(rawGraphLinearInequalityInequalityAttrs, 'graph-linear-inequality-inequality'),
}), (0, parser_1.record)({
    graphLinearInequalityInequality: (0, parser_1.field)((0, parser_1.obfuscated)(rawGraphLinearInequalityInequalityAttrs), 'obfuscated-graph-linear-inequality-inequality'),
}));
const parseMathQuestionGraphLinearInequalityExtraAttrs = (0, parser_1.merge)(parseGraphLinearInequalityInequality, parseGraphLinearInequalityEquationCoefficients);
const parseMathQuestionGraphLinearInequalityAttrs = (0, parser_1.merge)(common_parser_1.parseCommonMathGraphQuestionAttrs, parseMathQuestionGraphLinearInequalityExtraAttrs);
exports.parseMathQuestionGraphLinearInequalityMetadata = (0, parser_1.record)({
    answerType: (0, parser_1.field)((0, parser_1.literal)('graph_linear_inequality'), 'answer-type'),
    content: (0, parser_1.onSelf)(parseMathQuestionGraphLinearInequalityAttrs),
});
// Assumes we're in not using dangerously-set-inner-html
function formatInequality(inequality) {
    switch (inequality) {
        case 'lt':
            return '<';
        case 'gt':
            return '>';
        case 'le':
            return '≤';
        case 'ge':
            return '≥';
        default:
            return (0, exhaustive_1.exhaustive)(inequality, 'InequalityT');
    }
}
exports.formatInequality = formatInequality;
// Return :: Double (either 0 or 100)
function getAnswerCorrectness(metadata, answer) {
    const { graphLinearInequalityEquationCoefficients, graphLinearInequalityInequality } = metadata;
    const { points, inequality } = answer;
    const allCorrect = (0, equation_1.validateLinearInequationEquation)(points, inequality, graphLinearInequalityEquationCoefficients, graphLinearInequalityInequality);
    return allCorrect ? 100 : 0;
}
exports.getAnswerCorrectness = getAnswerCorrectness;
