"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const link_1 = require("@freckle/educator-materials/ts/components/link");
const button_1 = require("@freckle/educator-materials/ts/components/button");
const input_1 = require("@freckle/educator-entities/ts/common/components/base/input");
const centered_page_1 = require("@freckle/school/ts/common/components/common/centered-page");
const routes_1 = require("@freckle/school/ts/common/routers/routes");
const login_view_module_scss_1 = require("./login-view.module.scss");
function LoginView(props) {
    const { t } = (0, translate_1.useSafeTranslation)();
    const { email, password, disabled, onChangeEmail, onChangePassword, onSubmitLogin } = props;
    const supportNumberLink = React.createElement(link_1.Link, { href: "tel:+1-800-338-4204", reactRouter: false });
    const supportLink = React.createElement(link_1.Link, { href: "mailto:freckle-support@renaissance.com", reactRouter: false });
    return (React.createElement(centered_page_1.CenteredPage, null,
        React.createElement(typography_1.H1, null, t('LOGIN_PAGE_TITLE')),
        React.createElement(typography_1.H3, null, t('LOGIN_PAGE_DESCRIPTION_TEXT')),
        React.createElement("form", { action: "", method: "post", onSubmit: onSubmitLogin, className: login_view_module_scss_1.form },
            React.createElement("div", { className: login_view_module_scss_1.formGroup },
                React.createElement(input_1.Input, { className: "form-control", name: "email", onChange: onChangeEmail, type: "email", placeholder: t('LOGIN_PAGE_EMAIL_PLACEHOLDER_TEXT'), autoCorrect: "off", required: true, value: email, disabled: disabled, dataTest: "login-email-input" })),
            React.createElement("div", { className: login_view_module_scss_1.formGroup },
                React.createElement(input_1.Input, { className: "form-control", name: "password", onChange: onChangePassword, type: "password", autoComplete: "off", placeholder: t('LOGIN_PAGE_PASSWORD_PLACEHOLDER_TEXT'), value: password, disabled: disabled, dataTest: "login-password-input" }),
                React.createElement(link_1.Link, { href: routes_1.Routes.forgotPassword(), addClass: login_view_module_scss_1.forgotPassword, reactRouter: true }, t('LOGIN_PAGE_FORGOT_PASSWORD_LINK_TEXT'))),
            React.createElement(button_1.Button, { style: "primary", type: "submit", disabled: disabled, dataTest: "login-submit" }, t('LOGIN_PAGE_LOGIN_BUTTON_TEXT'))),
        React.createElement("div", { className: login_view_module_scss_1.footer },
            React.createElement(translate_1.SafeTrans, { i18nKey: "LOGIN_PAGE_CREATE_ACCOUNT_INFO_TEXT", components: { supportNumberLink, supportLink } }))));
}
exports.default = LoginView;
