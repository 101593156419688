"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONFIG = void 0;
const parser_1 = require("@freckle/parser");
const parseConfigAttrs = (0, parser_1.record)({
    BUGSNAG_API_KEY: (0, parser_1.string)(),
    BUGSNAG_APP_VERSION: (0, parser_1.string)(),
    COMMIT_UNIX_TIMESTAMP: (0, parser_1.string)(),
    COMMIT_SHA: (0, parser_1.string)(),
    LOCIZE_API_KEY: (0, parser_1.string)(),
});
// fail if the config is not what we expect
exports.CONFIG = parser_1.Parser.run(FRECKLE_CONFIG, parseConfigAttrs);
