"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentTabHandler = void 0;
const React = __importStar(require("react"));
const maybe_1 = require("@freckle/maybe");
const use_params_1 = require("@freckle/school/ts/hooks/use-params");
const student_tab_1 = require("@freckle/school/ts/manage-teachers/components/student-tab/student-tab");
const filters_1 = require("@freckle/school/ts/manage-teachers/api-helpers/filters");
const STUDENT_FILTER_TAG = 'student-filters';
function StudentTabHandler(props) {
    const { tableParams, summary, ...rest } = props;
    // Course filter
    const courseId = (0, use_params_1.useQueryParamAsNum)('courseId');
    const courseName = (0, use_params_1.useQueryParamAsString)('courseName');
    const course = courseId !== null && courseName !== null
        ? { id: courseId, name: decodeURIComponent(courseName) }
        : null;
    // Teacher filter
    const teacherId = (0, use_params_1.useQueryParamAsNum)('teacherId');
    const teacherName = (0, use_params_1.useQueryParamAsString)('teacherName');
    const teacher = teacherId !== null && teacherName !== null
        ? { id: teacherId, name: decodeURIComponent(teacherName) }
        : null;
    // Grade filter
    const grade = (0, use_params_1.useQueryParamAsNum)('grade');
    // Source filter
    const mSource = (0, use_params_1.useQueryParamAsString)('source');
    const source = (0, maybe_1.mthen)(mSource, s => (s === 'admin' || s === 'non-admin' ? s : null));
    // Table filters
    const studentName = (0, use_params_1.useQueryParamAsString)('studentName');
    const sisId = (0, use_params_1.useQueryParamAsNum)('sisId');
    const identityProviders = (0, filters_1.parseDefaultIdpsFromQueryParam)((0, use_params_1.useQueryParamAsString)('identityProviders'), {
        tag: 'for-student',
        ...summary,
    }, { tag: 'summary' });
    const tableParamsWithFilter = {
        ...tableParams,
        additionalFilters: {
            tag: STUDENT_FILTER_TAG,
            course,
            teacher,
            grade,
            source: source,
            studentName,
            sisId,
            teacherName,
            identityProviders,
        },
    };
    return React.createElement(student_tab_1.StudentTabContainer, { tableParams: tableParamsWithFilter, summary: summary, ...rest });
}
exports.StudentTabHandler = StudentTabHandler;
