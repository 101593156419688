"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpinnerOverlay = exports.CenteredSpinner = exports.Spinner = void 0;
const React = __importStar(require("react"));
const lodash_1 = require("lodash");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_hooks_1 = require("@freckle/react-hooks");
const exhaustive_1 = require("@freckle/exhaustive");
const spinner_module_scss_1 = require("./spinner.module.scss");
const SPINNER_DELAY = 300;
function Spinner(props) {
    const { delay, size = 'md', invertedColor } = props;
    const [showSpinner, setShowSpinner] = React.useState(delay !== true);
    const [timer, setTimer] = React.useState(null);
    (0, react_hooks_1.useSafeEffectExtraDeps)(({ timer }) => {
        if (delay === true && timer === null) {
            setTimer(setTimeout(() => setShowSpinner(true), SPINNER_DELAY));
        }
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
                setTimer(null);
            }
        };
    }, [delay], {
        timer: {
            value: timer,
            comparator: lodash_1.isEqual,
        },
    });
    return showSpinner ? (React.createElement(SpinnerIcon, { size: getSizeStyle(size), invertedColor: invertedColor === true })) : null;
}
exports.Spinner = Spinner;
function getSizeStyle(size) {
    switch (size) {
        case 'lg':
            return '5x';
        case 'md':
            return '3x';
        case 'sm':
            return 'md';
        default:
            return (0, exhaustive_1.exhaustive)(size, 'SizeT');
    }
}
function CenteredSpinner(props) {
    return (React.createElement("div", { className: spinner_module_scss_1.wrapper },
        React.createElement(Spinner, Object.assign({}, props))));
}
exports.CenteredSpinner = CenteredSpinner;
function SpinnerIcon({ size, invertedColor, }) {
    const spinnerClass = invertedColor ? spinner_module_scss_1.invertedSpinner : spinner_module_scss_1.spinner;
    return (React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSpinner, spin: true, size: size, className: spinnerClass, "data-test": "spinner" }));
}
function SpinnerOverlay(props) {
    return (React.createElement("div", { className: spinner_module_scss_1.overlayWrapperStyle, "data-test": "spinner-overlay" },
        React.createElement("div", { className: spinner_module_scss_1.spinnerContainerStyle, "data-test": "spinner-box" },
            React.createElement(Spinner, Object.assign({}, props)))));
}
exports.SpinnerOverlay = SpinnerOverlay;
