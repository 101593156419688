"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTeacherTableRows = void 0;
const parser_1 = require("@freckle/parser");
const identity_provider_1 = require("@freckle/educator-entities/ts/roster/identity-provider");
const teacher_role_1 = require("@freckle/educator-entities/ts/common/helpers/teacher-role");
const premium_licenses_1 = require("@freckle/educator-entities/ts/common/models/premium-licenses");
function parse(response) {
    const schoolSimplifiedAttrs = (0, parser_1.record)({
        id: (0, parser_1.number)(),
        name: (0, parser_1.string)(),
    });
    const studentSimplifiedAttrs = (0, parser_1.record)({
        id: (0, parser_1.number)(),
        firstName: (0, parser_1.string)(),
        lastName: (0, parser_1.string)(),
    });
    const attrs = (0, parser_1.record)({
        id: (0, parser_1.number)(),
        firstName: (0, parser_1.string)(),
        lastName: (0, parser_1.string)(),
        email: (0, parser_1.string)(),
        grades: (0, parser_1.array)((0, parser_1.number)()),
        school: schoolSimplifiedAttrs,
        students: (0, parser_1.array)(studentSimplifiedAttrs),
        role: (0, parser_1.nullable)((0, parser_1.oneOf)('TeacherRole', teacher_role_1.TeacherRoles.all())),
        source: (0, parser_1.nullable)(identity_provider_1.parser),
        premiumLicenses: (0, parser_1.nullable)(premium_licenses_1.parsePremiumLicensesAttrs),
    });
    return parser_1.Parser.run(response, attrs);
}
function parseTeacherTableRows(response) {
    return response.map(parse);
}
exports.parseTeacherTableRows = parseTeacherTableRows;
