"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flatOptions = exports.groupedOptions = void 0;
function groupedOptions(groups) {
    return {
        tag: 'grouped',
        groups,
    };
}
exports.groupedOptions = groupedOptions;
function flatOptions(options) {
    return { tag: 'flat', options };
}
exports.flatOptions = flatOptions;
