"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnswerCorrectness = exports.parseMathQuestionGraphExponentialMetadata = void 0;
const parser_1 = require("@freckle/parser");
const equation_1 = require("@freckle/educator-entities/ts/math/common/logic/equation");
const common_parser_1 = require("@freckle/educator-entities/ts/math/common/models/questions/common-parser");
const rawGraphExponentialEquationCoefficientsAttrs = (0, parser_1.record)({
    coefficientA: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-a'),
    coefficientB: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-b'),
    coefficientC: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-c'),
});
const parseGraphExponentialEquationCoefficients = (0, parser_1.firstOf)((0, parser_1.record)({
    graphExponentialEquationCoefficients: (0, parser_1.field)(rawGraphExponentialEquationCoefficientsAttrs, 'graph-exponential-equation-coefficients'),
}), (0, parser_1.record)({
    graphExponentialEquationCoefficients: (0, parser_1.field)((0, parser_1.obfuscated)(rawGraphExponentialEquationCoefficientsAttrs), 'obfuscated-graph-exponential-equation-coefficients'),
}));
const parseMathQuestionGraphExponentialAttrs = (0, parser_1.merge)(common_parser_1.parseCommonMathGraphQuestionAttrs, parseGraphExponentialEquationCoefficients);
exports.parseMathQuestionGraphExponentialMetadata = (0, parser_1.record)({
    answerType: (0, parser_1.field)((0, parser_1.literal)('graph_exponential'), 'answer-type'),
    content: (0, parser_1.onSelf)(parseMathQuestionGraphExponentialAttrs),
});
// Return :: Double (either 0 or 100)
function getAnswerCorrectness(metadata, answer) {
    const { graphExponentialEquationCoefficients } = metadata;
    const isCorrect = (0, equation_1.validatePointsForExponentialEquation)(answer.points, graphExponentialEquationCoefficients);
    return isCorrect ? 100 : 0;
}
exports.getAnswerCorrectness = getAnswerCorrectness;
