"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidatedImpression = exports.getSplitIOBrowserSDKAPIKey = void 0;
function getSplitIOBrowserSDKAPIKey(env) {
    const defaultKey = 'lnqpuohqcfl7srcm173s23sj3htbl3fign6g';
    switch (env) {
        case 'localhost':
        case 'staging':
        case 'test':
            return defaultKey;
        case 'production':
            return 'ukfgq0emo384oaoe46ju5u82vrekn0q9fdtl';
        default: {
            console.warn(`Environment not recognized. Using localhost SplitIO Api Key`);
            return defaultKey;
        }
    }
}
exports.getSplitIOBrowserSDKAPIKey = getSplitIOBrowserSDKAPIKey;
function getValidatedImpression(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
impressionData) {
    if (impressionData === null || impressionData === undefined) {
        console.error('SplitIO Impression Listener', 'impressionData is null/undefined');
        return null;
    }
    const { impression } = impressionData;
    if (impression === null || impression === undefined || typeof impression !== 'object') {
        console.error('SplitIO Impression Listener', 'impression is null/undefined/not an object');
        return null;
    }
    const { keyName, feature, label, treatment } = impression;
    if (keyName === null ||
        keyName === undefined ||
        feature === null ||
        feature === undefined ||
        label === null ||
        label === undefined ||
        treatment === null ||
        treatment === undefined) {
        console.error('SplitIO Impression Listener', 'keyName/feature/label is null/undefined');
        return null;
    }
    return impressionData.impression;
}
exports.getValidatedImpression = getValidatedImpression;
