"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrectAnswersForReport = exports.CorrectAnswers = void 0;
const React = __importStar(require("react"));
const exhaustive_1 = require("@freckle/exhaustive");
const enable_math_jax_1 = require("@freckle/educator-materials/ts/components/enable-math-jax");
const bucket_answer_1 = require("@freckle/educator-materials/ts/math/common/components/correct-answers/bucket-answer");
const formatted_answer_1 = require("@freckle/educator-materials/ts/math/common/components/correct-answers/formatted-answer");
const graph_answer_1 = require("@freckle/educator-materials/ts/math/common/components/correct-answers/graph-answer");
const fill_in_the_blanks_1 = require("@freckle/educator-materials/ts/math/common/components/correct-answers/fill-in-the-blanks");
const fill_in_the_blanks_report_answers_1 = require("@freckle/educator-materials/ts/math/common/components/correct-answers/fill-in-the-blanks-report-answers");
const CorrectAnswersForQuestion = (props) => {
    const { question, format } = props;
    switch (format.tag) {
        case 'formatted-answer':
            return (React.createElement(formatted_answer_1.FormattedAnswer, { metadata: format.metadata.content, alternationToken: getAlternationToken(format.metadata.answerType) }));
        case 'bucket-answer':
            return React.createElement(bucket_answer_1.BucketAnswer, { metadata: format.metadata, format: format.format });
        case 'graph-answer':
            return React.createElement(graph_answer_1.GraphAnswer, { metadata: format.metadata, format: format.format });
        case 'fill-in-the-blanks':
            switch (format.format) {
                case 'report':
                    return React.createElement(fill_in_the_blanks_report_answers_1.FillInTheBlanksReportCorrectAnswers, { metadata: format.metadata });
                case 'non-interactive-ui':
                    return (React.createElement(fill_in_the_blanks_1.MathQuestionFillInTheBlanks, { question: question, metadata: format.metadata, revealCorrectAnswers: true }));
                default:
                    return (0, exhaustive_1.exhaustive)(format.format);
            }
        default:
            return (0, exhaustive_1.exhaustive)(format);
    }
};
const CorrectAnswers = (props) => {
    const { format, question } = props;
    return (React.createElement(enable_math_jax_1.EnableMathJax, null,
        React.createElement(CorrectAnswersForQuestion, { question: question, format: format })));
};
exports.CorrectAnswers = CorrectAnswers;
const CorrectAnswersForReport = ({ question }) => {
    const { metadata } = question;
    switch (metadata.answerType) {
        case 'bucket':
            return (React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'bucket-answer', format: 'text', metadata: metadata.content } }));
        case 'singular_or':
            return React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'formatted-answer', metadata } });
        case 'multiple_choice':
            return React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'formatted-answer', metadata } });
        case 'multiple_answers':
            return React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'formatted-answer', metadata } });
        case 'fill_in_the_blanks':
            return (React.createElement(exports.CorrectAnswers, { question: question, format: {
                    tag: 'fill-in-the-blanks',
                    metadata: metadata.content,
                    format: 'report',
                } }));
        case 'graph_exponential':
            return (React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'graph-answer', metadata, format: 'text' } }));
        case 'graph_linear_inequality':
            return (React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'graph-answer', metadata, format: 'text' } }));
        case 'graph_linear':
            return (React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'graph-answer', metadata, format: 'text' } }));
        case 'graph_quadratic':
            return (React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'graph-answer', metadata, format: 'text' } }));
        case 'graph_scatter_points':
            return (React.createElement(exports.CorrectAnswers, { question: question, format: { tag: 'graph-answer', metadata, format: 'text' } }));
        default:
            return (0, exhaustive_1.exhaustive)(metadata);
    }
};
exports.CorrectAnswersForReport = CorrectAnswersForReport;
const getAlternationToken = (answerType) => {
    switch (answerType) {
        case 'singular_or':
            return 'or';
        case 'multiple_choice':
            return 'or';
        case 'multiple_answers':
            return 'and';
        default:
            return (0, exhaustive_1.exhaustive)(answerType);
    }
};
