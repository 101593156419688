"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCommonMathGraphQuestionAttrs = exports.parsePoint = void 0;
const parser_1 = require("@freckle/parser");
exports.parsePoint = (0, parser_1.record)({
    x: (0, parser_1.number)(),
    y: (0, parser_1.number)(),
});
exports.parseCommonMathGraphQuestionAttrs = (0, parser_1.record)({
    graphShowCoordinates: (0, parser_1.field)((0, parser_1.boolean)(), 'graph-show-coordinates'),
    graphGridLowerBound: (0, parser_1.field)(exports.parsePoint, 'graph-grid-lower-bound'),
    graphGridUpperBound: (0, parser_1.field)(exports.parsePoint, 'graph-grid-upper-bound'),
    graphGridStepX: (0, parser_1.field)((0, parser_1.number)(), 'graph-grid-step-x'),
    graphGridStepY: (0, parser_1.field)((0, parser_1.number)(), 'graph-grid-step-y'),
});
