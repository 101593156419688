"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportedLanguages = void 0;
const exhaustive_1 = require("@freckle/exhaustive");
const maybe_1 = require("@freckle/maybe");
const En = 'en';
const Es = 'es';
const EnGB = 'en-GB';
// Get all SupportedLanguage values
function all() {
    return [En, Es, EnGB];
}
// Parse SupportedLanguage from a raw string, returns null if no match
function parse(raw) {
    var _a;
    return (_a = all().find(e => e === raw)) !== null && _a !== void 0 ? _a : null;
}
// Parse SupportedLanguage from a raw string, returns default if no match
function parseWithDefault(raw, def) {
    var _a;
    return (_a = parse(raw)) !== null && _a !== void 0 ? _a : def;
}
// Parse SupportedLanguage from a raw string, throws if no match
function unsafeParse(raw) {
    return (0, maybe_1.fromJust)(parse(raw), `Invalid SupportedLanguage: ${raw}`);
}
// Make a function that will dispatch cases for SupportedLanguage
function mkMatch({ onEn, onEs, onEnGB }) {
    return (v) => {
        switch (v) {
            case 'en':
                return onEn();
            case 'es':
                return onEs();
            case 'en-GB':
                return onEnGB();
            default:
                return (0, exhaustive_1.exhaustive)(v);
        }
    };
}
function match(v, cases) {
    return mkMatch(cases)(v);
}
exports.SupportedLanguages = {
    // Constants
    En,
    Es,
    EnGB,
    // Enumeration
    all,
    // Parsing
    parse,
    unsafeParse,
    parseWithDefault,
    // Case dispatch
    mkMatch,
    match,
};
