"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const maybe_1 = require("@freckle/maybe");
const table_1 = require("@freckle/educator-materials/ts/components/table/table");
const table_2 = require("@freckle/educator-materials/ts/logic/table/table");
const common_bootstrap_table_helper_1 = require("@freckle/educator-entities/ts/common/helpers/common-bootstrap-table-helper");
const expandable_row_1 = require("./expandable-row");
const utils_1 = require("./utils");
function ProficiencyReportTable({ students, tableData, teachers, selectedGrade, selectedDomainId, mathStandardSet, schools, }) {
    const { t } = (0, translate_1.useSafeTranslation)();
    return (React.createElement("div", { className: "bootstrap-table-container proficiency-report-table-wrapper" },
        React.createElement(table_1.Table, { data: tableData, SubRow: (row) => (React.createElement(expandable_row_1.ProficiencyReportExpandableRow, { tableRow: row, students: students, teachers: teachers, selectedGrade: selectedGrade, selectedDomainId: selectedDomainId, mathStandardSet: mathStandardSet })), columns: [
                {
                    Header: t('PROFICIENCY_REPORT_TABLE_COLUMN_HEADER_SCHOOL'),
                    Cell: (0, table_2.cellFromRow)((row) => React.createElement(SchoolName, { row: row, schools: schools })),
                    ...(0, table_2.idAccessorFromProp)('schoolId'),
                },
                {
                    Header: t('PROFICIENCY_REPORT_TABLE_COLUMN_HEADER_GRADE_LEVEL'),
                    accessor: (d) => (0, common_bootstrap_table_helper_1.formatRoundedNumber)(2)(d.averageGradeLevel),
                    id: 'level',
                },
                {
                    Header: React.createElement(translate_1.SafeTrans, { i18nKey: "PROFICIENCY_REPORT_TABLE_COLUMN_HEADER_BELOW" }),
                    accessor: (d) => d.studentGroups.belowGradeLevel.length,
                    id: 'below',
                },
                {
                    Header: React.createElement(translate_1.SafeTrans, { i18nKey: "PROFICIENCY_REPORT_TABLE_COLUMN_HEADER_WITHIN" }),
                    accessor: (d) => d.studentGroups.withinGradeLevel.length,
                    id: 'within',
                },
                {
                    Header: React.createElement(translate_1.SafeTrans, { i18nKey: "PROFICIENCY_REPORT_TABLE_COLUMN_HEADER_ABOVE" }),
                    accessor: (d) => d.studentGroups.aboveGradeLevel.length,
                    id: 'above',
                },
                {
                    accessor: (d) => d.studentGroups,
                    Cell: (0, table_2.cellFromRow)(({ studentGroups }) => (React.createElement(StudentsProgressBar, { studentGroups: studentGroups }))),
                    id: 'progress-bar',
                    ...(0, table_2.aligned)('center'),
                    disableSortBy: table_2.disableSortBy,
                },
            ].map(c => ({ ...(0, table_2.aligned)('left'), ...c, tdClassName: () => 'proficiency-report-table-row' })) })));
}
exports.default = ProficiencyReportTable;
const SchoolName = ({ schools, row: { schoolId }, }) => {
    const mSchool = schools.find(school => school.id === schoolId);
    const school = (0, maybe_1.fromJust)(mSchool, `Unable to find schoolId: ${schoolId} in schools`);
    return React.createElement(React.Fragment, null, school.name);
};
const StudentsProgressBar = ({ studentGroups: { belowGradeLevel, withinGradeLevel, aboveGradeLevel }, }) => {
    const { t } = (0, translate_1.useSafeTranslation)();
    return (0, utils_1.formatProgressBar)(belowGradeLevel.length, withinGradeLevel.length, aboveGradeLevel.length, t);
};
