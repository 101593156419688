"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Leaderboards = void 0;
exports.Leaderboards = {
    Team: 'team',
    Individual: 'individual',
    NoLeaderboard: 'no_leaderboard',
    all: function () {
        return [exports.Leaderboards.Individual, exports.Leaderboards.Team, exports.Leaderboards.NoLeaderboard];
    },
    parse: function (s) {
        return exports.Leaderboards.all().find(leaderboard => s === leaderboard);
    },
    parseOrError: function (s) {
        const parsedLeaderboard = exports.Leaderboards.parse(s);
        if (parsedLeaderboard === null || parsedLeaderboard === undefined)
            throw new Error('couldnt parse leaderboard');
        return parsedLeaderboard;
    },
};
