// extracted by mini-css-extract-plugin
export var black = "#000";
export var blueBase = "#146eb3";
export var blueDark1 = "#0d4977";
export var blueDark2 = "#073954";
export var blueLight1 = "#1397e1";
export var blueLight2 = "#bbe7ff";
export var blueLight3 = "#e5f6ff";
export var body1Style = "table-module__body1Style___bJpoe";
export var body2Style = "table-module__body2Style___aV_wl";
export var body3Style = "table-module__body3Style___Gy1_D";
export var bold = "table-module__bold___t__xj";
export var brownBase = "#a06632";
export var brownDark1 = "#805026";
export var brownDark2 = "#5e2f22";
export var brownLight1 = "#d7ad6b";
export var brownLight2 = "#e7cea6";
export var brownLight3 = "#f9efe1";
export var caption1Style = "table-module__caption1Style___nCb4V";
export var caption2Style = "table-module__caption2Style___lVpKJ";
export var centered = "table-module__centered___JtydY";
export var expandableRow = "table-module__expandableRow___YLxiS";
export var expandableTableContainer = "table-module__expandableTableContainer___AGnKy";
export var expanderIcon = "table-module__expanderIcon___Rgnst";
export var grayBase = "#999";
export var grayDark1 = "#686c71";
export var grayDark2 = "#323232";
export var grayLight1 = "#ccc";
export var grayLight2 = "#e9e9e9";
export var grayLight3 = "#f4f4f4";
export var greenBase = "#5acc05";
export var greenDark1 = "#48a800";
export var greenDark2 = "#227622";
export var greenLight1 = "#84dc43";
export var greenLight2 = "#b5f586";
export var greenLight3 = "#d8f8c0";
export var inheritColor = "table-module__inheritColor___vRQFk";
export var italic = "table-module__italic___ZguB8";
export var label1Style = "table-module__label1Style___gCG_B";
export var label2Style = "table-module__label2Style___hlzji";
export var label3Style = "table-module__label3Style___Cerx5";
export var muted = "table-module__muted___ngXM2";
export var neutralDark1 = "#404041";
export var newStyleTableBody = "table-module__newStyleTableBody___p7Hvm";
export var newStyleTableHeader = "table-module__newStyleTableHeader___FTTGR";
export var noBottomMargin = "table-module__noBottomMargin___xZVRf";
export var orangeBase = "#ffb563";
export var orangeDark1 = "#ec863a";
export var orangeDark2 = "#c55f14";
export var orangeLight1 = "#f9cf86";
export var orangeLight2 = "#fbe7c3";
export var orangeLight3 = "#fff8ed";
export var pinkBase = "#f143aa";
export var pinkDark1 = "#cd1f86";
export var pinkDark2 = "#9e206b";
export var pinkLight1 = "#f8bbdf";
export var pinkLight2 = "#feecf7";
export var pinkLight3 = "#fff1f6";
export var practiceBoldLgStyle = "table-module__practiceBoldLgStyle___LuZx_";
export var practiceBoldMdStyle = "table-module__practiceBoldMdStyle___GD8bj";
export var practiceBoldSmStyle = "table-module__practiceBoldSmStyle___iY9TX";
export var practiceBoldXlgStyle = "table-module__practiceBoldXlgStyle___idrL5";
export var practiceLgStyle = "table-module__practiceLgStyle___awRcF";
export var practiceMdStyle = "table-module__practiceMdStyle___losw6";
export var practiceSmStyle = "table-module__practiceSmStyle___WMOIF";
export var practiceXlgStyle = "table-module__practiceXlgStyle___t7LHY";
export var purpleBase = "#a582f0";
export var purpleDark1 = "#7c50b4";
export var purpleDark2 = "#59338a";
export var purpleLight1 = "#cbb0fd";
export var purpleLight2 = "#eadeff";
export var purpleLight3 = "#f3ecff";
export var redBase = "#f55b5b";
export var redDark1 = "#d44a44";
export var redDark2 = "#bc2b23";
export var redLight1 = "#ffa1a1";
export var redLight2 = "#fddfdf";
export var redLight3 = "#fff1f1";
export var stripedRows = "table-module__stripedRows___ydfiA";
export var subheaderStyle = "table-module__subheaderStyle___b5rnE";
export var subtitle1Style = "table-module__subtitle1Style___Z8w4a";
export var subtitle2Style = "table-module__subtitle2Style___HrHGZ";
export var subtitle3Style = "table-module__subtitle3Style___QBE_O";
export var table = "table-module__table___LjOZm";
export var tealBase = "#00ceb5";
export var tealDark1 = "#127b6d";
export var tealDark2 = "#0c5248";
export var tealLight1 = "#8beee0";
export var tealLight2 = "#b4f4eb";
export var tealLight3 = "#ddfaf6";
export var white = "#fff";
export var yellowBase = "#ffd670";
export var yellowDark1 = "#e8b500";
export var yellowDark2 = "#bc9300";
export var yellowLight1 = "#fef188";
export var yellowLight2 = "#fff7b2";
export var yellowLight3 = "#fffbe3";