"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.H4 = void 0;
const React = __importStar(require("react"));
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const html_text_1 = require("@freckle/educator-entities/ts/common/models/html-text");
const typography_module_scss_1 = require("./typography.module.scss");
function H4(props) {
    var _a;
    const { children, addClass, dataTest, id } = props;
    const className = (0, classnames_1.addMaybeClassName)(typography_module_scss_1.h4, addClass);
    const dataTestObj = dataTest !== null && dataTest !== undefined ? { 'data-test': dataTest } : {};
    const TagToUse = (_a = props.as) !== null && _a !== void 0 ? _a : 'h4';
    const node = React.createElement(TagToUse, Object.assign(Object.assign(Object.assign({ className }, dataTestObj), { id }), (props.innerHTMLText !== undefined ? (0, html_text_1.innerHTMLText)(props.innerHTMLText) : {})), children);
    return node;
}
exports.H4 = H4;
