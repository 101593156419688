"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spinner = void 0;
const React = __importStar(require("react"));
const spinner_module_scss_1 = require("./spinner.module.scss");
function Spinner(props) {
    const { inline, text } = props;
    const [overlayClass, containerClass] = inline === true ? [null, spinner_module_scss_1.inlineContainer] : [spinner_module_scss_1.fullOverlay, spinner_module_scss_1.overlayContainer];
    const ariaLabel = text !== null && text !== void 0 ? text : 'Loading';
    const assistiveLabelAttr = { 'aria-label': ariaLabel };
    return (React.createElement("div", { className: overlayClass },
        React.createElement("div", Object.assign({ className: containerClass, "data-test": "loading-spinner" }, assistiveLabelAttr),
            React.createElement("i", { className: `fa fa-circle-o-notch fa-spin fa-3x fa-fw ${spinner_module_scss_1.spinner}`, "aria-hidden": "true" }),
            text !== null && text !== undefined ? React.createElement("span", { className: spinner_module_scss_1.textStyle }, text) : null)));
}
exports.Spinner = Spinner;
