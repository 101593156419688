"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const react_router_dom_1 = require("react-router-dom");
const table_1 = require("@freckle/educator-materials/ts/components/table/table");
const table_2 = require("@freckle/educator-materials/ts/logic/table/table");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const checkbox_1 = require("@freckle/educator-materials/ts/components/checkbox");
const cell_1 = require("@freckle/school/ts/common/components/table/cell");
const api_controlled_table_1 = require("@freckle/school/ts/manage-teachers/components/api-controlled-table");
const self_rostered_teacher_table_module_scss_1 = require("./self-rostered-teacher-table.module.scss");
function SelfRosteredTeacherTable({ tableParams, fetchTableRows, onUpdate, onUpdateRowSelection, getTeacherSelections, onToggleSelectAll, }) {
    const { t } = (0, translate_1.useSafeTranslation)();
    const routerHistory = (0, react_router_dom_1.useNavigate)();
    const { selected, totalCount, totalTeachersSelected } = getTeacherSelections();
    return (React.createElement(api_controlled_table_1.ApiControlledTabContainer, { name: "teacher", tableParams: tableParams, routerHistory: routerHistory, fetchRows: fetchTableRows, onUpdate: onUpdate, selfRosteredTeachersSelections: React.createElement(typography_1.Text, { style: "subtitle-2" }, t('SELF_ROSTERED_TEACHER_TABLE_SELECTED', {
            numTeachersSelected: totalTeachersSelected,
            numTotal: totalCount,
        })), render: ({ paginationPanel: PaginationPanel }, rows) => (React.createElement(React.Fragment, null,
            React.createElement(table_1.Table, { data: rows, columns: [
                    {
                        Header: (React.createElement(checkbox_1.Checkbox, { id: `toggle-all-teachers`, onChange: () => onToggleSelectAll(totalCount !== totalTeachersSelected, rows), checked: totalCount === totalTeachersSelected })),
                        Cell: (0, table_2.cellFromRow)(({ id }) => (React.createElement(checkbox_1.Checkbox, { id: `toggle-teacher-${id}`, onChange: () => onUpdateRowSelection(id, !selected.has(id)), checked: selected.has(id) }))),
                        id: 'checkbox',
                        // Force this to take up as little space as possible
                        widthPx: 0,
                    },
                    {
                        Header: (React.createElement(typography_1.Text, { style: "label-1", addClass: self_rostered_teacher_table_module_scss_1.tableHeaderTextMargin }, t('SELF_ROSTERED_TEACHER_TABLE_COLUMN_HEADER_NAME'))),
                        widthPx: 130,
                        ...(0, table_2.idAccessorFromProp)('name'),
                    },
                    {
                        Header: (React.createElement(typography_1.Text, { style: "label-1", addClass: self_rostered_teacher_table_module_scss_1.tableHeaderTextMargin }, t('SELF_ROSTERED_TEACHER_TABLE_COLUMN_HEADER_EMAIL'))),
                        widthPx: 215,
                        ...(0, table_2.idAccessorFromProp)('email'),
                    },
                    {
                        Header: (React.createElement(typography_1.Text, { style: "label-1", addClass: self_rostered_teacher_table_module_scss_1.tableHeaderTextMargin }, t('SELF_ROSTERED_TEACHER_TABLE_COLUMN_HEADER_ROLE'))),
                        Cell: (0, table_2.cellFromRow)(({ role }) => React.createElement(cell_1.Role, { role: role })),
                        widthPx: 90,
                        ...(0, table_2.idAccessorFromProp)('role'),
                    },
                    {
                        Header: (React.createElement(typography_1.Text, { style: "label-1", addClass: self_rostered_teacher_table_module_scss_1.tableHeaderTextMargin }, t('SELF_ROSTERED_TEACHER_TABLE_COLUMN_HEADER_GRADE'))),
                        Cell: (0, table_2.cellFromRow)(({ grades }) => React.createElement(cell_1.Grades, { grades: grades })),
                        widthPx: 56,
                        ...(0, table_2.idAccessorFromProp)('grades'),
                    },
                    {
                        Header: (React.createElement(typography_1.Text, { style: "label-1", addClass: self_rostered_teacher_table_module_scss_1.tableHeaderTextMargin }, t('SELF_ROSTERED_TEACHER_TABLE_COLUMN_HEADER_STUDENTS'))),
                        Cell: (0, table_2.cellFromRow)(({ students }) => React.createElement(React.Fragment, null, students.length)),
                        widthPx: 80,
                        ...(0, table_2.idAccessorFromProp)('students'),
                    },
                    {
                        Header: (React.createElement(typography_1.Text, { style: "label-1", addClass: self_rostered_teacher_table_module_scss_1.tableHeaderTextMargin }, t('SELF_ROSTERED_TEACHER_TABLE_COLUMN_HEADER_MANAGED_BY'))),
                        Cell: (0, table_2.cellFromRow)(({ source: idp }) => (React.createElement(React.Fragment, null, t('DISPLAY_IDP', { idp })))),
                        widthPx: 100,
                        ...(0, table_2.idAccessorFromProp)('source'),
                    },
                    {
                        Header: (React.createElement(typography_1.Text, { style: "label-1", addClass: self_rostered_teacher_table_module_scss_1.tableHeaderTextMargin }, t('SELF_ROSTERED_TEACHER_TABLE_COLUMN_HEADER_SCHOOL'))),
                        Cell: (0, table_2.cellFromRow)(({ school: { name } }) => React.createElement(React.Fragment, null, name)),
                        widthPx: 100,
                        ...(0, table_2.idAccessorFromProp)('school'),
                    },
                ].map(c => ({ ...(0, table_2.aligned)('left'), ...c, disableSortBy: table_2.disableSortBy })) }),
            React.createElement(PaginationPanel, { components: { sizePerPageDropdown: React.createElement(React.Fragment, null), pageList: React.createElement(React.Fragment, null) } }))) }));
}
exports.default = SelfRosteredTeacherTable;
