"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const use_schools_1 = require("@freckle/educator-entities/ts/common/hooks/use-schools");
const routes_1 = require("@freckle/school/ts/common/routers/routes");
const with_resources_1 = require("@freckle/school/ts/common/components/with-resources");
function RedirectAuthenticatedComponentHandler() {
    const schools = (0, use_schools_1.useSchools)();
    return (React.createElement(with_resources_1.WithResources, { resources: { schools }, render: ({ schools }) => {
            const firstSchool = schools[0];
            if (firstSchool === undefined) {
                return React.createElement(react_router_dom_1.Navigate, { to: routes_1.Routes.noSchools(), replace: true });
            }
            return React.createElement(react_router_dom_1.Navigate, { to: routes_1.Routes.dashboard(), replace: true });
        } }));
}
exports.default = RedirectAuthenticatedComponentHandler;
