"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const label_1 = require("@freckle/educator-materials/ts/components/label");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const link_1 = require("@freckle/educator-materials/ts/components/link");
const filter_module_scss_1 = require("./filter.module.scss");
function Filter(props) {
    const { url, name } = props;
    const { t } = (0, translate_1.useSafeTranslation)();
    return (React.createElement("div", { className: filter_module_scss_1.filter },
        React.createElement(label_1.Label, { addClass: filter_module_scss_1.label }, t('MANAGE_TEACHERS_FILTER_FILTER_LABEL')),
        React.createElement("span", { className: filter_module_scss_1.filterValue }, name),
        React.createElement(link_1.Link, { addClass: filter_module_scss_1.link, href: url, reactRouter: true, title: t('MANAGE_TEACHERS_FILTER_REMOVE_FILTER_LINK_TITLE') },
            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faTimes }))));
}
exports.default = Filter;
