"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checkbox = void 0;
const React = __importStar(require("react"));
const maybe_1 = require("@freckle/maybe");
const checkbox_module_scss_1 = require("./checkbox.module.scss");
function Checkbox(props) {
    const { id, label, autoFocus = false, checked = false, disabled = false, name, value, dataTest, onChange, size = 'sm', tabIndex = '0', } = props;
    const nameObj = { name: (0, maybe_1.asHTMLAttributeValue)(name) };
    const disabledObj = { disabled: disabled ? true : undefined };
    const autoFocusObj = { autoFocus: autoFocus ? true : undefined };
    const dataTestObj = { 'data-test': (0, maybe_1.asHTMLAttributeValue)(dataTest) };
    const dataTestInputObj = {
        'data-test': dataTest !== undefined ? `${dataTest}-input` : undefined,
    };
    const tabIndexObj = { tabIndex: parseInt(tabIndex, 10) };
    const additionalProps = Object.assign(Object.assign(Object.assign(Object.assign({}, nameObj), disabledObj), autoFocusObj), dataTestInputObj);
    const checkboxSize = getStylesForSize(size);
    const disabledClassStr = disabled ? checkbox_module_scss_1.disabledStyle : '';
    return (React.createElement("label", { className: checkbox_module_scss_1.labelWrapper, htmlFor: id },
        React.createElement("input", Object.assign({ id: id, type: "checkbox", value: value, checked: checked, onChange: onChange }, additionalProps)),
        React.createElement("span", Object.assign({ className: `${checkbox_module_scss_1.standardCheckbox} ${checkboxSize} ${disabledClassStr}` }, tabIndexObj, dataTestObj)),
        React.createElement("span", { className: checkbox_module_scss_1.labelStyle }, label)));
}
exports.Checkbox = Checkbox;
function getStylesForSize(size) {
    switch (size) {
        case 'sm':
            return checkbox_module_scss_1.smallCheckbox;
        case 'lg':
            return checkbox_module_scss_1.largeCheckbox;
        default:
            throw new Error(`No style available for selected checkbox size: ${size}`);
    }
}
