"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseElaSightWordsMetadata = exports.parseWordStudyMetadata = exports.parseElaFocusSkillsPracticeMetadata = exports.parseElaTargetedSkillsPracticeMetadata = exports.parseElaAdaptiveSkillsPracticeMetadata = exports.parseElaDecodablesMetadata = exports.parseElaAssessmentsMetadata = exports.parseElaArticlesWritingMetadata = exports.parseElaArticlesReadingMetadata = void 0;
const articles_1 = require("@freckle/educator-entities/ts/common/models/generic-assignment-session/articles");
const rl_skill_usp_id_1 = require("@freckle/educator-entities/ts/common/types/rl-skill-usp-id");
const parser_1 = require("@freckle/parser");
const ela_adaptive_skill_assignment_pathway_1 = require("@freckle/educator-entities/ts/ela/adaptive-skills/models/ela-adaptive-skill-assignment-pathway");
const rl_domain_1 = require("@freckle/educator-entities/ts/common/models/rl-domain");
const rl_skill_1 = require("./../../../common/models/rl-skill");
const rl_standard_1 = require("./../../../common/models/rl-standard");
/**
 * ELA Articles
 */
exports.parseElaArticlesReadingMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_articles_reading'),
    contents: articles_1.parseResumableArticleContents,
});
exports.parseElaArticlesWritingMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_articles_writing'),
    contents: articles_1.parseArticleContents,
});
exports.parseElaAssessmentsMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_assessments'),
    contents: (0, parser_1.record)({ assessmentContentUuid: (0, parser_1.string)() }),
});
exports.parseElaDecodablesMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_decodables'),
    contents: (0, parser_1.record)({ decodableUuid: (0, parser_1.string)() }),
});
exports.parseElaAdaptiveSkillsPracticeMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_adaptive_skills_practice'),
    contents: (0, parser_1.record)({
        domain: (0, parser_1.nullable)(rl_domain_1.parseDomainAttrs),
        rlStandard: (0, parser_1.nullable)(rl_standard_1.parseStandardWithDomainAttrs),
        rlSkillId: (0, parser_1.nullable)(rl_skill_usp_id_1.parse),
        startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
        pathway: (0, parser_1.nullable)((0, parser_1.stringEnum)('ElaAdaptiveSkillAssignmentPathwayT', ela_adaptive_skill_assignment_pathway_1.ElaAdaptiveSkillAssignmentPathway.parse)),
    }),
});
exports.parseElaTargetedSkillsPracticeMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_targeted_skills_practice'),
    contents: (0, parser_1.record)({
        startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
        skill: rl_skill_1.parseRlSkillAttrs,
        standard: rl_standard_1.parseStandardWithDomainAttrs,
    }),
});
// TODO: We are using the tag 'ela_targeted_skills_practice'
// for both targeted and focus skills practice since when the session is created on the student side,
// that's how it was tagged. Tech debt task: https://app.asana.com/0/1201335092255974/1207360706468057/f
exports.parseElaFocusSkillsPracticeMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_targeted_skills_practice'),
    contents: (0, parser_1.record)({
        startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
        skill: rl_skill_1.parseRlSkillAttrs,
        standard: rl_standard_1.parseStandardWithDomainAttrs,
    }),
});
const parseElaWordStudyAssignmentType = (0, parser_1.firstOf)((0, parser_1.literal)('word_study_assignment_adaptive'), (0, parser_1.literal)('word_study_assignment_targeted'));
exports.parseWordStudyMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_word_study'),
    contents: (0, parser_1.record)({
        assignmentType: parseElaWordStudyAssignmentType,
        rlStandard: (0, parser_1.nullable)(rl_standard_1.parseStandardWithDomainAttrs),
        rlSkill: (0, parser_1.nullable)(rl_skill_1.parseRlSkillAttrs),
    }),
});
exports.parseElaSightWordsMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('ela_sight_words'),
    contents: (0, parser_1.record)({ level: (0, parser_1.number)() }),
});
