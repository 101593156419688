"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Container = void 0;
const React = __importStar(require("react"));
const maybe_1 = require("@freckle/maybe");
const exhaustive_1 = require("@freckle/exhaustive");
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const style = __importStar(require("./container.module.scss"));
function Container(props) {
    const { children, addClass, size = 'lg', dataTest, onClick, onMouseEnter, onMouseLeave, corners = 'rounded', fullHeight = false, shadowStyle = 'none', } = props;
    const addDataTest = { 'data-test': (0, maybe_1.asHTMLAttributeValue)(dataTest) };
    const addOnClick = onClick !== undefined ? { onClick, role: 'button' } : { onClick: undefined, role: undefined };
    const addOnMouseEnter = { onMouseEnter: (0, maybe_1.asHTMLAttributeValue)(onMouseEnter) };
    const addOnMouseLeave = { onMouseLeave: (0, maybe_1.asHTMLAttributeValue)(onMouseLeave) };
    const contentContainerClassName = (0, classnames_1.addMaybeClassNames)(style.container, [
        style[corners],
        getSizeStyle(size),
        addClass,
        fullHeight ? style.fullHeightContainer : null,
        shadowStyle !== 'none' ? style.shadowStyle : null,
    ]);
    const optionalProps = Object.assign(Object.assign(Object.assign(Object.assign({}, addDataTest), addOnClick), addOnMouseEnter), addOnMouseLeave);
    const contentContainer = (React.createElement("div", Object.assign({ className: contentContainerClassName }, optionalProps), children));
    return contentContainer;
}
exports.Container = Container;
function getSizeStyle(size) {
    switch (size) {
        case 'lg':
            return style.lg;
        case 'md':
            return style.md;
        case 'sm':
            return style.sm;
        case 'xs':
            return style.xs;
        case 'empty':
            return style.noPadding;
        default:
            return (0, exhaustive_1.exhaustive)(size, 'BoardSizeT');
    }
}
