"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.failureToActions = exports.safeParseResponseJson = void 0;
const maybe_1 = require("@freckle/maybe");
const exhaustive_1 = require("@freckle/exhaustive");
const routes_1 = require("@freckle/school/ts/common/routers/routes");
const allFailures = [
    'EmailFailure',
    'PasswordFailure',
    'AccountNeedsVerification',
    'AccountExpired',
    'AccountLocked',
];
const safeParseResponseJson = (e) => {
    try {
        if (typeof e === 'object' &&
            e !== null &&
            'responseText' in e &&
            typeof e.responseText === 'string') {
            const response = JSON.parse(e.responseText);
            return (0, maybe_1.maybe)(() => ({
                tag: 'unknown-failure-key',
            }), key => ({
                tag: 'parsed-failure-key',
                key,
            }), allFailures.find(f => f === response));
        }
    }
    catch (e) {
        console.error('Error parsing responseText as JSON', e);
    }
    return { tag: 'parse-failed' };
};
exports.safeParseResponseJson = safeParseResponseJson;
const failureToActions = (result) => {
    const justMessage = (i18nKey) => ({ disabled: false, redirect: null, i18nKey });
    switch (result.tag) {
        case 'parse-failed':
            return justMessage('LOGIN_PAGE_ERROR_MESSAGE_TEXT');
        case 'unknown-failure-key':
            return justMessage('LOGIN_PAGE_ERROR_MESSAGE_OTHER_TEXT');
        case 'parsed-failure-key': {
            switch (result.key) {
                case 'EmailFailure':
                    return justMessage('LOGIN_PAGE_EMAIL_ERROR_MESSAGE_TEXT');
                case 'PasswordFailure':
                    return justMessage('LOGIN_PAGE_PASSWORD_ERROR_MESSAGE_TEXT');
                case 'AccountNeedsVerification':
                    return {
                        disabled: false,
                        i18nKey: 'LOGIN_PAGE_VERIFICATION_ERROR_MESSAGE_TEXT',
                        redirect: routes_1.Routes.verificationPending(),
                    };
                case 'AccountExpired':
                    return justMessage('LOGIN_PAGE_ACCOUNT_EXPIRED_ERROR_MESSAGE_TEXT');
                case 'AccountLocked':
                    return {
                        redirect: null,
                        i18nKey: 'LOGIN_PAGE_ACCOUNT_LOCKED_ERROR_MESSAGE_TEXT',
                        disabled: true,
                    };
                default:
                    return (0, exhaustive_1.exhaustive)(result.key);
            }
        }
        default:
            return (0, exhaustive_1.exhaustive)(result);
    }
};
exports.failureToActions = failureToActions;
