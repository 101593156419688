"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStudents = void 0;
const use_async_1 = require("@freckle/educator-entities/ts/common/hooks/use-async");
const students_1 = require("@freckle/school/ts/users/collections/students");
function useStudents(options) {
    const defaultOptions = { staleTime: 60 * 1000 };
    return (0, use_async_1.useAsync)(students_1.fetchStudents, ['fetchStudents'], { ...defaultOptions, ...options });
}
exports.useStudents = useStudents;
