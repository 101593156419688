"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSchools = void 0;
const use_async_1 = require("@freckle/educator-entities/ts/common/hooks/use-async");
const school_1 = require("@freckle/educator-entities/ts/roster/models/school");
function useSchools(options) {
    const defaultOptions = { staleTime: 60 * 1000 };
    return (0, use_async_1.useAsync)(school_1.fetchSchools, ['fetchSchools'], Object.assign(Object.assign({}, defaultOptions), options));
}
exports.useSchools = useSchools;
