"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const rl_standard_set_1 = require("@freckle/educator-entities/ts/common/models/rl-standard-set");
const generic_selector_1 = require("@freckle/educator-entities/ts/common/components/generic-selector");
const generic_selector_2 = require("@freckle/educator-entities/ts/common/logic/generic-selector");
const button_async_1 = require("@freckle/educator-materials/ts/components/button-async");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const label_1 = require("@freckle/educator-materials/ts/components/label");
const teacher_language_dropdown_1 = require("@freckle/educator-materials/ts/components/teacher-language-dropdown");
const languages_1 = require("@freckle/educator-entities/ts/common/helpers/languages");
const settings_module_scss_1 = require("./settings.module.scss");
function SettingsComponent(props) {
    const { t } = (0, translate_1.useSafeTranslation)();
    const { mathStandardSets, schoolAdmin, selectedMathStandardSetId, selectedLanguage, onSubmit, onChangeMathStandardSetId, onChangeLanguage, } = props;
    const adminStandardSets = (0, rl_standard_set_1.filterStandardSetsForDarkTest)(mathStandardSets, schoolAdmin.email, selectedMathStandardSetId);
    const mathStandardSetOptions = [
        (0, generic_selector_2.createSelectorOptionAsItem)(null, t('SETTINGS_PAGE_SELECTOR_OPTION_DEFAULT_TEXT')),
        ...adminStandardSets.map(mathStandardSet => (0, generic_selector_2.createSelectorOptionAsItem)(mathStandardSet.id, mathStandardSet.description)),
    ];
    return (React.createElement("div", { className: "settings-page" },
        React.createElement(typography_1.H1, null, t('SETTINGS_PAGE_TITLE')),
        React.createElement("form", { role: "form", onSubmit: onSubmit },
            React.createElement("div", { className: "form-group" },
                React.createElement(label_1.Label, { htmlFor: "mathStandardSetSelector" }, t('SETTINGS_PAGE_MATH_SYSTEM_TEXT')),
                React.createElement("div", { className: "label-descriptor" },
                    React.createElement(translate_1.SafeTrans, { i18nKey: "SETTINGS_PAGE_MATH_SYSTEM_DESCRIPTION_TEXT" })),
                React.createElement(generic_selector_1.GenericSelector, { id: "mathStandardSetSelector", selection: selectedMathStandardSetId, collection: mathStandardSetOptions, onChangeSelection: onChangeMathStandardSetId, className: "math-selector" }),
                React.createElement("div", { className: settings_module_scss_1.languagesItem },
                    React.createElement(teacher_language_dropdown_1.LanguageDropdown, { updateMode: { tag: 'component-is-passthrough' }, perspectiveLanguage: selectedLanguage, selectedLanguage: selectedLanguage, onChange: onChangeLanguage, labels: {
                            formLabel: t('LANGUAGE_DROPDOWN_LABEL'),
                            loadingText: t('LOADING_LANGUAGES_DROPDOWN_OPTION'),
                        }, filterLanguages: schoolAdmin.language === languages_1.Languages.Es ? [] : [languages_1.Languages.Es] })),
                React.createElement(button_async_1.ButtonAsync, { onClick: onSubmit, type: "submit", style: "primary", addClass: "submit-btn" }, t('SETTINGS_PAGE_SUBMIT_BTN_TEXT'))))));
}
exports.default = SettingsComponent;
