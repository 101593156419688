"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageHeader = void 0;
const React = __importStar(require("react"));
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const breadcrumbs_1 = require("@freckle/educator-materials/ts/components/breadcrumbs");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const style = __importStar(require("./page-header.module.scss"));
function PageHeader(props) {
    const { title, breadcrumbsConfig, breadcrumbsClass, addContainerClass, dataTest } = props;
    const breadcrumbs = breadcrumbsConfig !== null && breadcrumbsConfig !== undefined ? (React.createElement(breadcrumbs_1.Breadcrumbs, Object.assign({ breadcrumbsClass: breadcrumbsClass }, breadcrumbsConfig))) : null;
    const headerTitle = title !== null && title !== undefined ? (React.createElement(typography_1.H1, { addClass: style.heading, dataTest: dataTest }, title)) : null;
    if (breadcrumbs === null && headerTitle === null) {
        return React.createElement(React.Fragment, null);
    }
    const containerClassName = (0, classnames_1.addMaybeClassName)(style.header, addContainerClass);
    return (React.createElement("div", { className: containerClassName },
        headerTitle,
        breadcrumbs));
}
exports.PageHeader = PageHeader;
