"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const use_params_1 = require("@freckle/school/ts/hooks/use-params");
const filters_1 = require("@freckle/school/ts/manage-teachers/api-helpers/filters");
const teacher_tab_1 = require("./teacher-tab");
const TEACHER_FILTER_TAG = 'teacher-filters';
function TeacherTabHandler(props) {
    const { tableParams, summary, ...rest } = props;
    // Course filter
    const courseId = (0, use_params_1.useQueryParamAsNum)('courseId');
    const courseName = (0, use_params_1.useQueryParamAsString)('courseName');
    const course = courseId !== null && courseName !== null
        ? { course: { id: courseId, name: decodeURIComponent(courseName) } }
        : {};
    // Student filter
    const studentId = (0, use_params_1.useQueryParamAsNum)('studentId');
    const studentName = (0, use_params_1.useQueryParamAsString)('studentName');
    const student = studentId !== null && studentName !== null
        ? { student: { id: studentId, name: decodeURIComponent(studentName) } }
        : {};
    const schoolName = (0, use_params_1.useQueryParamAsString)('schoolName');
    const schoolNameObj = schoolName !== null ? { school: { name: schoolName } } : {};
    const role = (0, use_params_1.useQueryParamAsString)('role');
    const roleObj = role !== null ? { role } : {};
    const studentGrade = (0, use_params_1.useQueryParamAsString)('studentGrade');
    const studentGradeObj = studentGrade !== null && studentGrade !== '' ? { studentGrade } : {};
    const teacherName = (0, use_params_1.useQueryParamAsString)('teacherName');
    const teacherNameObj = teacherName !== null ? { name: teacherName } : {};
    const email = (0, use_params_1.useQueryParamAsString)('email');
    const emailObj = email !== null ? { email } : {};
    const identityProviders = (0, filters_1.parseDefaultIdpsFromQueryParam)((0, use_params_1.useQueryParamAsString)('identityProviders'), {
        tag: 'for-teacher',
        ...summary,
    }, { tag: 'summary' });
    const tableParamsWithFilter = {
        ...tableParams,
        additionalFilters: {
            tag: TEACHER_FILTER_TAG,
            ...course,
            ...student,
            ...schoolNameObj,
            ...roleObj,
            ...studentGradeObj,
            ...teacherNameObj,
            ...emailObj,
            identityProviders,
        },
    };
    return React.createElement(teacher_tab_1.TeacherTabContainer, { tableParams: tableParamsWithFilter, summary: summary, ...rest });
}
exports.default = TeacherTabHandler;
