"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageWrapper = void 0;
const React = __importStar(require("react"));
const page_header_1 = require("@freckle/educator-materials/ts/components/page-header");
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const page_wrapper_module_scss_1 = require("./page-wrapper.module.scss");
function PageWrapper(props) {
    const { title, breadcrumbsConfig, children, addPageWrapperContainerClass } = props;
    const containerClassName = (0, classnames_1.addMaybeClassName)(page_wrapper_module_scss_1.childrenWrapper, addPageWrapperContainerClass);
    return (React.createElement(React.Fragment, null,
        React.createElement(page_header_1.PageHeader, { title: title, breadcrumbsConfig: breadcrumbsConfig }),
        React.createElement("div", { className: containerClassName }, children)));
}
exports.PageWrapper = PageWrapper;
