"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Link = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const maybe_1 = require("@freckle/maybe");
const exhaustive_1 = require("@freckle/exhaustive");
const link_module_scss_1 = require("./link.module.scss");
/**
 * This is the standard component used for links.
 * `Link` should be used when changing page, not when performing an action.
 * When performing an action used the component `Link`.
 *
 * It contains the most used attribute of the regular HTML `<a>` tag.
 */
function Link(props) {
    const { href, onClick, children, dataTest, disabled = false, autoFocus, addClass, style = 'normal', size = 'md', target, rel, reactRouter, title, ariaLabel, fullWidth, leftIcon, rightIcon, tabIndex, downloadName, } = props;
    const addClassStr = addClass !== null && addClass !== void 0 ? addClass : '';
    const disabledClass = disabled ? link_module_scss_1.disabled : '';
    const fullWidthClassName = fullWidth === true ? [link_module_scss_1.fullWidth] : [];
    const styleClass = () => {
        switch (style) {
            case null:
            case undefined:
            case 'normal':
                return null;
            case 'btn-primary':
                return link_module_scss_1.btnPrimary;
            case 'btn-secondary':
                return link_module_scss_1.btnSecondary;
            case 'btn-tertiary':
                return link_module_scss_1.btnTertiary;
            case 'btn-danger':
                return link_module_scss_1.btnDanger;
            case 'btn-warning':
                return link_module_scss_1.btnWarning;
            case 'btn-premium':
                return link_module_scss_1.btnPremium;
            default:
                return (0, exhaustive_1.exhaustive)(style);
        }
    };
    const sizeClass = () => {
        switch (size) {
            case null:
            case undefined:
                return link_module_scss_1.md;
            case 'sm':
                return link_module_scss_1.sm;
            case 'md':
                return link_module_scss_1.md;
            case 'lg':
                return link_module_scss_1.lg;
            case 'xlg':
                return link_module_scss_1.xlg;
            default:
                return (0, exhaustive_1.exhaustive)(size);
        }
    };
    const className = [
        link_module_scss_1.link,
        styleClass(),
        sizeClass(),
        disabledClass,
        addClassStr,
        ...fullWidthClassName,
    ].join(' ');
    const dataTestObj = { 'data-test': (0, maybe_1.asHTMLAttributeValue)(dataTest) };
    const autoFocusObj = { autoFocus: autoFocus === true ? true : undefined };
    const targetObj = { target: (0, maybe_1.asHTMLAttributeValue)(target) };
    const relObj = { rel: (0, maybe_1.asHTMLAttributeValue)(rel) };
    const onClickObj = { onClick: (0, maybe_1.asHTMLAttributeValue)(onClick) };
    const titleObj = { title: (0, maybe_1.asHTMLAttributeValue)(title) };
    const ariaLabelObj = { 'aria-label': (0, maybe_1.asHTMLAttributeValue)(ariaLabel) };
    const additionalProps = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, dataTestObj), autoFocusObj), targetObj), relObj), onClickObj), titleObj), ariaLabelObj);
    const leftIconNode = leftIcon !== undefined ? (React.createElement("span", { className: link_module_scss_1.leftIconContainer },
        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: leftIcon }))) : null;
    const rightIconNode = rightIcon !== undefined ? (React.createElement("span", { className: link_module_scss_1.rightIconContainer },
        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: rightIcon }))) : null;
    const linkContentInternal = (React.createElement(React.Fragment, null,
        leftIconNode,
        children,
        rightIconNode));
    const linkContent = isButton(style) ? (React.createElement("span", { className: link_module_scss_1.btnPositionalStyle }, linkContentInternal)) : (linkContentInternal);
    return reactRouter ? (React.createElement(react_router_dom_1.Link, Object.assign({ to: href, className: className }, additionalProps, { tabIndex: tabIndex }), linkContent)) : (React.createElement("a", Object.assign({ href: href, className: className, tabIndex: tabIndex, download: downloadName }, additionalProps), linkContent));
}
exports.Link = Link;
function isButton(linkStyle) {
    switch (linkStyle) {
        case 'btn-primary':
        case 'btn-secondary':
        case 'btn-tertiary':
        case 'btn-danger':
        case 'btn-warning':
        case 'btn-premium':
            return true;
        case 'normal':
        case null:
        case undefined:
            return false;
        default:
            return (0, exhaustive_1.exhaustive)(linkStyle, 'LinkStyleT');
    }
}
