"use strict";
// File generated by generate-openapi-enums.js, do not change directly
Object.defineProperty(exports, "__esModule", { value: true });
exports.FactPracticeOperations = void 0;
const exhaustive_1 = require("@freckle/exhaustive");
const maybe_1 = require("@freckle/maybe");
const Addition = 'addition';
const Subtraction = 'subtraction';
const Multiplication = 'multiplication';
const Division = 'division';
const IntegerAdditionSubtraction = 'integer_addition_subtraction';
const IntegerMultiplicationDivision = 'integer_multiplication_division';
const Fractions = 'fractions';
// Get all FactPracticeOperation values
function all() {
    return [Addition, Subtraction, Multiplication, Division, IntegerAdditionSubtraction, IntegerMultiplicationDivision, Fractions];
}
// Parse FactPracticeOperation from a raw string, returns null if no match
function parse(raw) {
    var _a;
    return (_a = all().find(e => e === raw)) !== null && _a !== void 0 ? _a : null;
}
// Parse FactPracticeOperation from a raw string, returns default if no match
function parseWithDefault(raw, def) {
    var _a;
    return (_a = parse(raw)) !== null && _a !== void 0 ? _a : def;
}
// Parse FactPracticeOperation from a raw string, throws if no match
function unsafeParse(raw) {
    return (0, maybe_1.fromJust)(parse(raw), `Invalid FactPracticeOperation: ${raw}`);
}
// Make a function that will dispatch cases for FactPracticeOperation
function mkMatch({ onAddition, onSubtraction, onMultiplication, onDivision, onIntegerAdditionSubtraction, onIntegerMultiplicationDivision, onFractions, }) {
    return (v) => {
        switch (v) {
            case 'addition': return onAddition();
            case 'subtraction': return onSubtraction();
            case 'multiplication': return onMultiplication();
            case 'division': return onDivision();
            case 'integer_addition_subtraction': return onIntegerAdditionSubtraction();
            case 'integer_multiplication_division': return onIntegerMultiplicationDivision();
            case 'fractions': return onFractions();
            default:
                return (0, exhaustive_1.exhaustive)(v);
        }
    };
}
function match(v, cases) {
    return mkMatch(cases)(v);
}
exports.FactPracticeOperations = {
    // Constants
    Addition,
    Subtraction,
    Multiplication,
    Division,
    IntegerAdditionSubtraction,
    IntegerMultiplicationDivision,
    Fractions,
    // Enumeration
    all,
    // Parsing
    parse,
    unsafeParse,
    parseWithDefault,
    // Case dispatch
    mkMatch,
    match,
};
