"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortFreckleTextLevelCodes = void 0;
function sortFreckleTextLevels(a, b) {
    return sortFreckleTextLevelCodes(a.freckleTextLevel, b.freckleTextLevel);
}
exports.default = sortFreckleTextLevels;
function sortFreckleTextLevelCodes(a, b) {
    if (a === b)
        return 0;
    if (a === 'ER')
        return -1;
    if (b === 'ER')
        return 1;
    return a.localeCompare(b, 'en', { numeric: true });
}
exports.sortFreckleTextLevelCodes = sortFreckleTextLevelCodes;
