"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileName = void 0;
function getFileName(t, filetype) {
    switch (filetype) {
        case 'courses':
            return t('IMPORT_ROSTER_CSV_FILENAME_CLASSES');
        case 'teachers':
            return t('IMPORT_ROSTER_CSV_FILENAME_TEACHERS');
        case 'students':
            return t('IMPORT_ROSTER_CSV_FILENAME_STUDENTS');
        case 'enrollments':
            return t('IMPORT_ROSTER_CSV_FILENAME_ENROLLMENTS');
        default:
            throw new Error(`Unrecognized Roster CSV file type ${filetype}`);
    }
}
exports.getFileName = getFileName;
