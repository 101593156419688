"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const analytics_1 = require("@freckle/educator-entities/ts/common/helpers/analytics");
const http_error_1 = require("@freckle/educator-entities/ts/common/exceptions/http-error");
const bugsnag_client_1 = require("@freckle/school/ts/common/helpers/exception-handlers/bugsnag-client");
function trackSchoolRouteChanges(location) {
    return analytics_1.GoogleAnalytics.trackRouteChange(location.pathname).catch(err => (0, bugsnag_client_1.logErrorAsUnhandled)(new http_error_1.HttpError(err)));
}
exports.default = {
    trackSchoolRouteChanges,
};
