"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.spanDangerous = exports.plainTextParser = exports.htmlTextParser = exports.innerHTMLText = exports.displayHTMLOrPlainTextInSpan = exports.toPlainText = exports.toHTMLTextDangerous = void 0;
const React = __importStar(require("react"));
const parser_1 = require("@freckle/parser");
const exhaustive_1 = require("@freckle/exhaustive");
/** Tag a string as being HTML that is safe to render.
 * This function is flagged as being dangerous because inappropriately
 * applying it to arbitrary input can construct an HTMLText value that
 * is not actually safe to render. */
function toHTMLTextDangerous(value) {
    return { tag: 'html-text', value };
}
exports.toHTMLTextDangerous = toHTMLTextDangerous;
function toPlainText(value) {
    return { tag: 'plain-text', value };
}
exports.toPlainText = toPlainText;
function innerHTMLSpan(input) {
    return React.createElement("span", Object.assign({}, (0, exports.innerHTMLText)(input)));
}
function displayHTMLOrPlainTextInSpan(input) {
    switch (input.tag) {
        case 'html-text':
            return innerHTMLSpan(input);
        case 'plain-text':
            return React.createElement("span", null, input.value);
        default:
            (0, exhaustive_1.exhaustive)(input);
    }
}
exports.displayHTMLOrPlainTextInSpan = displayHTMLOrPlainTextInSpan;
const innerHTMLText = (htmlText) => ({
    dangerouslySetInnerHTML: { __html: htmlText.value },
});
exports.innerHTMLText = innerHTMLText;
/** Use this as the parser for a string field when the string should be interpreted as HTML to render. */
exports.htmlTextParser = (0, parser_1.mapStatic)((0, parser_1.string)(), value => toHTMLTextDangerous(value));
exports.plainTextParser = (0, parser_1.mapStatic)((0, parser_1.string)(), value => toPlainText(value));
function spanDangerous(value) {
    return React.createElement("span", Object.assign({}, (0, exports.innerHTMLText)(toHTMLTextDangerous(value))));
}
exports.spanDangerous = spanDangerous;
