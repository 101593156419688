"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnswerCorrectness = exports.parseMathQuestionGraphScatterPointsMetadata = void 0;
const parser_1 = require("@freckle/parser");
const equation_1 = require("@freckle/educator-entities/ts/math/common/logic/equation");
const common_parser_1 = require("@freckle/educator-entities/ts/math/common/models/questions/common-parser");
const rawGraphScatterPointsEquationCoefficients = (0, parser_1.array)(common_parser_1.parsePoint);
const parseGraphScatterPointsEquationCoefficients = (0, parser_1.firstOf)((0, parser_1.record)({
    graphScatterPointsPoints: (0, parser_1.field)(rawGraphScatterPointsEquationCoefficients, 'graph-scatter-points-points'),
}), (0, parser_1.record)({
    graphScatterPointsPoints: (0, parser_1.field)((0, parser_1.obfuscated)(rawGraphScatterPointsEquationCoefficients), 'obfuscated-graph-scatter-points-points'),
}));
const parseMathQuestionGraphScatterPointsAttrs = (0, parser_1.merge)(common_parser_1.parseCommonMathGraphQuestionAttrs, parseGraphScatterPointsEquationCoefficients);
exports.parseMathQuestionGraphScatterPointsMetadata = (0, parser_1.record)({
    answerType: (0, parser_1.field)((0, parser_1.literal)('graph_scatter_points'), 'answer-type'),
    content: (0, parser_1.onSelf)(parseMathQuestionGraphScatterPointsAttrs),
});
// Return :: Double (either 0 or 100)
function getAnswerCorrectness(metadata, answer) {
    const { graphScatterPointsPoints } = metadata;
    const isCorrect = (0, equation_1.validateScatterPoints)(answer.points, graphScatterPointsPoints);
    return isCorrect ? 100 : 0;
}
exports.getAnswerCorrectness = getAnswerCorrectness;
