"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateHTMLSpan = exports.translateHTML = exports.SafeTrans = exports.SafeTranslation = exports.useSafeTranslation = void 0;
const React = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const lodash_1 = require("lodash");
const exhaustive_1 = require("@freckle/exhaustive");
const html_text_1 = require("@freckle/educator-entities/ts/common/models/html-text");
// Returns a `t` function that more closely aligns with our usage
//
// This lets us avoid unsafely sending `HTMLText` to Locize without first unwrapping it.
// See `tHTMLText` for a `t` function that can translate with `HTMLText`
//
// https://app.asana.com/0/1203006199529183/1206496273151712/f
// eslint-disable-next-line react-refresh/only-export-components
function useSafeTranslation(ns) {
    return (0, react_i18next_1.useTranslation)(ns);
}
exports.useSafeTranslation = useSafeTranslation;
// Returns a `Translation` that more closely aligns with our usage
function SafeTranslation(props) {
    return React.createElement(react_i18next_1.Translation, Object.assign({}, props));
}
exports.SafeTranslation = SafeTranslation;
// Returns a `Trans` that more closely aligns with our usage
function SafeTrans(props) {
    // @ts-ignore You can pass a custom `t` but `Trans` doesn't like that
    return React.createElement(react_i18next_1.Trans, Object.assign({}, props));
}
exports.SafeTrans = SafeTrans;
/**
 * This can be useful when your translation template includes a mixture of both
 * HTML and non-HTML values. Values of type 'string' will be interpolated
 * as plain text (escaped), and values of type 'HTMLText' will be included as
 * HTML (unescaped).
 *
 * Any HTML in the translation template will be rendered as HTML (not escaped).
 */
// https://app.asana.com/0/1203006199529183/1206496273151712/f
// eslint-disable-next-line react-refresh/only-export-components
function translateHTML(t, i18nKey, values) {
    return (0, html_text_1.toHTMLTextDangerous)(t(i18nKey, (0, lodash_1.mapValues)(values !== null && values !== void 0 ? values : {}, translationValueHTMLToString)));
}
exports.translateHTML = translateHTML;
/** Same as 'translateHTML' but wraps the result in a 'span' element. */
// https://app.asana.com/0/1203006199529183/1206496273151712/f
// eslint-disable-next-line react-refresh/only-export-components
function translateHTMLSpan(t, i18nKey, values) {
    return React.createElement("span", Object.assign({}, (0, html_text_1.innerHTMLText)(translateHTML(t, i18nKey, values))));
}
exports.translateHTMLSpan = translateHTMLSpan;
function escape(r) {
    return r.replace(/[<>&]/g, r => `&#${r.charCodeAt(0)};`);
}
function translationValueHTMLToString(x) {
    if (typeof x === 'string') {
        return escape(x);
    }
    else {
        switch (x.tag) {
            case 'html-text':
                return x.value;
            default:
                (0, exhaustive_1.exhaustive)(x.tag);
        }
    }
}
