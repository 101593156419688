"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUnmanagedCourse = void 0;
const parser_1 = require("@freckle/parser");
exports.parseUnmanagedCourse = (0, parser_1.record)({
    id: (0, parser_1.number)(),
    name: (0, parser_1.string)(),
    courseSisId: (0, parser_1.nullable)((0, parser_1.string)()),
    createdAt: (0, parser_1.date)(),
    teacherId: (0, parser_1.number)(),
    teacherFirstName: (0, parser_1.string)(),
    teacherLastName: (0, parser_1.string)(),
});
