"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseMathTargetedDepthOfKnowledgeMetadata = exports.parseMathDepthOfKnowledgeMetadata = exports.parseMathFactPracticeMetadata = exports.parseMathNumberBasicsMetadata = exports.parseMathNumberFactsMetadata = exports.parseMathIblsMetadata = exports.parseMathAssessmentsMetadata = exports.parseMathFocusSkillsPracticeMetadata = exports.parseMathTargetedMetadata = exports.parseMathAdaptiveMetadata = void 0;
const parser_1 = require("@freckle/parser");
const rl_domain_1 = require("@freckle/educator-entities/ts/common/models/rl-domain");
const math_fact_practice_operation_1 = require("@freckle/educator-entities/ts/math/fact-practice/models/math-fact-practice-operation");
const math_fact_practice_snapshot_1 = require("@freckle/educator-entities/ts/math/fact-practice/models/math-fact-practice-snapshot");
const rl_standard_1 = require("./../../../common/models/rl-standard");
const rl_standard_2 = require("./../../../common/models/rl-standard");
const rl_skill_1 = require("./../../../common/models/rl-skill");
const MathAssessmentTopic = __importStar(require("./../../../math/assessment/types/assessment-topic"));
exports.parseMathAdaptiveMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('math_adaptive'),
    contents: (0, parser_1.record)({
        startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
        domain: rl_domain_1.parseDomainAttrs,
        currentStandard: (0, parser_1.nullable)(rl_standard_2.parseStandardAttrs),
    }),
});
const parseMathTargetedSessionType = (0, parser_1.oneOf)(`SessionTypeT`, [
    'practice',
    'prerequisite',
    'review',
]);
exports.parseMathTargetedMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('math_targeted'),
    contents: (0, parser_1.record)({
        standard: rl_standard_1.parseStandardWithDomainAttrs,
        skill: (0, parser_1.nullable)(rl_skill_1.parseRlSkillAttrs),
        startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
        originalAssignmentId: (0, parser_1.nullable)((0, parser_1.number)()),
        numQuestionsAnswered: (0, parser_1.nullable)((0, parser_1.number)()),
        totalWorth: (0, parser_1.nullable)((0, parser_1.number)()),
        completedWorth: (0, parser_1.nullable)((0, parser_1.number)()),
        isSupported: (0, parser_1.nullable)((0, parser_1.boolean)()),
        numQuestions: (0, parser_1.number)(),
        type: parseMathTargetedSessionType,
    }),
});
exports.parseMathFocusSkillsPracticeMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('focus_skills_practice'),
    contents: (0, parser_1.record)({
        standard: rl_standard_1.parseStandardWithDomainAttrs,
        skill: (0, parser_1.nullable)(rl_skill_1.parseRlSkillAttrs),
        startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
        originalAssignmentId: (0, parser_1.nullable)((0, parser_1.number)()),
        numQuestionsAnswered: (0, parser_1.nullable)((0, parser_1.number)()),
        totalWorth: (0, parser_1.nullable)((0, parser_1.number)()),
        completedWorth: (0, parser_1.nullable)((0, parser_1.number)()),
        isSupported: (0, parser_1.nullable)((0, parser_1.boolean)()),
        numQuestions: (0, parser_1.number)(),
        type: parseMathTargetedSessionType,
    }),
});
const parseMathAssessmentsContents = (0, parser_1.record)({
    topic: MathAssessmentTopic.parse,
    startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
});
exports.parseMathAssessmentsMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('math_assessments'),
    contents: parseMathAssessmentsContents,
});
exports.parseMathIblsMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('math_ibls'),
    contents: (0, parser_1.record)({
        lessonId: (0, parser_1.number)(),
        day: (0, parser_1.number)(),
        title: (0, parser_1.string)(),
        standard: rl_standard_1.parseStandardWithDomainAttrs,
    }),
});
/**
 * Math Number Facts
 */
exports.parseMathNumberFactsMetadata = (0, parser_1.record)({ tag: (0, parser_1.tag)('math_number_facts') });
exports.parseMathNumberBasicsMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('math_number_basics'),
    contents: (0, parser_1.record)({ skillCode: (0, parser_1.string)() }),
});
exports.parseMathFactPracticeMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('math_fact_practice'),
    contents: (0, parser_1.record)({
        operations: (0, parser_1.nonEmptyArray)(math_fact_practice_operation_1.mathFactPracticeOperationTypeParser),
        snapshots: (0, parser_1.array)((0, parser_1.record)({
            operation: math_fact_practice_operation_1.mathFactPracticeOperationTypeParser,
            snapshot: (0, parser_1.nullable)(math_fact_practice_snapshot_1.parseFactPracticeSnapshot),
        })),
        startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
    }),
});
exports.parseMathDepthOfKnowledgeMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('math_depth_of_knowledge_practice'),
    contents: (0, parser_1.record)({ questionSetUuid: (0, parser_1.string)() }),
});
exports.parseMathTargetedDepthOfKnowledgeMetadata = (0, parser_1.record)({
    tag: (0, parser_1.tag)('math_targeted_depth_of_knowledge_practice'),
    contents: (0, parser_1.record)({
        questionSetUuid: (0, parser_1.string)(),
        standard: rl_standard_1.parseStandardWithDomainAttrs,
    }),
});
