"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const confirmation_modal_1 = require("@freckle/educator-materials/ts/components/confirmation-modal");
const confirm_merge_modal_module_scss_1 = require("./confirm-merge-modal.module.scss");
function ConfirmMergeModal(props) {
    const { show, onCancel, onConfirm } = props;
    const { t } = (0, translate_1.useSafeTranslation)();
    return (React.createElement(confirmation_modal_1.ConfirmationModal, { title: t('CONFIRM_MERGE_MODAL_TITLE'), showModal: show, yesText: t('CONFIRM_MERGE_MODAL_BUTTON_YES'), yesBtnDataTest: "merge-confirmation-modal-confirm", noText: t('CONFIRM_MERGE_MODAL_BUTTON_NO'), noBtnDataTest: "merge-confirmation-modal-cancel", onClickYes: onConfirm, onClose: onCancel, addClass: confirm_merge_modal_module_scss_1.confirmMergeModal }, t('CONFIRM_MERGE_MODAL_WARNING')));
}
exports.default = ConfirmMergeModal;
