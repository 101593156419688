"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dashboard = void 0;
const React = __importStar(require("react"));
const maybe_1 = require("@freckle/maybe");
const top_bar_1 = require("@freckle/school/ts/common/components/dashboard/top-bar");
const sidebar_1 = require("@freckle/school/ts/common/components/dashboard/sidebar");
const Dashboard = ({ schools, schoolAdmin, tabId, children }) => {
    const countryCode = (0, maybe_1.maybe)(() => null, school => school.countryCode, schools.find(school => school.countryCode !== null && school.countryCode !== undefined));
    return (React.createElement("div", { className: "container-fluid" },
        React.createElement("div", { className: "row" },
            React.createElement(sidebar_1.Sidebar, { tabId: tabId, countryCode: countryCode }),
            React.createElement("div", { id: "content" },
                React.createElement(top_bar_1.TopBar, { schoolAdmin: schoolAdmin }),
                React.createElement("div", { className: "row main-content" },
                    React.createElement("div", { className: "col-12" }, children))))));
};
exports.Dashboard = Dashboard;
