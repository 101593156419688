"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATHS = void 0;
const maybe_1 = require("@freckle/maybe");
const env_1 = require("./env");
const baseCFProdAssetsUrl = 'https://classroom-assets.freckle.com';
const baseImageAssetsUrl = 'https://images.freckle.com';
const baseVendorAssetsUrl = 'https://vendor-assets.freckle.com';
const hostname = window.location.hostname;
// everything AFTER student or teacher in the domain name
const urlRegex = /(localhost|((classroom|student|school|console|sso)((.*)\.(freckle|freckletest|localhost).com)))/;
const defaultValue = '';
const fullSuffix = (0, maybe_1.maybe)(() => defaultValue, regexMatch => (0, maybe_1.fromMaybe)(() => defaultValue, regexMatch[4]), urlRegex.exec(hostname));
const unversionedApiUrl = `https://api${fullSuffix}`;
const textAssetsUrl = env_1.CONTENT_URL;
const teacherDashUrl = `https://classroom${fullSuffix}`;
const studentClientUrl = `https://student${fullSuffix}`;
const schoolDashUrl = `https://school${fullSuffix}`;
const landingUrl = `https://www${fullSuffix}`;
const blogUrl = 'https://blog.freckle.com';
const translationsUrl = `https://translations.freckle.com`;
const ttsUrl = 'https://tts.freckle.com';
const VITE_PORT = 5173;
function getApiUrl() {
    const [application, server] = window.location.hostname.split('.');
    if (server === 'localhost' && parseInt(window.location.port, 10) === VITE_PORT) {
        return `https://${window.location.hostname}:${VITE_PORT}/api`;
    }
    else if (application === 'localhost') {
        return `https://${window.location.hostname}`;
    }
    else {
        return unversionedApiUrl;
    }
}
exports.PATHS = {
    unversionedAPIUrl: getApiUrl(),
    imageAssetsUrl: baseImageAssetsUrl,
    prodAssetsUrl: baseCFProdAssetsUrl,
    vendorAssetsUrl: baseVendorAssetsUrl,
    textAssetsUrl,
    teacherDashUrl,
    studentClientUrl,
    schoolDashUrl,
    landingUrl,
    blogUrl,
    translationsUrl,
    ttsUrl,
};
