"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatGrade = exports.formatMoment = exports.formatRoundedNumber = void 0;
const React = __importStar(require("react"));
function formatRoundedNumber(digit) {
    return function roundedNumber(cell) {
        return cell !== null && cell !== undefined ? cell.toFixed(digit) : '—';
    };
}
exports.formatRoundedNumber = formatRoundedNumber;
const formatMoment = (format, cell) => {
    if (cell === null || cell === undefined) {
        // eslint-disable-next-line i18next/no-literal-string
        return React.createElement("span", null, "\u2014");
    }
    return React.createElement("span", null, cell.format(format));
};
exports.formatMoment = formatMoment;
function formatGrade(cell, t) {
    if (cell === null || cell === undefined) {
        return '—';
    }
    return t('DISPLAY_GRADE_ORDINAL', { grade: cell });
}
exports.formatGrade = formatGrade;
