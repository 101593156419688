"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnswerCorrectness = exports.parseMathQuestionGraphQuadraticMetadata = void 0;
const parser_1 = require("@freckle/parser");
const equation_1 = require("@freckle/educator-entities/ts/math/common/logic/equation");
const common_parser_1 = require("@freckle/educator-entities/ts/math/common/models/questions/common-parser");
const rawGraphQuadraticEquationCoefficientsAttrs = (0, parser_1.record)({
    coefficientX2: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-x-2'),
    coefficientX1: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-x-1'),
    coefficientX0: (0, parser_1.field)((0, parser_1.number)(), 'coefficient-x-0'),
});
const parseGraphQuadraticEquationCoefficients = (0, parser_1.firstOf)((0, parser_1.record)({
    graphQuadraticEquationCoefficients: (0, parser_1.field)(rawGraphQuadraticEquationCoefficientsAttrs, 'graph-quadratic-equation-coefficients'),
}), (0, parser_1.record)({
    graphQuadraticEquationCoefficients: (0, parser_1.field)((0, parser_1.obfuscated)(rawGraphQuadraticEquationCoefficientsAttrs), 'obfuscated-graph-quadratic-equation-coefficients'),
}));
const parseMathQuestionGraphQuadraticAttrs = (0, parser_1.merge)(common_parser_1.parseCommonMathGraphQuestionAttrs, parseGraphQuadraticEquationCoefficients);
exports.parseMathQuestionGraphQuadraticMetadata = (0, parser_1.record)({
    answerType: (0, parser_1.field)((0, parser_1.literal)('graph_quadratic'), 'answer-type'),
    content: (0, parser_1.onSelf)(parseMathQuestionGraphQuadraticAttrs),
});
// Return :: Double (either 0 or 100)
function getAnswerCorrectness(metadata, answer) {
    const { vertex, point } = answer;
    const { graphQuadraticEquationCoefficients } = metadata;
    const isCorrect = (0, equation_1.validateVertexAndPointForQuadraticEquation)(vertex, point, graphQuadraticEquationCoefficients);
    return isCorrect ? 100 : 0;
}
exports.getAnswerCorrectness = getAnswerCorrectness;
