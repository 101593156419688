"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.intersperse = exports.safeArrayAccess = void 0;
function safeArrayAccess(a, index) {
    return a[index];
}
exports.safeArrayAccess = safeArrayAccess;
function intersperse(separator, list) {
    return list.length
        ? // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
            [...Array(2 * list.length - 1)].map((_, i) => (i % 2 ? separator : list[i / 2]))
        : [];
}
exports.intersperse = intersperse;
