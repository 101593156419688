"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnauthenticatedFooter = void 0;
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const footer_module_scss_1 = require("../footer/footer.module.scss");
const link_1 = require("../link");
function UnauthenticatedFooter() {
    const { t } = (0, translate_1.useSafeTranslation)();
    const childrenPrivacyNoticeLink = "https://renaissance.widen.net/view/pdf/9hoolemzvy/Renaissance---Childrens-Privacy-Notice.pdf?t.download=true&u=zceria";
    return (React.createElement("div", { className: `${footer_module_scss_1.footerMain} ${footer_module_scss_1.footerLoginMain}` },
        React.createElement("div", { className: footer_module_scss_1.footerWrap },
            React.createElement("p", { className: footer_module_scss_1.footerText },
                React.createElement(translate_1.SafeTrans, { i18nKey: "FOOTER_FRECKLE_TEXT" })),
            React.createElement("p", { className: footer_module_scss_1.footerText },
                "\u00A9 ",
                ' ',
                React.createElement(translate_1.SafeTrans, { i18nKey: "FOOTER_RIGHT_RESERVED_TEXT" })),
            React.createElement(link_1.Link, { href: childrenPrivacyNoticeLink, target: "_blank", reactRouter: false }, t('FOOTER_CHILDRENS_PRIVACY_NOTICE_TEXT'))),
        React.createElement("p", { className: footer_module_scss_1.footerTextTwo },
            React.createElement(translate_1.SafeTrans, { i18nKey: "FOOTER_CONTACT_INFORMATION_TEXT" }))));
}
exports.UnauthenticatedFooter = UnauthenticatedFooter;
