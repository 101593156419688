"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CourseTabHandler = void 0;
const React = __importStar(require("react"));
const use_params_1 = require("@freckle/school/ts/hooks/use-params");
const filters_1 = require("@freckle/school/ts/manage-teachers/api-helpers/filters");
const course_tab_1 = require("./course-tab");
const constants_1 = require("./constants");
function CourseTabHandler(props) {
    const { tableParams, summary, ...rest } = props;
    // SisId filter
    const mSisIdExists = (0, use_params_1.useQueryParamAsString)('sisIdExists');
    const sisIdExists = mSisIdExists === 'true'
        ? { sisIdExists: true }
        : mSisIdExists === 'false'
            ? { sisIdExists: false }
            : {};
    // StudentId Filter
    const mStudentId = (0, use_params_1.useQueryParamAsNum)('studentId');
    const mStudentName = (0, use_params_1.useQueryParamAsString)('studentName');
    const student = mStudentId !== null && mStudentName !== null
        ? { student: { id: mStudentId, name: decodeURIComponent(mStudentName) } }
        : {};
    // Table filters
    const courseName = (0, use_params_1.useQueryParamAsString)('courseName');
    const courseNameObj = courseName !== null && courseName !== '' ? { courseName } : {};
    const teacherName = (0, use_params_1.useQueryParamAsString)('teacherName');
    const teacherNameObj = teacherName !== null && teacherName !== '' ? { teacherName } : {};
    const studentGrade = (0, use_params_1.useQueryParamAsString)('studentGrade');
    const studentGradeObj = studentGrade !== null && studentGrade !== '' ? { studentGrade } : {};
    const identityProviders = (0, filters_1.parseDefaultIdpsFromQueryParam)((0, use_params_1.useQueryParamAsString)('identityProviders'), {
        tag: 'for-course',
        ...summary,
    }, { tag: 'summary' });
    const tableParamsWithFilter = {
        ...tableParams,
        additionalFilters: {
            tag: constants_1.COURSE_FILTER_TAG,
            ...sisIdExists,
            ...student,
            ...courseNameObj,
            ...teacherNameObj,
            ...studentGradeObj,
            identityProviders,
        },
    };
    return React.createElement(course_tab_1.CourseTabContainer, { tableParams: tableParamsWithFilter, summary: summary, ...rest });
}
exports.CourseTabHandler = CourseTabHandler;
