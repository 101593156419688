"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const React = __importStar(require("react"));
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const maybe_1 = require("@freckle/maybe");
const exhaustive_1 = require("@freckle/exhaustive");
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const styleCSS = __importStar(require("./button.module.scss"));
/**
 * This is the standard Button.
 *
 * It contains the most used attribute of the regular HTML `<button>` tag.
 *
 */
exports.Button = React.forwardRef((_a, ref) => {
    var { style = 'primary', size = 'md', disabled = false, type = 'button' } = _a, restProps = __rest(_a, ["style", "size", "disabled", "type"]);
    const { onClick, children, id, dataTest, dataPendo, tabIndex, autoFocus, title, ariaLabel, ariaHaspopup, ariaExpanded, ariaControls, ariaChecked, role, leftIcon, rightIcon, onMouseEnter, onMouseLeave, onMouseOver, onMouseOut, form, } = restProps;
    const props = Object.assign({ style,
        size,
        disabled,
        type }, restProps);
    const className = getClassName(props);
    const handleClick = disabled ? preventClick : onClick;
    const idObj = { id: (0, maybe_1.asHTMLAttributeValue)(id) };
    const titleObj = { title: (0, maybe_1.asHTMLAttributeValue)(title) };
    const dataTestObj = { 'data-test': (0, maybe_1.asHTMLAttributeValue)(dataTest) };
    const dataPendoObj = { 'data-pendo': (0, maybe_1.asHTMLAttributeValue)(dataPendo) };
    const disabledObj = { disabled: disabled ? true : undefined };
    const autoFocusObj = { autoFocus: autoFocus === true ? true : undefined };
    const tabIndexObj = { tabIndex: (0, maybe_1.asHTMLAttributeValue)(tabIndex) };
    const onMouseEnterObj = { onMouseEnter: (0, maybe_1.asHTMLAttributeValue)(onMouseEnter) };
    const onMouseLeaveObj = { onMouseLeave: (0, maybe_1.asHTMLAttributeValue)(onMouseLeave) };
    const onMouseOverObj = { onMouseOver: (0, maybe_1.asHTMLAttributeValue)(onMouseOver) };
    const onMouseOutObj = { onMouseOut: (0, maybe_1.asHTMLAttributeValue)(onMouseOut) };
    const formObj = { form: (0, maybe_1.asHTMLAttributeValue)(form) };
    const ariaControlsObj = { 'aria-controls': (0, maybe_1.asHTMLAttributeValue)(ariaControls) };
    const roleObj = { role: (0, maybe_1.asHTMLAttributeValue)(role) };
    const ariaCheckedObj = { 'aria-checked': (0, maybe_1.asHTMLAttributeValue)(ariaChecked) };
    const additionalProps = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, disabledObj), idObj), titleObj), autoFocusObj), dataTestObj), dataPendoObj), tabIndexObj), onMouseEnterObj), onMouseLeaveObj), onMouseOverObj), onMouseOutObj), formObj), ariaControlsObj), ariaCheckedObj), roleObj);
    const leftIconNode = leftIcon !== undefined ? (React.createElement("span", { className: styleCSS.leftIconContainer },
        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: leftIcon, "aria-hidden": "true" }))) : null;
    const rightIconNode = rightIcon !== undefined ? (React.createElement("span", { className: styleCSS.rightIconContainer },
        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: rightIcon, "aria-hidden": "true" }))) : null;
    return (React.createElement("button", Object.assign({ ref: ref, onClick: handleClick, type: type, className: className, "aria-label": ariaLabel, "aria-haspopup": ariaHaspopup, "aria-expanded": ariaExpanded }, additionalProps),
        leftIconNode,
        children,
        rightIconNode));
});
exports.Button.displayName = 'Button';
function preventClick(e) {
    e.preventDefault();
}
function getClassName(props) {
    const { addClass, fullWidth, noWrap, style, size, selected, rounded } = props;
    const baseClass = style !== 'link' ? styleCSS.button : styleCSS.link;
    return (0, classnames_1.addIfClassNames)([
        [true, `${baseClass} ${styleCSS[style]}`],
        [style !== 'link', styleCSS[size]],
        [fullWidth, styleCSS.fullWidth],
        [noWrap, styleCSS.noWrap],
        [selected, getSelectedStyle(style)],
        [rounded, styleCSS.rounded],
        [addClass !== null && addClass !== undefined, addClass],
    ]);
}
function getSelectedStyle(buttonStyle) {
    switch (buttonStyle) {
        case 'primary':
        case 'secondary':
        case 'tertiary':
        case 'danger':
        case 'warning':
        case 'success':
        case 'premium':
            return styleCSS.selectedStyle;
        case 'incorrect':
        case 'link':
        case null:
        case undefined:
            return '';
        default:
            return (0, exhaustive_1.exhaustive)(buttonStyle, 'ButtonStyleT');
    }
}
