"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productToPracticeTypeFilter = void 0;
const exhaustive_1 = require("@freckle/exhaustive");
/** Please See: Autodocodec.HasCodec PracticeMode in backend */
const productToPracticeTypeFilter = (product) => {
    switch (product) {
        case 'math_adaptive':
        case 'math_targeted':
        case 'math_fact_practice':
        case 'ela_decodables':
        case 'ela_targeted_skills_practice':
        case 'ela_focus_skills_practice':
        case 'ela_word_study':
        case 'ela_sight_words':
            // These are identical
            return product;
        case 'focus_skills_practice':
            return 'focus_skills_practice';
        case 'math_assessments':
            return 'math_assessment';
        case 'math_ibls':
            return 'math_ibl';
        case 'math_number_facts':
            return 'number_facts';
        case 'math_number_basics':
            return 'number_basics';
        case 'math_depth_of_knowledge_practice':
            return 'math_depth_of_knowledge';
        case 'math_targeted_depth_of_knowledge_practice':
            return 'math_targeted_depth_of_knowledge';
        case 'ela_adaptive_skills_practice':
            return 'ela_adaptive_skills';
        case 'ela_articles_reading':
            return 'ela_article_reading';
        case 'ela_articles_writing':
            return 'ela_article_writing';
        case 'ela_assessments':
            return 'ela_assessment';
        case 'science_articles_reading':
            return 'science_reading';
        case 'science_articles_writing':
            return 'science_writing';
        case 'social_studies_articles_reading':
            return 'social_studies_reading';
        case 'social_studies_articles_writing':
            return 'social_studies_writing';
        default:
            return (0, exhaustive_1.exhaustive)(product, 'ProductT');
    }
};
exports.productToPracticeTypeFilter = productToPracticeTypeFilter;
