"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitialFilters = void 0;
const maybe_1 = require("@freckle/maybe");
const teacher_role_1 = require("@freckle/educator-entities/ts/common/helpers/teacher-role");
function getInitialFilters(tableParams) {
    const { additionalFilters } = tableParams;
    const empty = () => '';
    const emptyArray = () => [];
    return {
        teacherName: (0, maybe_1.maybe)(empty, filters => (0, maybe_1.fromMaybe)(empty, filters.name), additionalFilters),
        email: (0, maybe_1.maybe)(empty, filters => (0, maybe_1.fromMaybe)(empty, filters.email), additionalFilters),
        schoolName: (0, maybe_1.maybe)(empty, filters => (0, maybe_1.maybe)(empty, ({ name }) => name, filters.school), additionalFilters),
        role: (0, maybe_1.mmap)(filters => (0, maybe_1.mmap)(teacher_role_1.TeacherRoles.parse, filters.role), additionalFilters),
        studentGrade: (0, maybe_1.maybe)(empty, filters => (0, maybe_1.fromMaybe)(empty, filters.studentGrade), additionalFilters),
        sources: (0, maybe_1.maybe)(emptyArray, filters => (0, maybe_1.fromMaybe)(emptyArray, filters.identityProviders), additionalFilters),
    };
}
exports.getInitialFilters = getInitialFilters;
