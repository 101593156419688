"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendingOperationWhenLocation = exports.PendingOperation = void 0;
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const exhaustive_1 = require("@freckle/exhaustive");
const use_operation_1 = require("@freckle/educator-entities/ts/common/hooks/use-operation");
const spinner_1 = require("@freckle/educator-materials/ts/components/spinner");
const basic_modal_1 = require("@freckle/educator-materials/ts/components/basic-modal");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const flex_1 = require("@freckle/educator-materials/ts/components/flex");
const react_hooks_1 = require("@freckle/react-hooks");
const PendingOperation = ({ operationLocation, onSuccess, onFailure, i18nKeys, }) => {
    const { operation } = (0, use_operation_1.useOperation)({ operationLocation });
    const operationStatus = operation?.status;
    (0, react_hooks_1.useSafeEffectExtraDeps)(({ onSuccess, onFailure }) => {
        switch (operationStatus) {
            case undefined:
            case 'not_started':
            case 'running':
                return;
            case 'succeeded':
                return onSuccess();
            case 'disappeared':
            case 'failed':
                return onFailure();
            default:
                return (0, exhaustive_1.exhaustive)(operationStatus);
        }
    }, [operationStatus], { onSuccess, onFailure });
    switch (operationStatus) {
        case undefined:
        case 'not_started':
        case 'running':
            return React.createElement(Modal, { i18nKeys: i18nKeys });
        case 'succeeded':
        case 'failed':
        case 'disappeared':
            return React.createElement(React.Fragment, null);
        default:
            return (0, exhaustive_1.exhaustive)(operationStatus);
    }
};
exports.PendingOperation = PendingOperation;
const Modal = ({ i18nKeys: { title, heading, body }, }) => {
    const { t } = (0, translate_1.useSafeTranslation)();
    return (React.createElement(basic_modal_1.BasicModal, { show: true, onHide: () => { }, title: t(title) },
        React.createElement(flex_1.Flex, { flexDirection: "column" },
            React.createElement(spinner_1.Spinner, null),
            React.createElement(typography_1.Text, { style: "subtitle-2" }, t(heading)),
            React.createElement(typography_1.Text, { style: "body-2" }, t(body)))));
};
const PendingOperationWhenLocation = ({ operationLocation, ...rest }) => {
    const result = operationLocation === null || operationLocation === undefined ? null : (React.createElement(exports.PendingOperation, { ...rest, operationLocation: operationLocation }));
    return result;
};
exports.PendingOperationWhenLocation = PendingOperationWhenLocation;
