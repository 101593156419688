"use strict";
// File generated by generate-openapi-enums.js, do not change directly
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentAreas = void 0;
const exhaustive_1 = require("@freckle/exhaustive");
const maybe_1 = require("@freckle/maybe");
const Math = 'math';
const Ela = 'ela';
// Get all ContentArea values
function all() {
    return [Math, Ela];
}
// Parse ContentArea from a raw string, returns null if no match
function parse(raw) {
    var _a;
    return (_a = all().find(e => e === raw)) !== null && _a !== void 0 ? _a : null;
}
// Parse ContentArea from a raw string, returns default if no match
function parseWithDefault(raw, def) {
    var _a;
    return (_a = parse(raw)) !== null && _a !== void 0 ? _a : def;
}
// Parse ContentArea from a raw string, throws if no match
function unsafeParse(raw) {
    return (0, maybe_1.fromJust)(parse(raw), `Invalid ContentArea: ${raw}`);
}
// Make a function that will dispatch cases for ContentArea
function mkMatch({ onMath, onEla, }) {
    return (v) => {
        switch (v) {
            case 'math': return onMath();
            case 'ela': return onEla();
            default:
                return (0, exhaustive_1.exhaustive)(v);
        }
    };
}
function match(v, cases) {
    return mkMatch(cases)(v);
}
exports.ContentAreas = {
    // Constants
    Math,
    Ela,
    // Enumeration
    all,
    // Parsing
    parse,
    unsafeParse,
    parseWithDefault,
    // Case dispatch
    mkMatch,
    match,
};
