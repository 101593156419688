"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sidebar = void 0;
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const paths_1 = require("@freckle/educator-entities/ts/common/helpers/paths");
const admin_1 = require("@freckle/educator-materials/ts/components/support-button/admin");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const link_1 = require("@freckle/educator-materials/ts/components/link");
const routes_1 = require("@freckle/school/ts/common/routers/routes");
const sidebar_module_scss_1 = require("./sidebar.module.scss");
const sidebarLinks = [
    {
        tabId: 'dashboard',
        name: 'Dashboard',
        route: routes_1.Routes.dashboard(),
        dataTest: 'sidebar-teacher-link',
    },
    {
        tabId: 'growth',
        name: 'Growth Data',
        route: routes_1.Routes.growth(),
        dataTest: 'sidebar-growth-link',
    },
    {
        tabId: 'usage',
        name: 'Usage Data',
        route: routes_1.Routes.usage(),
        dataTest: 'sidebar-usage-link',
    },
    {
        tabId: 'proficiency-report',
        name: 'Proficiency Data',
        route: routes_1.Routes.schoolProficiencyReport(),
        dataTest: 'sidebar-reports-link',
    },
    {
        tabId: 'assessments',
        name: 'School Assessments',
        route: routes_1.Routes.assessments(),
        dataTest: 'sidebar-assessments-link',
    },
];
function Sidebar(props) {
    const { tabId: activeTabId, countryCode } = props;
    const { t } = (0, translate_1.useSafeTranslation)();
    const sidebarLinksNode = sidebarLinks.map(({ tabId, name, route, dataTest }) => {
        const activeClass = tabId === activeTabId ? 'active' : '';
        return (React.createElement("li", { className: activeClass, key: tabId },
            React.createElement(link_1.Link, { href: route, addClass: sidebar_module_scss_1.link, dataTest: dataTest, reactRouter: true },
                React.createElement(typography_1.Text, { style: "subtitle-2", addClass: sidebar_module_scss_1.menuLink }, name))));
    });
    return (React.createElement("div", { className: "sidebar hidden-print" },
        React.createElement("div", { className: "sidebar-header" },
            React.createElement(link_1.Link, { href: "/", reactRouter: true },
                React.createElement("img", { src: `${paths_1.PATHS.imageAssetsUrl}/company-logos/renaissance-freckle-logo-white.svg`, alt: t('SIDEBAR_LINK_LOGO_ALT'), title: t('SIDEBAR_LINK_LOGO_TITLE'), className: "freckle-logo" }))),
        React.createElement("div", { className: "sidebar-links" },
            React.createElement("ul", { className: "list-unstyled" }, sidebarLinksNode)),
        React.createElement("div", { className: sidebar_module_scss_1.supportButtonWrapper },
            React.createElement(admin_1.AdminSupportButton, { countryCode: countryCode }))));
}
exports.Sidebar = Sidebar;
