"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSchoolAdmin = void 0;
const use_async_1 = require("@freckle/educator-entities/ts/common/hooks/use-async");
const school_admin_1 = require("@freckle/school/ts/users/models/school-admin");
function useSchoolAdmin(options) {
    const defaultOptions = { staleTime: 60 * 1000 };
    return (0, use_async_1.useAsync)(school_admin_1.fetchSchoolAdmin, ['fetchSchoolAdmin'], { ...defaultOptions, ...options });
}
exports.useSchoolAdmin = useSchoolAdmin;
