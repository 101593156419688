"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cellFromRow = exports.columnWhen = exports.disableSortBy = exports.idAccessorFromProp = exports.aligned = exports.Table = void 0;
var table_1 = require("@freckle/educator-materials/ts/components/table/table");
Object.defineProperty(exports, "Table", { enumerable: true, get: function () { return table_1.Table; } });
var table_2 = require("@freckle/educator-materials/ts/logic/table/table");
Object.defineProperty(exports, "aligned", { enumerable: true, get: function () { return table_2.aligned; } });
Object.defineProperty(exports, "idAccessorFromProp", { enumerable: true, get: function () { return table_2.idAccessorFromProp; } });
Object.defineProperty(exports, "disableSortBy", { enumerable: true, get: function () { return table_2.disableSortBy; } });
Object.defineProperty(exports, "columnWhen", { enumerable: true, get: function () { return table_2.columnWhen; } });
Object.defineProperty(exports, "cellFromRow", { enumerable: true, get: function () { return table_2.cellFromRow; } });
