"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEqual = exports.toString = exports.fromString = exports.parse = void 0;
const parser_1 = require("@freckle/parser");
exports.parse = parser_1.Parser.map((0, parser_1.string)(), 'RlStandardIdT.fromString', fromString);
function fromString(rlStandardId) {
    return rlStandardId;
}
exports.fromString = fromString;
function toString(rlStandardId) {
    return rlStandardId;
}
exports.toString = toString;
function isEqual(a, b) {
    return a === b;
}
exports.isEqual = isEqual;
