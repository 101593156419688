"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const rl_standard_set_1 = require("@freckle/educator-entities/ts/common/models/rl-standard-set");
const content_area_1 = require("@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area");
const index_1 = require("@freckle/educator-entities/ts/common/hooks/use-async/index");
const use_school_admin_1 = require("@freckle/school/ts/hooks/use-school-admin");
const settings_container_1 = require("@freckle/school/ts/settings/components/settings-container");
const with_resources_1 = require("@freckle/school/ts/common/components/with-resources");
function SettingsComponentHandler() {
    const schoolAdmin = (0, use_school_admin_1.useSchoolAdmin)();
    const mathStandardSets = (0, index_1.useAsync)(() => (0, rl_standard_set_1.fetchRlStandardSets)(content_area_1.ContentAreas.Math), ['fetchRlStandardSets', content_area_1.ContentAreas.Math]);
    return (React.createElement(with_resources_1.WithResources, { resources: { schoolAdmin, mathStandardSets }, render: ({ schoolAdmin, mathStandardSets }) => (React.createElement(settings_container_1.SettingsComponentContainer, { schoolAdmin: schoolAdmin, mathStandardSets: mathStandardSets })) }));
}
exports.default = SettingsComponentHandler;
