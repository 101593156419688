"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const maybe_1 = require("@freckle/maybe");
const table_1 = require("@freckle/educator-materials/ts/components/table/table");
const table_2 = require("@freckle/educator-materials/ts/logic/table/table");
const teacher_1 = require("@freckle/educator-entities/ts/users/models/teacher");
const common_bootstrap_table_helper_1 = require("@freckle/educator-entities/ts/common/helpers/common-bootstrap-table-helper");
const link_1 = require("@freckle/educator-materials/ts/components/link");
const query_params_1 = require("@freckle/educator-entities/ts/common/helpers/routers/query-params");
const routes_1 = require("@freckle/school/ts/common/routers/routes");
const utils_1 = require("@freckle/school/ts/proficiency-report/components/school/table/utils");
function ProficiencyReportTeacherTable({ tableRow, teachers, ...props }) {
    const { t } = (0, translate_1.useSafeTranslation)();
    return (React.createElement(table_1.Table, { data: tableRow.teacherGroups, columns: [
            {
                Header: t('PROFICIENCY_REPORT_TEACHER_TABLE_COLUMN_HEADER_TEACHER'),
                Cell: (0, table_2.cellFromRow)(({ teacherId }) => (React.createElement(TeacherName, { teacherId: teacherId, teachers: teachers }))),
                accessor: (d) => d.teacherId,
                id: 'id',
                ...(0, table_2.aligned)('left'),
            },
            {
                Header: t('PROFICIENCY_REPORT_TEACHER_TABLE_COLUMN_HEADER_GRADE_LEVEL'),
                Cell: (0, table_2.cellFromRow)(({ averageGradeLevel }) => (React.createElement(React.Fragment, null, (0, common_bootstrap_table_helper_1.formatRoundedNumber)(2)(averageGradeLevel)))),
                accessor: (d) => d.averageGradeLevel,
                id: 'avg',
                ...(0, table_2.aligned)('left'),
            },
            {
                Header: t('PROFICIENCY_REPORT_TEACHER_TABLE_COLUMN_HEADER_BELOW'),
                accessor: (d) => d.belowGradeLevel,
                id: 'below',
                ...(0, table_2.aligned)('left'),
            },
            {
                Header: t('PROFICIENCY_REPORT_TEACHER_TABLE_COLUMN_HEADER_WITHIN'),
                accessor: (d) => d.withinGradeLevel,
                id: 'within',
                ...(0, table_2.aligned)('left'),
            },
            {
                Header: t('PROFICIENCY_REPORT_TEACHER_TABLE_COLUMN_HEADER_ABOVE'),
                accessor: (d) => d.aboveGradeLevel,
                id: 'above',
                ...(0, table_2.aligned)('left'),
            },
            {
                Cell: (0, table_2.cellFromRow)((row) => React.createElement(TeacherProgressBar, { row: row })),
                accessor: (d) => d.averageGradeLevel,
                id: 'progress',
                ...(0, table_2.aligned)('center'),
            },
            {
                Cell: (0, table_2.cellFromRow)((row) => (React.createElement(TeacherReportLink, { ...props, tableRow: tableRow, row: row }))),
                accessor: (d) => d.averageGradeLevel,
                id: 'cta',
                ...(0, table_2.aligned)('right'),
            },
        ].map(c => ({ ...c, tdClassName: () => 'teacher-table-container' })) }));
}
exports.default = ProficiencyReportTeacherTable;
const TeacherName = ({ teacherId, teachers, }) => {
    const mTeacherModel = teachers.find(t => t.id === teacherId);
    const teacherModel = (0, maybe_1.fromJust)(mTeacherModel, `Could not find teacher: ${teacherId}`);
    return React.createElement(React.Fragment, null, (0, teacher_1.formattedName)(teacherModel));
};
const TeacherProgressBar = ({ row }) => (0, utils_1.formatProgressBar)(row.belowGradeLevel, row.withinGradeLevel, row.aboveGradeLevel, x => x);
const TeacherReportLink = ({ selectedGrade, selectedDomainId, row, tableRow: { schoolId }, }) => {
    const params = { grade: selectedGrade, domainId: selectedDomainId, schoolsIds: [schoolId] };
    const teacherReportRoute = (0, query_params_1.toFragment)(routes_1.Routes.teacherProficiencyReport(row.teacherId), params);
    return (React.createElement(link_1.Link, { href: teacherReportRoute, addClass: "report-detail-link", reactRouter: true },
        React.createElement("i", { className: "fa fa-arrow-circle-o-right" })));
};
