"use strict";
// Note:
// localePercent is used with fractional percentages (between 0 and 1 inclusive)
// For whole numbers, use the convenience method localePercentWhole
Object.defineProperty(exports, "__esModule", { value: true });
exports.localePercentWhole = void 0;
const localeStringDefaults = {
    minimumFractionDigits: 0,
};
const localePercent = (n, { minimumFractionDigits } = localeStringDefaults) => n.toLocaleString(undefined, { style: 'percent', minimumFractionDigits });
function localePercentWhole(whole) {
    return localePercent(whole / 100);
}
exports.localePercentWhole = localePercentWhole;
