"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StarIntegrationModalConfirmation = void 0;
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const paths_1 = require("@freckle/educator-entities/ts/common/helpers/paths");
const basic_modal_1 = require("@freckle/educator-materials/ts/components/basic-modal");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const button_1 = require("@freckle/educator-materials/ts/components/button");
const button_async_1 = require("@freckle/educator-materials/ts/components/button-async");
const button_spacer_1 = require("@freckle/educator-materials/ts/components/button-spacer");
const flex_1 = require("@freckle/educator-materials/ts/components/flex");
const img_1 = require("@freckle/educator-materials/ts/components/img");
const star_support_link_1 = require("@freckle/school/ts/manage-teachers/components/star-integration-panel/star-support-link");
const star_integration_modal_confirmation_module_scss_1 = require("./star-integration-modal-confirmation.module.scss");
function StarIntegrationModalConfirmation(props) {
    const { show, hasSharedRostering, onHide, onEnableStarIntegration } = props;
    const { t } = (0, translate_1.useSafeTranslation)();
    const footer = (React.createElement(button_spacer_1.ButtonSpacer, null,
        React.createElement(button_1.Button, { onClick: onHide, style: "secondary" }, t('MANAGE_TEACHERS_STAR_INTEGRATION_PANEL_MODAL_CANCEL_BUTTON')),
        React.createElement(button_async_1.ButtonAsync, { onClick: onEnableStarIntegration, style: "primary" }, t('MANAGE_TEACHERS_STAR_INTEGRATION_PANEL_MODAL_ENABLE_BUTTON'))));
    const imageTitle = t('MANAGE_TEACHERS_STAR_INTEGRATION_PANEL_MODAL_ROSTERING_ALERT_CONNTECT_TO_STAR_TITLE');
    const rosteringAlert = hasSharedRostering ? null : (React.createElement("div", null,
        React.createElement(typography_1.Text, { style: "body-2" },
            React.createElement("strong", null, t('MANAGE_TEACHERS_STAR_INTEGRATION_PANEL_MODAL_ROSTERING_ALERT'))),
        React.createElement(img_1.Image, { src: `${paths_1.PATHS.imageAssetsUrl}/teacher-dashboard/star-integration/connect-to-star.png`, alt: imageTitle, title: imageTitle, className: star_integration_modal_confirmation_module_scss_1.imageWrapper })));
    return (React.createElement(basic_modal_1.BasicModal, { show: show, onHide: onHide, title: t('MANAGE_TEACHERS_STAR_INTEGRATION_PANEL_MODAL_TITLE'), footer: footer },
        React.createElement(flex_1.Flex, { flexDirection: "column" },
            React.createElement(typography_1.Text, { style: "body-2" },
                t('MANAGE_TEACHERS_STAR_INTEGRATION_PANEL_MODAL_DESCRIPTION'),
                ' ',
                React.createElement(star_support_link_1.StarSupportLink, null, t('MANAGE_TEACHERS_STAR_INTEGRATION_PANEL_MODAL_LEARN_MORE_LINK'))),
            rosteringAlert,
            React.createElement(typography_1.Text, { style: "body-2" },
                React.createElement("strong", null, t('MANAGE_TEACHERS_STAR_INTEGRATION_PANEL_MODAL_QUESTION_TEXT'))))));
}
exports.StarIntegrationModalConfirmation = StarIntegrationModalConfirmation;
