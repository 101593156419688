"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CenteredPage = void 0;
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const img_1 = require("@freckle/educator-materials/ts/components/img");
const container_1 = require("@freckle/educator-materials/ts/components/container");
const paths_1 = require("@freckle/educator-entities/ts/common/helpers/paths");
const unauthenticated_footer_1 = require("@freckle/educator-materials/ts/components/footer/unauthenticated-footer");
const centered_page_module_scss_1 = require("./centered-page.module.scss");
function CenteredPage(p) {
    const { t } = (0, translate_1.useSafeTranslation)();
    return (React.createElement("div", { className: centered_page_module_scss_1.centeredPage },
        React.createElement(img_1.Image, { src: `${paths_1.PATHS.imageAssetsUrl}/company-logos/renaissance-freckle-logo.svg`, className: centered_page_module_scss_1.logo, alt: t('CENTERED_PAGE_IMG_LOGO_ALT'), title: t('CENTERED_PAGE_IMG_LOGO_TITLE') }),
        React.createElement(container_1.Container, { addClass: centered_page_module_scss_1.centeredPageContent }, p.children),
        React.createElement(unauthenticated_footer_1.UnauthenticatedFooter, null)));
}
exports.CenteredPage = CenteredPage;
