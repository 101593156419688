"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponsiveInfo = void 0;
const react_1 = require("react");
const use_window_size_1 = require("@freckle/educator-materials/ts/hooks/use-window-size");
const responsive_helper_1 = require("@freckle/educator-materials/ts/helpers/responsive-helper");
function useResponsiveInfo() {
    const { width } = (0, use_window_size_1.useWindowSize)();
    const responsiveBreakpoint = (0, responsive_helper_1.getResponsiveBreakpoint)(width);
    const [breakpoint, setBreakpoint] = (0, react_1.useState)(responsiveBreakpoint);
    (0, react_1.useEffect)(() => setBreakpoint(responsiveBreakpoint), [responsiveBreakpoint]);
    return breakpoint;
}
exports.useResponsiveInfo = useResponsiveInfo;
