"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdaptiveGrammarTitle = exports.getWordStudyTitle = exports.getWordStudyPracticeMode = exports.getDecodableTitle = exports.getDecodablePracticeMode = exports.getAssessmentTitle = exports.getAssessmentPracticeMode = exports.getArticleTitle = exports.getArticlePracticeMode = exports.getFocusSkillsTitle = exports.getTargetedSkillsTitle = exports.getFocusSkillsPracticeMode = exports.getTargetedSkillsPracticeMode = exports.getAdaptiveSkillsTitle = exports.getAdaptiveSkillsPracticeMode = void 0;
const html_text_1 = require("@freckle/educator-entities/ts/common/models/html-text");
function getSkillsPracticeMode(t) {
    return t('common|ELA_SKILLS_PRACTICE_MODE');
}
function getAdaptiveSkillsPracticeMode(t) {
    return getSkillsPracticeMode(t);
}
exports.getAdaptiveSkillsPracticeMode = getAdaptiveSkillsPracticeMode;
function getAdaptiveSkillsTitle(rlDomain, t, teacherDefinedTitle, rlStandardWithDomain) {
    if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
        return teacherDefinedTitle;
    }
    if (rlStandardWithDomain !== null &&
        rlStandardWithDomain !== undefined &&
        rlDomain !== null &&
        rlDomain !== undefined) {
        return (0, html_text_1.toHTMLTextDangerous)(t('common|ELA_ADAPTIVE_SKILLS_TITLE_WITH_STANDARD', {
            domainName: rlDomain.name,
            standardName: rlStandardWithDomain.shortName,
        }));
    }
    if (rlDomain !== null && rlDomain !== undefined) {
        return (0, html_text_1.toHTMLTextDangerous)(t('common|ELA_ADAPTIVE_SKILLS_TITLE_WITH_DOMAIN', {
            systemDomainDescription: rlDomain.name,
        }));
    }
    return (0, html_text_1.toHTMLTextDangerous)(t('At your level'));
}
exports.getAdaptiveSkillsTitle = getAdaptiveSkillsTitle;
function getTargetedSkillsPracticeMode(t) {
    return getSkillsPracticeMode(t);
}
exports.getTargetedSkillsPracticeMode = getTargetedSkillsPracticeMode;
function getFocusSkillsPracticeMode(t) {
    return getSkillsPracticeMode(t);
}
exports.getFocusSkillsPracticeMode = getFocusSkillsPracticeMode;
function getTargetedSkillsTitle(standard, t, teacherDefinedTitle) {
    if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
        return teacherDefinedTitle;
    }
    return (0, html_text_1.toHTMLTextDangerous)(t('common|TARGETED_SKILLS_PRACTICE_TITLE_WITH_STANDARD', {
        standardShortName: standard.shortName,
        standardName: standard.name,
    }));
}
exports.getTargetedSkillsTitle = getTargetedSkillsTitle;
function getFocusSkillsTitle(standard, t, teacherDefinedTitle) {
    if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
        return teacherDefinedTitle;
    }
    return (0, html_text_1.toHTMLTextDangerous)(t('common|TARGETED_SKILLS_PRACTICE_TITLE_WITH_STANDARD', {
        standardShortName: standard.shortName,
        standardName: standard.name,
    }));
}
exports.getFocusSkillsTitle = getFocusSkillsTitle;
function getArticlePracticeMode(assignmentType, t) {
    return t('common|ELA_ARTICLE_PRACTICE_MODE', { assignmentType });
}
exports.getArticlePracticeMode = getArticlePracticeMode;
function getArticleTitle(articleTitle, teacherDefinedTitle) {
    if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
        return teacherDefinedTitle;
    }
    return (0, html_text_1.toHTMLTextDangerous)(articleTitle);
}
exports.getArticleTitle = getArticleTitle;
function getAssessmentPracticeMode(t) {
    return t('common|ASSESSMENT_PRACTICE_MODE');
}
exports.getAssessmentPracticeMode = getAssessmentPracticeMode;
function getAssessmentTitle(letter, t, teacherDefinedTitle) {
    if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
        return teacherDefinedTitle;
    }
    return (0, html_text_1.toHTMLTextDangerous)(t('common|ELA_ASSESSMENT_TITLE', { letter }));
}
exports.getAssessmentTitle = getAssessmentTitle;
function getDecodablePracticeMode(t) {
    return t('common|DECODABLE_PRACTICE_MODE');
}
exports.getDecodablePracticeMode = getDecodablePracticeMode;
function getDecodableTitle(decodableTitle, teacherDefinedTitle) {
    if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
        return teacherDefinedTitle;
    }
    return (0, html_text_1.toHTMLTextDangerous)(decodableTitle);
}
exports.getDecodableTitle = getDecodableTitle;
function getWordStudyPracticeMode(t) {
    return t('common|WORD_STUDY_PRACTICE_MODE');
}
exports.getWordStudyPracticeMode = getWordStudyPracticeMode;
function getWordStudyTitle(standard, skill, t, teacherDefinedTitle) {
    if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
        return teacherDefinedTitle;
    }
    if (skill === null || skill === undefined || standard === null || standard === undefined) {
        return (0, html_text_1.toHTMLTextDangerous)(t('WORD_STUDY_TITLE'));
    }
    else {
        return (0, html_text_1.toHTMLTextDangerous)(`${skill.shortName} - ${standard.name}`);
    }
}
exports.getWordStudyTitle = getWordStudyTitle;
function getAdaptiveGrammarTitle(rlDomain, t, teacherDefinedTitle) {
    if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
        return teacherDefinedTitle;
    }
    if (rlDomain === null || rlDomain === undefined) {
        return (0, html_text_1.toHTMLTextDangerous)(t('ADAPTIVE_GRAMMAR_TITLE'));
    }
    return (0, html_text_1.toHTMLTextDangerous)(t('ADAPTIVE_GRAMMAR_TITLE_WITH_DOMAIN', {
        systemDomainDescription: rlDomain.name,
    }));
}
exports.getAdaptiveGrammarTitle = getAdaptiveGrammarTitle;
