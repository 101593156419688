"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnswerTypePlacement = exports.AnswerTypes = exports.parseAnswerType = exports.parseTaggedSavedAnswer = void 0;
const parser_1 = require("@freckle/parser");
const exhaustive_1 = require("@freckle/exhaustive");
const equation_1 = require("@freckle/educator-entities/ts/math/common/logic/equation");
exports.parseTaggedSavedAnswer = (0, parser_1.firstOf)((0, parser_1.record)({ tag: (0, parser_1.tag)('singular_or'), value: (0, parser_1.string)() }), (0, parser_1.record)({ tag: (0, parser_1.tag)('multiple_choice'), value: (0, parser_1.string)() }), (0, parser_1.record)({ tag: (0, parser_1.tag)('multiple_answers'), value: (0, parser_1.array)((0, parser_1.string)()) }), (0, parser_1.record)({ tag: (0, parser_1.tag)('bucket'), value: (0, parser_1.array)((0, parser_1.array)((0, parser_1.string)())) }), (0, parser_1.record)({
    tag: (0, parser_1.tag)('graph_linear'),
    value: (0, parser_1.record)({
        points: (0, parser_1.array)(equation_1.parsePoint),
    }),
}), (0, parser_1.record)({
    tag: (0, parser_1.tag)('graph_quadratic'),
    value: (0, parser_1.record)({
        vertex: equation_1.parsePoint,
        point: equation_1.parsePoint,
    }),
}), (0, parser_1.record)({
    tag: (0, parser_1.tag)('graph_exponential'),
    value: (0, parser_1.record)({
        points: (0, parser_1.array)(equation_1.parsePoint),
    }),
}), (0, parser_1.record)({
    tag: (0, parser_1.tag)('graph_linear_inequality'),
    value: (0, parser_1.record)({
        points: (0, parser_1.array)(equation_1.parsePoint),
        inequality: equation_1.parseInequality,
    }),
}), (0, parser_1.record)({
    tag: (0, parser_1.tag)('graph_scatter_points'),
    value: (0, parser_1.record)({
        points: (0, parser_1.array)(equation_1.parsePoint),
    }),
}), (0, parser_1.record)({
    tag: (0, parser_1.tag)('fill_in_the_blanks'),
    value: (0, parser_1.array)((0, parser_1.record)({
        row: (0, parser_1.number)(),
        column: (0, parser_1.number)(),
        answer: (0, parser_1.string)(),
    })),
}));
exports.parseAnswerType = (0, parser_1.firstOf)((0, parser_1.literal)('singular_or'), (0, parser_1.literal)('multiple_choice'), (0, parser_1.literal)('multiple_answers'), (0, parser_1.literal)('bucket'), (0, parser_1.literal)('graph_linear'), (0, parser_1.literal)('graph_quadratic'), (0, parser_1.literal)('graph_exponential'), (0, parser_1.literal)('graph_linear_inequality'), (0, parser_1.literal)('graph_scatter_points'), (0, parser_1.literal)('fill_in_the_blanks'));
exports.AnswerTypes = {
    SingularOr: 'singular_or',
    MultipleChoice: 'multiple_choice',
    MultipleAnswers: 'multiple_answers',
    Bucket: 'bucket',
    GraphLinear: 'graph_linear',
    GraphQuadratic: 'graph_quadratic',
    GraphExponential: 'graph_exponential',
    GraphLinearInequality: 'graph_linear_inequality',
    GraphScatterPoints: 'graph_scatter_points',
    FillInTheBlanks: 'fill_in_the_blanks',
    all: function () {
        return [
            'singular_or',
            'multiple_choice',
            'multiple_answers',
            'bucket',
            'graph_linear',
            'graph_quadratic',
            'graph_exponential',
            'graph_linear_inequality',
            'graph_scatter_points',
            'fill_in_the_blanks',
        ];
    },
};
function getAnswerTypePlacement(answerType) {
    switch (answerType) {
        case 'graph_linear':
        case 'graph_quadratic':
        case 'graph_exponential':
        case 'graph_linear_inequality':
        case 'graph_scatter_points':
        case 'fill_in_the_blanks':
            return 'inline-question';
        case 'bucket':
        case 'multiple_answers':
        case 'multiple_choice':
        case 'singular_or':
            return 'bottom-full-width';
        default:
            return (0, exhaustive_1.exhaustive)(answerType, `AnswerTypeT`);
    }
}
exports.getAnswerTypePlacement = getAnswerTypePlacement;
