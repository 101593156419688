"use strict";
/* @flow */
Object.defineProperty(exports, "__esModule", { value: true });
class PATHS_TYPE_paths {
    docs() { return this.root + '/docs'; }
    faviconsvg() { return this.root + '/favicon.svg'; }
    health_check() { return this.root + '/health-check'; }
    openapijson() { return this.root + '/openapi.json'; }
    robotstxt() { return this.root + '/robots.txt'; }
    routesjs() { return this.root + '/routes.js'; }
    version() { return this.root + '/version'; }
    constructor(root) {
        this.root = root;
        this.static_pages = new PATHS_TYPE_paths_static_pages(root);
        this.v2 = new PATHS_TYPE_paths_v2(root);
        this.v3 = new PATHS_TYPE_paths_v3(root);
    }
}
class PATHS_TYPE_paths_static_pages {
    docs() { return this.root + '/docs'; }
    faviconsvg() { return this.root + '/favicon.svg'; }
    health_check() { return this.root + '/health-check'; }
    openapijson() { return this.root + '/openapi.json'; }
    robotstxt() { return this.root + '/robots.txt'; }
    routesjs() { return this.root + '/routes.js'; }
    version() { return this.root + '/version'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2 {
    auth_check() { return this.root + '/2/auth-check'; }
    boom() { return this.root + '/2/boom'; }
    colleagues() { return this.root + '/2/colleagues'; }
    connection_check() { return this.root + '/2/connection-check'; }
    email_verifications() { return this.root + '/2/email-verifications'; }
    ibl_sessions() { return this.root + '/2/ibl-sessions'; }
    math_fact_practice_usage() { return this.root + '/2/math-fact-practice-usage'; }
    ping() { return this.root + '/2/ping'; }
    premium_licenses() { return this.root + '/2/premium-licenses'; }
    session_summaries() { return this.root + '/2/session-summaries'; }
    sight_words(a) { return this.root + '/2/sight-words/' + a + ''; }
    sight_words_level() { return this.root + '/2/sight-words-level'; }
    sight_words_sessions() { return this.root + '/2/sight-words-sessions'; }
    sloth() { return this.root + '/2/sloth'; }
    static_redirect() { return this.root + '/2/static-redirect'; }
    student_ping() { return this.root + '/2/student-ping'; }
    student_shares() { return this.root + '/2/student-shares'; }
    teacher_ping() { return this.root + '/2/teacher-ping'; }
    teacher_relogin_redirect() { return this.root + '/2/teacher-relogin-redirect'; }
    teacher_static_redirect() { return this.root + '/2/teacher-static-redirect'; }
    constructor(root) {
        this.root = root;
        this.admins = new PATHS_TYPE_paths_v2_admins(root);
        this.assignment_sessions = new PATHS_TYPE_paths_v2_assignment_sessions(root);
        this.console = new PATHS_TYPE_paths_v2_console(root);
        this.course_memberships = new PATHS_TYPE_paths_v2_course_memberships(root);
        this.courses = new PATHS_TYPE_paths_v2_courses(root);
        this.demo_districts = new PATHS_TYPE_paths_v2_demo_districts(root);
        this.districts = new PATHS_TYPE_paths_v2_districts(root);
        this.educator_academy = new PATHS_TYPE_paths_v2_educator_academy(root);
        this.ela = new PATHS_TYPE_paths_v2_ela(root);
        this.ela_assessment_session_report = new PATHS_TYPE_paths_v2_ela_assessment_session_report(root);
        this.ela_assignment_answers = new PATHS_TYPE_paths_v2_ela_assignment_answers(root);
        this.ela_assignments = new PATHS_TYPE_paths_v2_ela_assignments(root);
        this.ela_snapshots = new PATHS_TYPE_paths_v2_ela_snapshots(root);
        this.ela_vocab_words = new PATHS_TYPE_paths_v2_ela_vocab_words(root);
        this.ibl_assignments = new PATHS_TYPE_paths_v2_ibl_assignments(root);
        this.item_ownerships = new PATHS_TYPE_paths_v2_item_ownerships(root);
        this.items = new PATHS_TYPE_paths_v2_items(root);
        this.license_allocations = new PATHS_TYPE_paths_v2_license_allocations(root);
        this.licenses = new PATHS_TYPE_paths_v2_licenses(root);
        this.math = new PATHS_TYPE_paths_v2_math(root);
        this.math_adaptive_assignments = new PATHS_TYPE_paths_v2_math_adaptive_assignments(root);
        this.math_assessment_sessions = new PATHS_TYPE_paths_v2_math_assessment_sessions(root);
        this.math_assessments = new PATHS_TYPE_paths_v2_math_assessments(root);
        this.math_assignments = new PATHS_TYPE_paths_v2_math_assignments(root);
        this.math_fact_practice_snapshots = new PATHS_TYPE_paths_v2_math_fact_practice_snapshots(root);
        this.math_targeted_assignment_sessions = new PATHS_TYPE_paths_v2_math_targeted_assignment_sessions(root);
        this.math_targeted_assignments = new PATHS_TYPE_paths_v2_math_targeted_assignments(root);
        this.passwords = new PATHS_TYPE_paths_v2_passwords(root);
        this.periscope_embeds = new PATHS_TYPE_paths_v2_periscope_embeds(root);
        this.referrals = new PATHS_TYPE_paths_v2_referrals(root);
        this.school_admins = new PATHS_TYPE_paths_v2_school_admins(root);
        this.schools = new PATHS_TYPE_paths_v2_schools(root);
        this.science_assignments = new PATHS_TYPE_paths_v2_science_assignments(root);
        this.science_writing_assignment_answers = new PATHS_TYPE_paths_v2_science_writing_assignment_answers(root);
        this.social_studies_assignments = new PATHS_TYPE_paths_v2_social_studies_assignments(root);
        this.social_studies_writing_assignment_answers = new PATHS_TYPE_paths_v2_social_studies_writing_assignment_answers(root);
        this.students = new PATHS_TYPE_paths_v2_students(root);
        this.students_idp_overlays = new PATHS_TYPE_paths_v2_students_idp_overlays(root);
        this.teachers = new PATHS_TYPE_paths_v2_teachers(root);
    }
}
class PATHS_TYPE_paths_v2_educator_academy {
    user() { return this.root + '/2/educator-academy/user'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_console {
    agent() { return this.root + '/2/console/agent'; }
    backdoor() { return this.root + '/2/console/backdoor'; }
    impersonations() { return this.root + '/2/console/impersonations'; }
    jobs() { return this.root + '/2/console/jobs'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_admins {
    impersonations() { return this.root + '/2/admins/impersonations'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_assessments {
    _(a) { return this.root + '/2/math-assessments/' + a.toString() + ''; }
    assessment_answers1(a) { return this.root + '/2/math-assessments/' + a.toString() + '/assessment-answers'; }
    assessment_answers2(a, aa) { return this.root + '/2/math-assessments/' + a.toString() + '/assessment-answers/' + aa.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_assessment_sessions {
    assessment_answers(a) { return this.root + '/2/math-assessment-sessions/' + a.toString() + '/assessment-answers'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_fact_practice_snapshots {
    latest() { return this.root + '/2/math-fact-practice-snapshots/latest'; }
    latest3() { return this.root + '/2/math-fact-practice-snapshots/latest3'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_students {
    _0() { return this.root + '/2/students'; }
    _1(a) { return this.root + '/2/students/' + a.toString() + ''; }
    activity_feed(a) { return this.root + '/2/students/' + a.toString() + '/activity-feed'; }
    archive(a, aa) { return this.root + '/2/students/' + a.toString() + '/archive/' + aa.toString() + ''; }
    change_course(a) { return this.root + '/2/students/' + a.toString() + '/change-course'; }
    co_teachers(a) { return this.root + '/2/students/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/co-teachers'; }
    ela_report_card(a) { return this.root + '/2/students/' + a.toString() + '/ela-report-card'; }
    ela_snapshots(a) { return this.root + '/2/students/' + a.toString() + '/ela-snapshots'; }
    math_number_basics_snapshots_latest(a) { return this.root + '/2/students/' + a.toString() + '/math/number-basics/snapshots/latest'; }
    me() { return this.root + '/2/students/me'; }
    me_ela_progress() { return this.root + '/2/students/me/ela-progress'; }
    me_ela_snapshots_latest() { return this.root + '/2/students/me/ela-snapshots/latest'; }
    me_incomplete_assignment_count() { return this.root + '/2/students/me/incomplete-assignment-count'; }
    me_link_renaissance() { return this.root + '/2/students/me/link-renaissance'; }
    me_login_events() { return this.root + '/2/students/me/login-events'; }
    me_math_adaptive_pretest_status() { return this.root + '/2/students/me/math-adaptive-pretest-status'; }
    me_math_adaptive_stats_student_domain_month_latest() { return this.root + '/2/students/me/math-adaptive-stats-student-domain-month/latest'; }
    me_progress_and_growth() { return this.root + '/2/students/me/progress-and-growth'; }
    me_time_spent() { return this.root + '/2/students/me/time-spent'; }
    sessions() { return this.root + '/2/students/sessions'; }
    standard_stats(a) { return this.root + '/2/students/' + a.toString() + '/standard-stats'; }
    teachers(a) { return this.root + '/2/students/' + a.toString() + '/teachers'; }
    word_study_sessions(a) { return this.root + '/2/students/' + a.toString() + '/word-study-sessions'; }
    constructor(root) {
        this.root = root;
        this.goals = new PATHS_TYPE_paths_v2_students_goals(root);
        this.student_placements = new PATHS_TYPE_paths_v2_students_student_placements(root);
        this.student_reports = new PATHS_TYPE_paths_v2_students_student_reports(root);
        this.students_duplicates = new PATHS_TYPE_paths_v2_students_students_duplicates(root);
    }
}
class PATHS_TYPE_paths_v2_students_students_duplicates {
    _() { return this.root + '/2/students/duplicates'; }
    merge() { return this.root + '/2/students/duplicates/merge'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_students_goals {
    _(a) { return this.root + '/2/students/' + a.toString() + '/goals'; }
    constructor(root) {
        this.root = root;
        this.goal = new PATHS_TYPE_paths_v2_students_goals_goal(root);
    }
}
class PATHS_TYPE_paths_v2_students_goals_goal {
    _(a, aa) { return this.root + '/2/students/' + a.toString() + '/goals/' + aa.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_students_student_reports {
    constructor(root) {
        this.root = root;
        this.student_reports_ela = new PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela(root);
    }
}
class PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela {
    constructor(root) {
        this.root = root;
        this.student_reports_ela_standards = new PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards(root);
    }
}
class PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards {
    constructor(root) {
        this.root = root;
        this.student_reports_ela_standard = new PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards_student_reports_ela_standard(root);
    }
}
class PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards_student_reports_ela_standard {
    sessions(a, aa) { return this.root + '/2/students/' + a.toString() + '/reports/ela/standards/' + aa + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_students_student_placements {
    constructor(root) {
        this.root = root;
        this.student_placements_math = new PATHS_TYPE_paths_v2_students_student_placements_student_placements_math(root);
    }
}
class PATHS_TYPE_paths_v2_students_student_placements_student_placements_math {
    _(a) { return this.root + '/2/students/' + a.toString() + '/placements/math'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_students_idp_overlays {
    _0() { return this.root + '/2/student-idp-overlays'; }
    _1(a) { return this.root + '/2/student-idp-overlays/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_items {
    _() { return this.root + '/2/items'; }
    constructor(root) {
        this.root = root;
        this.item = new PATHS_TYPE_paths_v2_items_item(root);
    }
}
class PATHS_TYPE_paths_v2_items_item {
    _(a) { return this.root + '/2/items/' + a.toString() + ''; }
    item_ownership(a) { return this.root + '/2/items/' + a.toString() + '/item-ownership'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_item_ownerships {
    _() { return this.root + '/2/item-ownerships'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_courses {
    _0() { return this.root + '/2/courses'; }
    _1(a) { return this.root + '/2/courses/' + a.toString() + ''; }
    activity_feed(a) { return this.root + '/2/courses/' + a.toString() + '/activity-feed'; }
    class_codes(a) { return this.root + '/2/courses/class-codes/' + a + ''; }
    class_grouping_report_domain_growth(a) { return this.root + '/2/courses/' + a.toString() + '/class-grouping-report/domain-growth'; }
    current() { return this.root + '/2/courses/current'; }
    ela_class_activity1(a) { return this.root + '/2/courses/' + a.toString() + '/ela-class-activity'; }
    ela_class_activity2(a, aa) { return this.root + '/2/courses/' + a.toString() + '/ela-class-activity/' + aa.toString() + ''; }
    ela_reading_levels(a) { return this.root + '/2/courses/' + a.toString() + '/ela-reading-levels'; }
    enabled_practice_modes(a) { return this.root + '/2/courses/' + a.toString() + '/enabled-practice-modes'; }
    experts_ela_adaptive_skills(a) { return this.root + '/2/courses/' + a.toString() + '/experts/ela-adaptive-skills'; }
    experts_math_adaptive(a) { return this.root + '/2/courses/' + a.toString() + '/experts/math-adaptive'; }
    experts_word_study(a) { return this.root + '/2/courses/' + a.toString() + '/experts/word-study'; }
    idp_student_merge(a) { return this.root + '/2/courses/' + a.toString() + '/idp-student-merge'; }
    math_adaptive_stats_student_domain_month_latest(a) { return this.root + '/2/courses/' + a.toString() + '/math-adaptive-stats-student-domain-month/latest'; }
    math_diagnostics(a) { return this.root + '/2/courses/' + a.toString() + '/math-diagnostics'; }
    math_fact_practice_levels(a) { return this.root + '/2/courses/' + a.toString() + '/math-fact-practice-levels'; }
    math_report_card_summaries(a) { return this.root + '/2/courses/' + a.toString() + '/math-report-card-summaries'; }
    reports_ela_groups_by_level(a) { return this.root + '/2/courses/' + a.toString() + '/reports/ela/groups-by-level'; }
    reports_ela_performance_by_standard(a) { return this.root + '/2/courses/' + a.toString() + '/reports/ela/performance-by-standard'; }
    student_goals(a) { return this.root + '/2/courses/' + a.toString() + '/student-goals'; }
    student_highlights(a) { return this.root + '/2/courses/' + a.toString() + '/student-highlights'; }
    student_standard_stats(a) { return this.root + '/2/courses/' + a.toString() + '/student-standard-stats'; }
    students(a) { return this.root + '/2/courses/' + a.toString() + '/students'; }
    students_subjects_enabled_practice_modes(a, aa, aaa) { return this.root + '/2/courses/' + a.toString() + '/students/' + aa.toString() + '/subjects/' + aaa + '/enabled-practice-modes'; }
    subjects(a) { return this.root + '/2/courses/' + a.toString() + '/subjects'; }
    subjects_enabled_practice_modes(a, aa) { return this.root + '/2/courses/' + a.toString() + '/subjects/' + aa + '/enabled-practice-modes'; }
    teacher(a) { return this.root + '/2/courses/' + a.toString() + '/teacher'; }
    unmanaged() { return this.root + '/2/courses/unmanaged'; }
    word_study_levels_latest_per_student(a) { return this.root + '/2/courses/' + a.toString() + '/word-study-levels/latest-per-student'; }
    constructor(root) {
        this.root = root;
        this.courses_reports_math = new PATHS_TYPE_paths_v2_courses_courses_reports_math(root);
    }
}
class PATHS_TYPE_paths_v2_courses_courses_reports_math {
    constructor(root) {
        this.root = root;
        this.courses_reports_math_targeted = new PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted(root);
    }
}
class PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted {
    constructor(root) {
        this.root = root;
        this.courses_reports_math_targeted_assignments = new PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments(root);
    }
}
class PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments {
    constructor(root) {
        this.root = root;
        this.courses_reports_math_targeted_assignment = new PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments_courses_reports_math_targeted_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments_courses_reports_math_targeted_assignment {
    question_performance(a, aa) { return this.root + '/2/courses/' + a.toString() + '/reports/math/targeted/assignments/' + aa.toString() + '/question-performance'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_course_memberships {
    _0() { return this.root + '/2/course-memberships'; }
    _1(a) { return this.root + '/2/course-memberships/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_districts {
    _() { return this.root + '/2/districts'; }
    constructor(root) {
        this.root = root;
        this.district = new PATHS_TYPE_paths_v2_districts_district(root);
    }
}
class PATHS_TYPE_paths_v2_districts_district {
    _(a) { return this.root + '/2/districts/' + a.toString() + ''; }
    schools(a) { return this.root + '/2/districts/' + a.toString() + '/schools'; }
    constructor(root) {
        this.root = root;
        this.district_identity_providers = new PATHS_TYPE_paths_v2_districts_district_district_identity_providers(root);
        this.district_school_admins = new PATHS_TYPE_paths_v2_districts_district_district_school_admins(root);
        this.district_students = new PATHS_TYPE_paths_v2_districts_district_district_students(root);
        this.rosters = new PATHS_TYPE_paths_v2_districts_district_rosters(root);
    }
}
class PATHS_TYPE_paths_v2_districts_district_rosters {
    _(a) { return this.root + '/2/districts/' + a.toString() + '/rosters'; }
    sis_id_sync(a) { return this.root + '/2/districts/' + a.toString() + '/rosters/sis-id-sync'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_districts_district_district_school_admins {
    constructor(root) {
        this.root = root;
        this.district_school_admin = new PATHS_TYPE_paths_v2_districts_district_district_school_admins_district_school_admin(root);
    }
}
class PATHS_TYPE_paths_v2_districts_district_district_school_admins_district_school_admin {
    school_memberships(a, aa) { return this.root + '/2/districts/' + a.toString() + '/school-admins/' + aa.toString() + '/school-memberships'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_districts_district_district_students {
    constructor(root) {
        this.root = root;
        this.district_students_placements = new PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements(root);
    }
}
class PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements {
    constructor(root) {
        this.root = root;
        this.district_students_placements_content_area = new PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements_district_students_placements_content_area(root);
    }
}
class PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements_district_students_placements_content_area {
    _(a, aa) { return this.root + '/2/districts/' + a.toString() + '/students/placements/' + aa + ''; }
    fast_bridge(a, aa) { return this.root + '/2/districts/' + a.toString() + '/students/placements/' + aa + '/fast-bridge'; }
    reading(a, aa) { return this.root + '/2/districts/' + a.toString() + '/students/placements/' + aa + '/reading'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_districts_district_district_identity_providers {
    associations(a) { return this.root + '/2/districts/' + a.toString() + '/identity-providers/associations'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_demo_districts {
    _() { return this.root + '/2/demo-districts'; }
    external() { return this.root + '/2/demo-districts/external'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_schools {
    _() { return this.root + '/2/schools'; }
    provision() { return this.root + '/2/schools/provision'; }
    reports_math_adaptive_grade_level(a) { return this.root + '/2/schools/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/reports/math-adaptive-grade-level'; }
    school_admins_school_memberships(a, aa) { return this.root + '/2/schools/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/school-admins/' + aa.toString() + '/school-memberships'; }
    search() { return this.root + '/2/schools/search'; }
    constructor(root) {
        this.root = root;
        this.school = new PATHS_TYPE_paths_v2_schools_school(root);
    }
}
class PATHS_TYPE_paths_v2_schools_school {
    _(a) { return this.root + '/2/schools/' + a.toString() + ''; }
    assessments(a) { return this.root + '/2/schools/' + a.toString() + '/assessments'; }
    assessments2(a) { return this.root + '/2/schools/' + a.toString() + '/assessments2'; }
    colleagues(a) { return this.root + '/2/schools/' + a.toString() + '/colleagues'; }
    courses(a) { return this.root + '/2/schools/' + a.toString() + '/courses'; }
    deletion_info(a) { return this.root + '/2/schools/' + a.toString() + '/deletion-info'; }
    licenses(a) { return this.root + '/2/schools/' + a.toString() + '/licenses'; }
    merge(a) { return this.root + '/2/schools/' + a.toString() + '/merge'; }
    constructor(root) {
        this.root = root;
        this.school_identity_providers = new PATHS_TYPE_paths_v2_schools_school_school_identity_providers(root);
        this.school_math_assessments_assignment = new PATHS_TYPE_paths_v2_schools_school_school_math_assessments_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_schools_school_school_math_assessments_assignment {
    answers(a, aa) { return this.root + '/2/schools/' + a.toString() + '/math/assessments/assignments/' + aa.toString() + '/answers'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_schools_school_school_identity_providers {
    associations(a) { return this.root + '/2/schools/' + a.toString() + '/identity-providers/associations'; }
    sync(a) { return this.root + '/2/schools/' + a.toString() + '/identity-providers/sync'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_licenses {
    _() { return this.root + '/2/licenses'; }
    constructor(root) {
        this.root = root;
        this.license = new PATHS_TYPE_paths_v2_licenses_license(root);
    }
}
class PATHS_TYPE_paths_v2_licenses_license {
    _(a) { return this.root + '/2/licenses/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_license_allocations {
    constructor(root) {
        this.root = root;
        this.license_allocation = new PATHS_TYPE_paths_v2_license_allocations_license_allocation(root);
    }
}
class PATHS_TYPE_paths_v2_license_allocations_license_allocation {
    _(a) { return this.root + '/2/license-allocations/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_teachers {
    _0() { return this.root + '/2/teachers'; }
    _1(a) { return this.root + '/2/teachers/' + a.toString() + ''; }
    accounts() { return this.root + '/2/teachers/accounts'; }
    affiliation(a) { return this.root + '/2/teachers/' + a.toString() + '/affiliation'; }
    affiliations() { return this.root + '/2/teachers/affiliations'; }
    courses_default(a) { return this.root + '/2/teachers/' + a.toString() + '/courses/default'; }
    idp_clever(a) { return this.root + '/2/teachers/' + a.toString() + '/idp/clever'; }
    impressions(a) { return this.root + '/2/teachers/' + a.toString() + '/impressions'; }
    invite() { return this.root + '/2/teachers/invite'; }
    license_allocations(a) { return this.root + '/2/teachers/' + a.toString() + '/license-allocations'; }
    reminders_finish_onboarding(a) { return this.root + '/2/teachers/' + a.toString() + '/reminders/finish-onboarding'; }
    sessions() { return this.root + '/2/teachers/sessions'; }
    sessions_school_admin_impersonations() { return this.root + '/2/teachers/sessions/school-admin-impersonations'; }
    constructor(root) {
        this.root = root;
        this.me = new PATHS_TYPE_paths_v2_teachers_me(root);
        this.teacher_claimed_free_resources = new PATHS_TYPE_paths_v2_teachers_teacher_claimed_free_resources(root);
    }
}
class PATHS_TYPE_paths_v2_teachers_me {
    _() { return this.root + '/2/teachers/me'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_teachers_teacher_claimed_free_resources {
    _(a) { return this.root + '/2/teachers/' + a.toString() + '/claimed-free-resources'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_school_admins {
    _() { return this.root + '/2/school-admins'; }
    bulk_upload() { return this.root + '/2/school-admins/bulk-upload'; }
    forgot_password() { return this.root + '/2/school-admins/forgot-password'; }
    impression(a) { return this.root + '/2/school-admins/' + a.toString() + '/impression'; }
    sessions() { return this.root + '/2/school-admins/sessions'; }
    teacher_sessions() { return this.root + '/2/school-admins/teacher-sessions'; }
    constructor(root) {
        this.root = root;
        this.school_admin = new PATHS_TYPE_paths_v2_school_admins_school_admin(root);
        this.school_admins_me = new PATHS_TYPE_paths_v2_school_admins_school_admins_me(root);
    }
}
class PATHS_TYPE_paths_v2_school_admins_school_admins_me {
    _() { return this.root + '/2/school-admins/me'; }
    dashboard() { return this.root + '/2/school-admins/me/dashboard'; }
    teachers() { return this.root + '/2/school-admins/me/teachers'; }
    teachers_missing_emails() { return this.root + '/2/school-admins/me/teachers-missing-emails'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_school_admins_school_admin {
    _(a) { return this.root + '/2/school-admins/' + a.toString() + ''; }
    credentials(a) { return this.root + '/2/school-admins/' + a.toString() + '/credentials'; }
    constructor(root) {
        this.root = root;
        this.school_admin_rosters = new PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_rosters(root);
        this.school_admin_verifications = new PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_verifications(root);
    }
}
class PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_verifications {
    _(a, aa) { return this.root + '/2/school-admins/' + a.toString() + '/verifications/' + aa + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_rosters {
    _(a) { return this.root + '/2/school-admins/' + a.toString() + '/rosters'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_passwords {
    _() { return this.root + '/2/passwords'; }
    resets() { return this.root + '/2/passwords/resets'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_vocab_words {
    _(a) { return this.root + '/2/vocab-words/' + a.map(function (a1) { return a1; }).join(',') + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignments {
    _() { return this.root + '/2/ela-assignments'; }
    constructor(root) {
        this.root = root;
        this.ela_adaptive_skills_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_adaptive_skills_assignments(root);
        this.ela_article_reading_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments(root);
        this.ela_article_writing_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments(root);
        this.ela_assessment_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments(root);
        this.ela_decodables_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments(root);
        this.ela_targeted_skills_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments(root);
        this.ela_word_study_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments(root);
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_adaptive_skills_assignments {
    answers(a) { return this.root + '/2/ela-assignments/adaptive-skills/' + a.toString() + '/answers'; }
    exit(a) { return this.root + '/2/ela-assignments/adaptive-skills/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/exit'; }
    passages(a) { return this.root + '/2/ela-assignments/adaptive-skills/' + a.toString() + '/passages'; }
    session(a) { return this.root + '/2/ela-assignments/adaptive-skills/' + a.toString() + '/session'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments {
    constructor(root) {
        this.root = root;
        this.ela_targeted_skills_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments_ela_targeted_skills_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments_ela_targeted_skills_assignment {
    answers(a) { return this.root + '/2/ela-assignments/targeted-skills/' + a.toString() + '/answers'; }
    exit(a) { return this.root + '/2/ela-assignments/targeted-skills/' + a.toString() + '/exit'; }
    passages(a) { return this.root + '/2/ela-assignments/targeted-skills/' + a.toString() + '/passages'; }
    sessions(a) { return this.root + '/2/ela-assignments/targeted-skills/' + a.toString() + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments {
    constructor(root) {
        this.root = root;
        this.ela_article_reading_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments_ela_article_reading_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments_ela_article_reading_assignment {
    answers(a) { return this.root + '/2/ela-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers'; }
    answers_retries(a) { return this.root + '/2/ela-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers/retries'; }
    exit(a) { return this.root + '/2/ela-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/exit'; }
    sessions(a) { return this.root + '/2/ela-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments {
    constructor(root) {
        this.root = root;
        this.ela_article_writing_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments_ela_article_writing_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments_ela_article_writing_assignment {
    answers(a) { return this.root + '/2/ela-assignments/writing/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers'; }
    sessions(a) { return this.root + '/2/ela-assignments/writing/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments {
    constructor(root) {
        this.root = root;
        this.ela_assessment_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments_ela_assessment_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments_ela_assessment_assignment {
    answers(a) { return this.root + '/2/ela-assignments/assessments/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers'; }
    ela_assessment_report(a) { return this.root + '/2/ela-assignments/assessments/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/ela-assessment-report'; }
    sessions(a) { return this.root + '/2/ela-assignments/assessments/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments {
    _() { return this.root + '/2/ela-assignments/decodables'; }
    constructor(root) {
        this.root = root;
        this.ela_decodables_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments_ela_decodables_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments_ela_decodables_assignment {
    answers(a) { return this.root + '/2/ela-assignments/decodables/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers'; }
    sessions(a) { return this.root + '/2/ela-assignments/decodables/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments {
    _(a) { return this.root + '/2/ela-assignments/word-study/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
        this.ela_word_study_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_assignment(root);
        this.ela_word_study_sessions = new PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_sessions(root);
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_sessions {
    progress() { return this.root + '/2/ela-assignments/word-study/sessions/progress'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_assignment {
    sessions(a) { return this.root + '/2/ela-assignments/word-study/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assessment_session_report {
    _(a) { return this.root + '/2/ela-assessment-session-report/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_assignment_answers {
    constructor(root) {
        this.root = root;
        this.ela_writing_assignment_answer = new PATHS_TYPE_paths_v2_ela_assignment_answers_ela_writing_assignment_answer(root);
    }
}
class PATHS_TYPE_paths_v2_ela_assignment_answers_ela_writing_assignment_answer {
    _(a) { return this.root + '/2/ela-assignment-answers/writing/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_snapshots {
    _() { return this.root + '/2/ela-snapshots'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ibl_assignments {
    completion(a) { return this.root + '/2/ibl-assignments/' + a.toString() + '/completion'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_assignment_sessions {
    adaptive_answers(a) { return this.root + '/2/assignment-sessions/adaptive/' + a.toString() + '/answers'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_assignments {
    _() { return this.root + '/2/math-assignments'; }
    assignment_sessions_is_any_session_complete() { return this.root + '/2/math-assignments/assignment-sessions/is-any-session-complete'; }
    constructor(root) {
        this.root = root;
        this.math_adaptive_assignment = new PATHS_TYPE_paths_v2_math_assignments_math_adaptive_assignment(root);
        this.math_assessment_assignments = new PATHS_TYPE_paths_v2_math_assignments_math_assessment_assignments(root);
        this.math_fact_practice_assignments = new PATHS_TYPE_paths_v2_math_assignments_math_fact_practice_assignments(root);
        this.math_ibl_assignments = new PATHS_TYPE_paths_v2_math_assignments_math_ibl_assignments(root);
        this.math_standard_assignments = new PATHS_TYPE_paths_v2_math_assignments_math_standard_assignments(root);
    }
}
class PATHS_TYPE_paths_v2_math_assignments_math_adaptive_assignment {
    assignment_sessions(a) { return this.root + '/2/math-assignments/adaptive/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/assignment-sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_assignments_math_assessment_assignments {
    assignment_sessions(a) { return this.root + '/2/math-assignments/assessment/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/assignment-sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_assignments_math_fact_practice_assignments {
    assignment_sessions(a) { return this.root + '/2/math-assignments/fact-practice/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/assignment-sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_assignments_math_ibl_assignments {
    assignment_sessions(a) { return this.root + '/2/math-assignments/ibl/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/assignment-sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_assignments_math_standard_assignments {
    assignment_sessions(a) { return this.root + '/2/math-assignments/standard/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/assignment-sessions'; }
    questions(a) { return this.root + '/2/math-assignments/standard/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/questions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_targeted_assignments {
    _(a) { return this.root + '/2/math-targeted-assignments/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_adaptive_assignments {
    _(a) { return this.root + '/2/math-adaptive-assignments/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math_targeted_assignment_sessions {
    answers(a) { return this.root + '/2/math-targeted-assignment-sessions/' + a.toString() + '/answers'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_referrals {
    _() { return this.root + '/2/referrals'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_math {
    constructor(root) {
        this.root = root;
        this.number_facts = new PATHS_TYPE_paths_v2_math_number_facts(root);
    }
}
class PATHS_TYPE_paths_v2_math_number_facts {
    levels() { return this.root + '/2/math/number-facts/levels'; }
    questions() { return this.root + '/2/math/number-facts/questions'; }
    sessions() { return this.root + '/2/math/number-facts/sessions'; }
    constructor(root) {
        this.root = root;
        this.number_facts_assignments = new PATHS_TYPE_paths_v2_math_number_facts_number_facts_assignments(root);
    }
}
class PATHS_TYPE_paths_v2_math_number_facts_number_facts_assignments {
    sessions(a) { return this.root + '/2/math/number-facts/assignments/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_social_studies_assignments {
    _() { return this.root + '/2/social-studies-assignments'; }
    constructor(root) {
        this.root = root;
        this.social_studies_reading_assignments = new PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments(root);
        this.social_studies_writing_assignments = new PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments(root);
    }
}
class PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments {
    _(a) { return this.root + '/2/social-studies-assignments/reading/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
        this.social_studies_reading_assignment = new PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments_social_studies_reading_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments_social_studies_reading_assignment {
    answers(a) { return this.root + '/2/social-studies-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers'; }
    answers_retries(a) { return this.root + '/2/social-studies-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers/retries'; }
    exit(a) { return this.root + '/2/social-studies-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/exit'; }
    sessions(a) { return this.root + '/2/social-studies-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments {
    _(a) { return this.root + '/2/social-studies-assignments/writing/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
        this.social_studies_writing_assignment = new PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments_social_studies_writing_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments_social_studies_writing_assignment {
    answers(a) { return this.root + '/2/social-studies-assignments/writing/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers'; }
    sessions(a) { return this.root + '/2/social-studies-assignments/writing/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_social_studies_writing_assignment_answers {
    _(a) { return this.root + '/2/social-studies-writing-assignments-answers/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_science_assignments {
    _() { return this.root + '/2/science-assignments'; }
    constructor(root) {
        this.root = root;
        this.science_reading_assignments = new PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments(root);
        this.science_writing_assignments = new PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments(root);
    }
}
class PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments {
    _(a) { return this.root + '/2/science-assignments/reading/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
        this.science_reading_assignment = new PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments_science_reading_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments_science_reading_assignment {
    answers(a) { return this.root + '/2/science-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers'; }
    answers_retries(a) { return this.root + '/2/science-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers/retries'; }
    exit(a) { return this.root + '/2/science-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/exit'; }
    sessions(a) { return this.root + '/2/science-assignments/reading/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments {
    _(a) { return this.root + '/2/science-assignments/writing/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
        this.science_writing_assignment = new PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments_science_writing_assignment(root);
    }
}
class PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments_science_writing_assignment {
    answers(a) { return this.root + '/2/science-assignments/writing/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/answers'; }
    sessions(a) { return this.root + '/2/science-assignments/writing/' + a.map(function (a1) { return a1.toString(); }).join(',') + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_science_writing_assignment_answers {
    _(a) { return this.root + '/2/science-writing-assignments-answers/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela {
    article_search() { return this.root + '/2/ela/article-search'; }
    constructor(root) {
        this.root = root;
        this.ela_adaptive_skills = new PATHS_TYPE_paths_v2_ela_ela_adaptive_skills(root);
        this.ela_decodables = new PATHS_TYPE_paths_v2_ela_ela_decodables(root);
    }
}
class PATHS_TYPE_paths_v2_ela_ela_adaptive_skills {
    contents() { return this.root + '/2/ela/adaptive-skills/contents'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_ela_ela_decodables {
    highest() { return this.root + '/2/ela/decodables/highest'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v2_periscope_embeds {
    districts_growth() { return this.root + '/2/periscope-embeds/districts/growth'; }
    school_usage() { return this.root + '/2/periscope-embeds/school-usage'; }
    schools_growth(a) { return this.root + '/2/periscope-embeds/schools/' + a.toString() + '/growth'; }
    skill_details(a) { return this.root + '/2/periscope-embeds/skill-details/' + a + ''; }
    students_growth(a) { return this.root + '/2/periscope-embeds/students/' + a.toString() + '/growth'; }
    teachers_growth(a) { return this.root + '/2/periscope-embeds/teachers/' + a.toString() + '/growth'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3 {
    content_feedback() { return this.root + '/3/content-feedback'; }
    login_cards() { return this.root + '/3/login-cards'; }
    constructor(root) {
        this.root = root;
        this.assignment_count = new PATHS_TYPE_paths_v3_assignment_count(root);
        this.content_area = new PATHS_TYPE_paths_v3_content_area(root);
        this.languages = new PATHS_TYPE_paths_v3_languages(root);
        this.operations = new PATHS_TYPE_paths_v3_operations(root);
        this.renaissance = new PATHS_TYPE_paths_v3_renaissance(root);
        this.subjects = new PATHS_TYPE_paths_v3_subjects(root);
        this.v3_courses = new PATHS_TYPE_paths_v3_v3_courses(root);
        this.v3_schools = new PATHS_TYPE_paths_v3_v3_schools(root);
        this.v3_students = new PATHS_TYPE_paths_v3_v3_students(root);
        this.v3_teachers = new PATHS_TYPE_paths_v3_v3_teachers(root);
        this.v3_webhooks = new PATHS_TYPE_paths_v3_v3_webhooks(root);
    }
}
class PATHS_TYPE_paths_v3_assignment_count {
    _() { return this.root + '/3/assignment-count'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_languages {
    _() { return this.root + '/3/languages'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_subjects {
    constructor(root) {
        this.root = root;
        this.subject = new PATHS_TYPE_paths_v3_subjects_subject(root);
    }
}
class PATHS_TYPE_paths_v3_subjects_subject {
    academic_vocabulary(a) { return this.root + '/3/subjects/' + a + '/academic-vocabulary'; }
    constructor(root) {
        this.root = root;
        this.products = new PATHS_TYPE_paths_v3_subjects_subject_products(root);
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products {
    constructor(root) {
        this.root = root;
        this.product = new PATHS_TYPE_paths_v3_subjects_subject_products_product(root);
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product {
    constructor(root) {
        this.root = root;
        this.subject_product_assignments = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments(root);
        this.subject_product_sessions = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions(root);
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments {
    constructor(root) {
        this.root = root;
        this.subject_product_assignment = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments_subject_product_assignment(root);
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments_subject_product_assignment {
    _(a, aa, aaa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/assignments/' + aaa.toString() + ''; }
    passages(a, aa, aaa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/assignments/' + aaa.toString() + '/passages'; }
    sessions(a, aa, aaa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/assignments/' + aaa.toString() + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions {
    _(a, aa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions'; }
    constructor(root) {
        this.root = root;
        this.subject_product_session = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session(root);
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session {
    _(a, aa, aaa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + ''; }
    constructor(root) {
        this.root = root;
        this.subject_product_session_answers = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_answers(root);
        this.subject_product_session_attempts = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_attempts(root);
        this.subject_product_session_sentiments = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments(root);
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_attempts {
    _(a, aa, aaa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + '/attempts'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_answers {
    _(a, aa, aaa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + '/answers'; }
    retries(a, aa, aaa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + '/answers/retries'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments {
    constructor(root) {
        this.root = root;
        this.subject_product_session_sentiment = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments_subject_product_session_sentiment(root);
    }
}
class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments_subject_product_session_sentiment {
    _(a, aa, aaa, aaaa) { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + '/sentiments/' + aaaa + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_courses {
    _() { return this.root + '/3/courses'; }
    constructor(root) {
        this.root = root;
        this.v3_course = new PATHS_TYPE_paths_v3_v3_courses_v3_course(root);
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course {
    math_adaptive_domains_practiced(a) { return this.root + '/3/courses/' + a.toString() + '/math-adaptive-domains-practiced'; }
    constructor(root) {
        this.root = root;
        this.course_ela_adaptive_skills = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_ela_adaptive_skills(root);
        this.course_math_assessments = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_math_assessments(root);
        this.course_subjects = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects(root);
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects {
    constructor(root) {
        this.root = root;
        this.course_subject = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject(root);
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject {
    constructor(root) {
        this.root = root;
        this.course_products = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products(root);
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products {
    constructor(root) {
        this.root = root;
        this.course_product = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product(root);
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product {
    constructor(root) {
        this.root = root;
        this.course_subject_product_printable_assignments = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_printable_assignments(root);
        this.course_subject_product_sessions = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_sessions(root);
        this.course_subject_product_settings = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_settings(root);
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_sessions {
    _(a, aa, aaa) { return this.root + '/3/courses/' + a.toString() + '/subjects/' + aa + '/products/' + aaa + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_settings {
    _(a, aa, aaa, aaaa) { return this.root + '/3/courses/' + a.toString() + '/subjects/' + aa + '/products/' + aaa + '/settings/' + aaaa.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_printable_assignments {
    _(a, aa, aaa) { return this.root + '/3/courses/' + a.toString() + '/subjects/' + aa + '/products/' + aaa + '/printable-assignments'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_math_assessments {
    domain_growth_report(a) { return this.root + '/3/courses/' + a.toString() + '/math-assessments/domain-growth-report'; }
    growth_report(a) { return this.root + '/3/courses/' + a.toString() + '/math-assessments/growth-report'; }
    standard_growth_report(a) { return this.root + '/3/courses/' + a.toString() + '/math-assessments/standard-growth-report'; }
    student_growth_report(a) { return this.root + '/3/courses/' + a.toString() + '/math-assessments/student-growth-report'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_ela_adaptive_skills {
    adaptive_progresses(a) { return this.root + '/3/courses/' + a.toString() + '/ela-adaptive-skills/adaptive-progresses'; }
    skill_groups_report(a) { return this.root + '/3/courses/' + a.toString() + '/ela-adaptive-skills/skill-groups-report'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_schools {
    constructor(root) {
        this.root = root;
        this.v3_school = new PATHS_TYPE_paths_v3_v3_schools_v3_school(root);
    }
}
class PATHS_TYPE_paths_v3_v3_schools_v3_school {
    constructor(root) {
        this.root = root;
        this.school_math = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_math(root);
        this.school_subjects = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects(root);
    }
}
class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_math {
    standard_set(a) { return this.root + '/3/schools/' + a.toString() + '/math/standard-set'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects {
    constructor(root) {
        this.root = root;
        this.school_subject = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject(root);
    }
}
class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject {
    constructor(root) {
        this.root = root;
        this.school_products = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products(root);
    }
}
class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products {
    constructor(root) {
        this.root = root;
        this.school_product = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products_school_product(root);
    }
}
class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products_school_product {
    sessions(a, aa, aaa) { return this.root + '/3/schools/' + a.toString() + '/subjects/' + aa + '/products/' + aaa + '/sessions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_students {
    _() { return this.root + '/3/students'; }
    impressions() { return this.root + '/3/students/impressions'; }
    presence_events() { return this.root + '/3/students/presence-events'; }
    constructor(root) {
        this.root = root;
        this.v3_student = new PATHS_TYPE_paths_v3_v3_students_v3_student(root);
        this.v3_students_duplicates = new PATHS_TYPE_paths_v3_v3_students_v3_students_duplicates(root);
    }
}
class PATHS_TYPE_paths_v3_v3_students_v3_students_duplicates {
    merge() { return this.root + '/3/students/duplicates/merge'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_students_v3_student {
    practice_summary(a) { return this.root + '/3/students/' + a.toString() + '/practice-summary'; }
    constructor(root) {
        this.root = root;
        this.student_math = new PATHS_TYPE_paths_v3_v3_students_v3_student_student_math(root);
    }
}
class PATHS_TYPE_paths_v3_v3_students_v3_student_student_math {
    domain_statuses(a) { return this.root + '/3/students/' + a.toString() + '/math/domain-statuses'; }
    progress(a) { return this.root + '/3/students/' + a.toString() + '/math/progress'; }
    report_card(a) { return this.root + '/3/students/' + a.toString() + '/math/report-card'; }
    suggested_domain(a) { return this.root + '/3/students/' + a.toString() + '/math/suggested-domain'; }
    constructor(root) {
        this.root = root;
        this.student_math_adaptive = new PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive(root);
    }
}
class PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive {
    constructor(root) {
        this.root = root;
        this.student_math_adaptive_rewards = new PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive_student_math_adaptive_rewards(root);
    }
}
class PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive_student_math_adaptive_rewards {
    _1(a) { return this.root + '/3/students/' + a.toString() + '/math/adaptive/rewards'; }
    _2(a, aa) { return this.root + '/3/students/' + a.toString() + '/math/adaptive/rewards/' + aa.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_teachers {
    _() { return this.root + '/3/teachers'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area {
    standard_sets(a) { return this.root + '/3/' + a + '/standard-sets'; }
    constructor(root) {
        this.root = root;
        this.content_area_adaptive = new PATHS_TYPE_paths_v3_content_area_content_area_adaptive(root);
        this.content_area_adaptive_progresses = new PATHS_TYPE_paths_v3_content_area_content_area_adaptive_progresses(root);
        this.content_area_assessment = new PATHS_TYPE_paths_v3_content_area_content_area_assessment(root);
        this.content_area_constructed_responses = new PATHS_TYPE_paths_v3_content_area_content_area_constructed_responses(root);
        this.content_area_depth_of_knowledge = new PATHS_TYPE_paths_v3_content_area_content_area_depth_of_knowledge(root);
        this.content_area_diagnostic = new PATHS_TYPE_paths_v3_content_area_content_area_diagnostic(root);
        this.content_area_domains = new PATHS_TYPE_paths_v3_content_area_content_area_domains(root);
        this.content_area_ibls = new PATHS_TYPE_paths_v3_content_area_content_area_ibls(root);
        this.content_area_questions = new PATHS_TYPE_paths_v3_content_area_content_area_questions(root);
        this.content_area_skill_proficiencies = new PATHS_TYPE_paths_v3_content_area_content_area_skill_proficiencies(root);
        this.content_area_skills = new PATHS_TYPE_paths_v3_content_area_content_area_skills(root);
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_adaptive {
    answers(a) { return this.root + '/3/' + a + '/adaptive/answers'; }
    constructor(root) {
        this.root = root;
        this.content_area_adaptive_questions = new PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_questions(root);
        this.content_area_adaptive_rewards = new PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_rewards(root);
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_questions {
    arbitrary(a) { return this.root + '/3/' + a + '/adaptive/questions/arbitrary'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_rewards {
    _(a) { return this.root + '/3/' + a + '/adaptive/rewards'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_adaptive_progresses {
    _(a) { return this.root + '/3/' + a + '/adaptive-progresses'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_assessment {
    constructor(root) {
        this.root = root;
        this.content_area_assessment_questions = new PATHS_TYPE_paths_v3_content_area_content_area_assessment_content_area_assessment_questions(root);
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_assessment_content_area_assessment_questions {
    arbitrary(a) { return this.root + '/3/' + a + '/assessments/questions/arbitrary'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_constructed_responses {
    _(a) { return this.root + '/3/' + a + '/constructed-responses'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_depth_of_knowledge {
    question_sets(a) { return this.root + '/3/' + a + '/depth-of-knowledge/question-sets'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_diagnostic {
    constructor(root) {
        this.root = root;
        this.content_area_diagnostic_questions = new PATHS_TYPE_paths_v3_content_area_content_area_diagnostic_content_area_diagnostic_questions(root);
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_diagnostic_content_area_diagnostic_questions {
    arbitrary(a) { return this.root + '/3/' + a + '/diagnostic/questions/arbitrary'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_domains {
    constructor(root) {
        this.root = root;
        this.content_area_domains_exclusions = new PATHS_TYPE_paths_v3_content_area_content_area_domains_content_area_domains_exclusions(root);
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_domains_content_area_domains_exclusions {
    _(a) { return this.root + '/3/' + a + '/domains/exclusions'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_ibls {
    _(a) { return this.root + '/3/' + a + '/ibls'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_questions {
    arbitrary(a) { return this.root + '/3/' + a + '/questions/arbitrary'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_skills {
    _(a) { return this.root + '/3/' + a + '/skills'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_content_area_content_area_skill_proficiencies {
    _(a) { return this.root + '/3/' + a + '/skill-proficiencies'; }
    progress(a) { return this.root + '/3/' + a + '/skill-proficiencies/progress'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_operations {
    constructor(root) {
        this.root = root;
        this.operation = new PATHS_TYPE_paths_v3_operations_operation(root);
    }
}
class PATHS_TYPE_paths_v3_operations_operation {
    _(a) { return this.root + '/3/operations/' + a.toString() + ''; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_renaissance {
    home() { return this.root + '/3/renaissance/home'; }
    constructor(root) {
        this.root = root;
    }
}
class PATHS_TYPE_paths_v3_v3_webhooks {
    _() { return this.root + '/3/webhooks'; }
    recurly() { return this.root + '/3/webhooks/recurly'; }
    constructor(root) {
        this.root = root;
    }
}
var PATHS = new PATHS_TYPE_paths('');
exports.default = { createPaths: function (root) { return new PATHS_TYPE_paths(root); } };
