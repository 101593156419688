"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const string_helper_1 = require("@freckle/educator-entities/ts/common/helpers/string-helper");
function getStorageImplementation(storage) {
    const testKey = '__frontrowStorageTestKey__';
    try {
        const store = window[storage];
        store.setItem(testKey, 'true');
        store.removeItem(testKey);
        return store;
    }
    catch (err) {
        return {
            getItem: () => null,
            setItem: () => { },
            removeItem: () => { },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        };
    }
}
const StorageHelper = {
    localStorage: getStorageImplementation('localStorage'),
    sessionStorage: getStorageImplementation('sessionStorage'),
    // Generic storage helpers
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    requireStorage: function (storage) {
        return StorageHelper[storage];
    },
    setRawStorageProperty: function (storage, property, text) {
        StorageHelper.requireStorage(storage).setItem(property, text);
    },
    setStorageProperty: function (storage, property, value) {
        StorageHelper.setRawStorageProperty(storage, property, JSON.stringify(value));
    },
    clearStorageProperty: function (storage, property) {
        StorageHelper.requireStorage(storage).removeItem(property);
    },
    getRawStorageProperty: function (storage, property) {
        var _a;
        return (_a = StorageHelper.requireStorage(storage).getItem(property)) !== null && _a !== void 0 ? _a : null;
    },
    getStorageProperty: (storage, property) => {
        const text = StorageHelper.getRawStorageProperty(storage, property);
        return text === null ? null : JSON.parse(text);
    },
    getStoragePropertyDefault: (storage, property, defaultValue) => {
        const text = StorageHelper.getRawStorageProperty(storage, property);
        return text === null ? defaultValue : JSON.parse(text);
    },
    replaceMalformedStorageProperty: function (storage, property, value) {
        // We've occasionally put un-parseable stuff in local/session/storage
        const text = StorageHelper.getRawStorageProperty(storage, property);
        if (text !== null) {
            try {
                const _parsed = JSON.parse(text);
            }
            catch (_err) {
                console.log(`Replacing malformed property ${property}`);
                StorageHelper.setStorageProperty(storage, property, value);
                return true;
            }
        }
        return false;
    },
    // Local storage helpers
    // Set an item in local Storage
    setLocalStorageProperty: function (property, value) {
        StorageHelper.setStorageProperty('localStorage', property, value);
    },
    // Clear an item in local Storage
    clearLocalStorageProperty: function (property) {
        StorageHelper.clearStorageProperty('localStorage', property);
    },
    // Get an item in local Storage
    getLocalStorageProperty: (property) => StorageHelper.getStorageProperty('localStorage', property),
    // Get an item in local Storage
    getLocalStoragePropertyDefault: (property, defaultValue) => StorageHelper.getStoragePropertyDefault('localStorage', property, defaultValue),
    // Return true if existing malformed property was replaced by value
    replaceMalformedLocalStorageProperty: function (property, value) {
        return StorageHelper.replaceMalformedStorageProperty('localStorage', property, value);
    },
    // Session Storage Helper
    // Set an item in session Storage
    setSessionStorageProperty: function (property, value) {
        StorageHelper.setStorageProperty('sessionStorage', property, value);
    },
    // Clear an item in session Storage
    clearSessionStorageProperty: function (property) {
        StorageHelper.clearStorageProperty('sessionStorage', property);
    },
    // Get an item in session Storage
    getSessionStorageProperty: (property) => StorageHelper.getStorageProperty('sessionStorage', property),
    // Get an item in session Storage
    getSessionStoragePropertyDefault: (property, defaultValue) => StorageHelper.getStoragePropertyDefault('sessionStorage', property, defaultValue),
    // Return true if existing malformed property was replaced by value
    replaceMalformedSessionStorageProperty: function (property, value) {
        return StorageHelper.replaceMalformedStorageProperty('sessionStorage', property, value);
    },
    // Cookies
    setSecureCookie(name, value, expirationDays, path) {
        const domain = window.location.hostname;
        let expiration = null;
        if (expirationDays !== null && expirationDays !== undefined) {
            expiration = new Date();
            expiration.setTime(expiration.getTime() + expirationDays * 24 * 60 * 60 * 1000);
        }
        document.cookie = (0, string_helper_1.createSecureCookieString)(name, value, domain, expiration, path);
    },
    parseCookies(cookie) {
        return cookie
            .split('; ')
            .filter(x => x !== '')
            .map(x => x.split('='))
            .reduce((m, x) => {
            // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
            m[x[0]] = x[1];
            return m;
        }, {});
    },
    getCookie(name) {
        return StorageHelper.parseCookies(document.cookie)[name];
    },
    clearSecureCookie(name) {
        StorageHelper.setSecureCookie(name, 'v', -1);
    },
};
exports.default = StorageHelper;
