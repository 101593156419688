"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WithResourcesArrayInternal = exports.WithResourcesInternal = void 0;
const React = __importStar(require("react"));
const delayed_spinner_1 = require("@freckle/educator-entities/ts/common/components/spinner-wrapper/delayed-spinner");
function WithResourcesInternal({ resources, render, error, noSpinner = false, }) {
    const errors = Object.values(resources)
        .map((r) => r.error)
        .filter(r => r !== null);
    const isAllLoaded = Object.values(resources).every(r => r.status === 'success');
    const isReloading = Object.values(resources).some(r => r.isFetching);
    const data = Object.keys(resources).reduce((acc, r) => {
        acc[r] = resources[r].data;
        return acc;
    }, {});
    if (errors[0])
        return error(errors[0]);
    if (!isAllLoaded)
        return noSpinner ? React.createElement(React.Fragment, null) : React.createElement(delayed_spinner_1.DelayedSpinner, { inline: true });
    return (React.createElement(React.Fragment, null,
        isReloading && !noSpinner && React.createElement(delayed_spinner_1.DelayedSpinner, null),
        render(data)));
}
exports.WithResourcesInternal = WithResourcesInternal;
function WithResourcesArrayInternal({ resources, render, error, }) {
    const errors = Object.values(resources)
        .flat()
        .map((r) => r.error)
        .filter(r => r !== null);
    const isAllLoaded = Object.values(resources).every((r) => r.every(r => r.status === 'success'));
    const isReloading = Object.values(resources).some(r => r.isFetching);
    const data = Object.keys(resources).reduce((acc, r) => {
        acc[r] = resources[r].map(r => r.data);
        return acc;
    }, {});
    if (errors[0])
        return error(errors[0]);
    if (!isAllLoaded)
        return React.createElement(delayed_spinner_1.DelayedSpinner, { inline: true });
    return (React.createElement(React.Fragment, null,
        isReloading && React.createElement(delayed_spinner_1.DelayedSpinner, null),
        render(data)));
}
exports.WithResourcesArrayInternal = WithResourcesArrayInternal;
