"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = exports.H4 = exports.H3 = exports.H2 = exports.H1 = void 0;
const H1_1 = require("@freckle/educator-materials/ts/components/typography/H1");
Object.defineProperty(exports, "H1", { enumerable: true, get: function () { return H1_1.H1; } });
const H2_1 = require("@freckle/educator-materials/ts/components/typography/H2");
Object.defineProperty(exports, "H2", { enumerable: true, get: function () { return H2_1.H2; } });
const H3_1 = require("@freckle/educator-materials/ts/components/typography/H3");
Object.defineProperty(exports, "H3", { enumerable: true, get: function () { return H3_1.H3; } });
const H4_1 = require("@freckle/educator-materials/ts/components/typography/H4");
Object.defineProperty(exports, "H4", { enumerable: true, get: function () { return H4_1.H4; } });
const text_1 = require("@freckle/educator-materials/ts/components/typography/text");
Object.defineProperty(exports, "Text", { enumerable: true, get: function () { return text_1.Text; } });
