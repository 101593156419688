"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getResponsiveBreakpoint = exports.isScreenLargeDesktop = exports.isScreenDesktop = exports.isScreenTabletHorizontal = exports.isScreenTabletVertical = exports.isScreenPhoneHorizontal = exports.isScreenPhoneVertical = exports.isScreenVertical = void 0;
const responsive_breakpoints_1 = require("@freckle/educator-materials/ts/helpers/responsive-breakpoints");
function isScreenVertical() {
    return window.innerHeight > window.innerWidth;
}
exports.isScreenVertical = isScreenVertical;
function isScreenPhoneVertical() {
    return getScreenResponsiveBreakpoint() === 'phoneVertical';
}
exports.isScreenPhoneVertical = isScreenPhoneVertical;
function isScreenPhoneHorizontal() {
    return getScreenResponsiveBreakpoint() === 'phoneHorizontal';
}
exports.isScreenPhoneHorizontal = isScreenPhoneHorizontal;
function isScreenTabletVertical() {
    return getScreenResponsiveBreakpoint() === 'tabletVertical';
}
exports.isScreenTabletVertical = isScreenTabletVertical;
function isScreenTabletHorizontal() {
    return getScreenResponsiveBreakpoint() === 'tabletHorizontal';
}
exports.isScreenTabletHorizontal = isScreenTabletHorizontal;
function isScreenDesktop() {
    return getScreenResponsiveBreakpoint() === 'desktop';
}
exports.isScreenDesktop = isScreenDesktop;
function isScreenLargeDesktop() {
    return getScreenResponsiveBreakpoint() === 'largeDesktop';
}
exports.isScreenLargeDesktop = isScreenLargeDesktop;
function getScreenResponsiveBreakpoint() {
    return getResponsiveBreakpoint(window.innerWidth);
}
function getResponsiveBreakpoint(width) {
    if (width >= responsive_breakpoints_1.LARGE_DESKTOP_START) {
        return 'largeDesktop';
    }
    if (width > responsive_breakpoints_1.TABLET_HORIZONTAL_END) {
        return 'desktop';
    }
    if (width >= responsive_breakpoints_1.TABLET_VERTICAL_END) {
        return 'tabletHorizontal';
    }
    if (width >= responsive_breakpoints_1.PHONE_HORIZONTAL_END) {
        return 'tabletVertical';
    }
    if (width >= responsive_breakpoints_1.PHONE_VERTICAL_END) {
        return 'phoneHorizontal';
    }
    return 'phoneVertical';
}
exports.getResponsiveBreakpoint = getResponsiveBreakpoint;
