"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeXEcho = exports.ajaxSettingsWithEcho = exports.genericFetchWithEcho = exports.FetchEchoError = void 0;
const uuid_1 = require("uuid");
const maybe_1 = require("@freckle/maybe");
const paths_1 = require("@freckle/educator-entities/ts/common/helpers/paths");
const query_params_1 = require("@freckle/educator-entities/ts/common/helpers/routers/query-params");
const bugsnag_helper_1 = require("@freckle/educator-entities/ts/common/helpers/exception-handlers/bugsnag-helper");
// An error class for invalid echo responses. This is intended to add more
// visibility to errors reported to bug snag.
class EchoError extends Error {
    constructor(message, xhr) {
        super(`${message}\n${xhr.getAllResponseHeaders()}`);
        this.name = 'EchoError';
    }
}
class FetchEchoError extends Error {
    constructor(message, response) {
        // @ts-ignore https://app.asana.com/0/1203006199529183/1205043123990678/f
        super(`${message}\n${JSON.stringify(Object.fromEntries(response.headers.entries()))}`);
        this.name = 'EchoError';
    }
}
exports.FetchEchoError = FetchEchoError;
function appendParams(urlString, params) {
    // The UrlSearchParams type isn't supported on iOS < 10
    const searchParams = [];
    for (const [key, value] of Object.entries(params)) {
        searchParams.push(`${key}=${encodeURIComponent(value.toString())}`);
    }
    const url = new URL(urlString);
    const sep = url.search.trim() === '' ? '?' : '&';
    url.search += `${sep}${searchParams.join('&')}`;
    return url.toString();
}
const requestEcho = (0, uuid_1.v4)();
function genericFetchWithEcho(url, init) {
    return __awaiter(this, void 0, void 0, function* () {
        const urlWithEcho = (0, query_params_1.appendQueryStringToRoute)(removeXEcho(url), { 'x-echo': requestEcho });
        const response = yield fetch(urlWithEcho, init);
        const responseXEcho = response.headers.get('x-echo');
        if (responseXEcho !== null &&
            requestEcho !== responseXEcho &&
            response.status !== 503 &&
            response.status !== 504) {
            throw new FetchEchoError(`X-Echo expected "${requestEcho}", but found "${responseXEcho}"`, response);
        }
        return response;
    });
}
exports.genericFetchWithEcho = genericFetchWithEcho;
// Optional sides parameter to force the backend to randomly cause 503s
function ajaxSettingsWithEcho(sides, onEchoError, onError) {
    return {
        dataType: 'json',
        xhrFields: {
            // pass cookies along, useful only when talking to the API, the other
            // $.ajax calls often don't need it and it makes CORS more stringent
            withCredentials: true,
        },
        // Add x-echo to API routes and store it in the AJAX settings to
        // check in complete()
        beforeSend: function (_jqXHR, settings) {
            if (settings.url !== undefined) {
                if (sides !== null && sides !== undefined) {
                    settings.url = appendParams(settings.url, { sides, 'expose-headers': true });
                }
                if (settings.method !== 'OPTIONS' && settings.url.indexOf(paths_1.PATHS.unversionedAPIUrl) !== -1) {
                    //Cast settings to any so that we can add an _echo field to it
                    const settingsWithEcho = settings;
                    settingsWithEcho._echo = requestEcho;
                    //If the outgoing route already has an x-echo query param, we need to replace it with the new one.
                    //Example usecase: pagination link header URLs provided by backend include x-echo's that should be
                    //replaced with new ones.
                    settings.url = appendParams(removeXEcho(settings.url), { 'x-echo': requestEcho });
                }
            }
            //Cast settings to any so that we can stash the time that we queued the request
            const settingsWithTimes = settings;
            settingsWithTimes._requestStartTime = new Date();
            if (settings.xhr !== undefined) {
                const xhr = settings.xhr;
                settings.xhr = () => {
                    const output = xhr();
                    output.onreadystatechange = function () {
                        if (output.readyState === XMLHttpRequest.OPENED) {
                            // Stash the time that the request was OPENED so that we can log how long the request
                            // took on the server
                            settingsWithTimes._requestOpenedTime = new Date();
                        }
                    };
                    return output;
                };
            }
        },
        // If this._echo exists, check it against the X-Echo header
        complete: function (jqXHR) {
            const echo = this._echo;
            if (echo !== null && echo !== undefined) {
                const respEcho = jqXHR.getResponseHeader('X-Echo');
                if (respEcho !== null && echo !== respEcho) {
                    onEchoError(new EchoError(`X-Echo expected "${echo}", but found "${respEcho}"`, jqXHR));
                }
            }
        },
        error(jqXHR, textStatus, errorThrown) {
            const nowMs = new Date().getTime();
            const totalDurationMs = nowMs - this._requestStartTime;
            const serverDurationMs = (0, maybe_1.fromMaybe)(() => 0, (0, maybe_1.mthen)(this._requestOpenedTime, t => nowMs - t));
            (0, bugsnag_helper_1.leaveAjaxErrorBreadcrumb)({
                reqType: this.type,
                reqUrl: this.url,
                reqStatus: { status: jqXHR.status.toString(), textStatus },
                totalDurationMs,
                serverDurationMs,
                errorThrown,
            });
            (0, maybe_1.mthen)(onError, cb => cb(jqXHR));
        },
    };
}
exports.ajaxSettingsWithEcho = ajaxSettingsWithEcho;
function removeXEcho(urlString) {
    const url = new URL(urlString);
    if (url.searchParams.has('x-echo')) {
        url.searchParams.delete('x-echo');
    }
    return url.toString();
}
exports.removeXEcho = removeXEcho;
