"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const checkbox_1 = require("@freckle/educator-materials/ts/components/checkbox");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const container_1 = require("@freckle/educator-materials/ts/components/container");
const img_1 = require("@freckle/educator-materials/ts/components/img");
const typography_2 = require("@freckle/educator-materials/ts/components/typography");
const learn_more_1 = require("@freckle/school/ts/rostering/components/common/clean-up-row/learn-more");
const clean_up_row_module_scss_1 = require("@freckle/school/ts/rostering/components/common/clean-up-row/clean-up-row.module.scss");
function CleanUpRow(props) {
    const { src, title, description, children, isChecked, onChange, checkboxId, checkboxDataTest, subTitle, } = props;
    const subTitleNode = subTitle !== undefined ? React.createElement(typography_2.Text, { style: "subtitle-2" }, subTitle) : null;
    return (React.createElement(container_1.Container, null,
        React.createElement("div", { className: clean_up_row_module_scss_1.header },
            React.createElement("div", { className: clean_up_row_module_scss_1.checkboxWrapper },
                React.createElement(checkbox_1.Checkbox, { disabled: onChange === undefined, id: checkboxId, label: "", onChange: onChange ?? (() => { }), checked: isChecked, dataTest: checkboxDataTest })),
            React.createElement(img_1.Image, { className: clean_up_row_module_scss_1.imageWrapper, src: src }),
            React.createElement("div", null,
                React.createElement(typography_1.H3, null, title),
                React.createElement("div", null, subTitleNode),
                React.createElement("p", null, description),
                React.createElement("div", null,
                    React.createElement(learn_more_1.LearnMore, null, children))))));
}
exports.default = CleanUpRow;
