"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseStudentTableRows = void 0;
const roster_source_1 = require("@freckle/educator-entities/ts/users/logic/roster-source");
const parser_1 = require("@freckle/parser");
const identity_provider_1 = require("@freckle/educator-entities/ts/roster/identity-provider");
function parse(response) {
    const attrs = (0, parser_1.record)({
        id: (0, parser_1.number)(),
        firstName: (0, parser_1.string)(),
        lastName: (0, parser_1.string)(),
        sisId: (0, parser_1.nullable)((0, parser_1.string)()),
        grade: (0, parser_1.number)(),
        source: (0, parser_1.nullable)((0, parser_1.stringEnum)('RosterSourceT', roster_source_1.RosterSources.parse)),
        teacherIds: (0, parser_1.array)((0, parser_1.number)()),
        coursesCount: (0, parser_1.number)(),
        identityProvider: (0, parser_1.nullable)(identity_provider_1.parser),
    });
    return parser_1.Parser.run(response, attrs);
}
function parseStudentTableRows(response) {
    return response.map(parse);
}
exports.parseStudentTableRows = parseStudentTableRows;
