"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSelectorOption = exports.createSelectorOptionAsItem = exports.createSelectorGroupAsItem = void 0;
function createSelectorGroupAsItem(key, label, options) {
    return { tag: 'group', key, label, options };
}
exports.createSelectorGroupAsItem = createSelectorGroupAsItem;
function createSelectorOptionAsItem(key, label, disabled = false, dataTest = null) {
    const dataTestAttr = dataTest === null ? {} : { dataTest };
    return Object.assign({ tag: 'option', key, disabled, label }, dataTestAttr);
}
exports.createSelectorOptionAsItem = createSelectorOptionAsItem;
function createSelectorOption(key, label, disabled = false, dataTest = null) {
    const dataTestAttr = dataTest === null ? {} : { dataTest };
    return Object.assign({ tag: 'option', key, disabled, label }, dataTestAttr);
}
exports.createSelectorOption = createSelectorOption;
