"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveSelfRosteredTeachersContainer = void 0;
const React = __importStar(require("react"));
const translate_1 = require("@freckle/educator-materials/ts/helpers/translate");
const react_hooks_1 = require("@freckle/react-hooks");
const page_wrapper_1 = require("@freckle/school/ts/common/components/common/page-wrapper");
const routes_1 = require("@freckle/school/ts/common/routers/routes");
const self_rostered_teachers_removed_confirmation_1 = require("@freckle/school/ts/rostering/components/clean-up-roster/remove-self-rostered-teachers/self-rostered-teachers-removed-confirmation");
const html_text_1 = require("@freckle/educator-entities/ts/common/models/html-text");
const review_self_rostered_teachers_1 = require("./review-self-rostered-teachers");
function RemoveSelfRosteredTeachersContainer(props) {
    const { currentSchoolIds, schools } = props;
    const [removedSelfRosteredTeachers, setRemovedSelfRosteredTeachers] = React.useState(0);
    const setRemovedSelfRosteredTeachersCB = (0, react_hooks_1.useSafeCallback)(() => setRemovedSelfRosteredTeachers, []);
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const { t } = (0, translate_1.useSafeTranslation)();
    const breadcrumbsConfig = {
        currentItemName: (0, html_text_1.spanDangerous)(t('REMOVE_SELF_ROSTERED_REMOVE_SELF_ROSTERED_BREADCRUMB')),
        path: [
            {
                url: routes_1.Routes.dashboard(),
                name: t('REMOVE_SELF_ROSTERED_DASHBOARD_BREADCRUMB'),
            },
            {
                url: routes_1.Routes.rostering.cleanUpRosterLink(),
                name: t('REMOVE_SELF_ROSTERED_CLEAN_UP_BREADCRUMB'),
            },
        ],
        reactRouter: true,
    };
    const onRemoveAffiliationSuccess = (0, react_hooks_1.useSafeCallback)(() => () => setShowConfirmation(true), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(page_wrapper_1.PageWrapper, { title: t('REMOVE_SELF_ROSTERED_TEACHERS_TITLE'), breadcrumbsConfig: breadcrumbsConfig }, showConfirmation ? (React.createElement(self_rostered_teachers_removed_confirmation_1.RemoveSelfRosteredTeachersConfirmationWrapper, { schoolIds: currentSchoolIds, schools: schools, removedAffiliations: removedSelfRosteredTeachers })) : (React.createElement(review_self_rostered_teachers_1.ReviewSelfRosteredTeachers, { ...props, setRemovedSelfRosteredTeachersCB: setRemovedSelfRosteredTeachersCB, onRemoveAffiliationSuccess: onRemoveAffiliationSuccess })))));
}
exports.RemoveSelfRosteredTeachersContainer = RemoveSelfRosteredTeachersContainer;
