"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const maybe_1 = require("@freckle/maybe");
const use_schools_1 = require("@freckle/educator-entities/ts/common/hooks/use-schools");
const with_resources_1 = require("@freckle/school/ts/common/components/with-resources");
const query_params_1 = require("@freckle/educator-entities/ts/common/helpers/routers/query-params");
const utils_1 = require("@freckle/school/ts/manage-teachers/components/manage-teachers/utils");
const container_1 = require("@freckle/school/ts/manage-teachers/components/manage-teachers/container");
const react_router_dom_2 = require("react-router-dom");
const routes_1 = require("@freckle/school/ts/common/routers/routes");
function ManageTeachersHandler() {
    const location = (0, react_router_dom_1.useLocation)();
    const params = getQueryParams(location);
    const schools = (0, use_schools_1.useSchools)();
    return (React.createElement(with_resources_1.WithResources, { resources: { schools }, render: ({ schools }) => React.createElement(container_1.ManageTeachersContainer, { schools: schools, ...params }), error: () => React.createElement(react_router_dom_2.Navigate, { to: routes_1.Routes.noSchools(), replace: true }) }));
}
exports.default = ManageTeachersHandler;
function getQueryParams(location) {
    const paramMap = (0, query_params_1.extractQueryParams)(location);
    const pathSchoolIds = paramMap && paramMap.get('schoolIds');
    const schoolIds = (0, maybe_1.maybe)(() => null, pSchoolIds => (0, query_params_1.parseQueryParamNumArray)(pSchoolIds), pathSchoolIds);
    const position = (0, maybe_1.maybe)(() => null, pMap => pMap.get('position'), paramMap);
    const limit = (0, maybe_1.maybe)(() => null, pMap => {
        const val = pMap.get('limit');
        return val !== undefined ? parseInt(val, 10) : null;
    }, paramMap);
    const searchValue = (0, maybe_1.maybe)(() => null, pMap => pMap.get('searchValue'), paramMap);
    const activeTabPath = (0, maybe_1.maybe)(() => null, pMap => pMap.get('activeTab'), paramMap);
    const activeTab = activeTabPath !== null && activeTabPath !== undefined ? (0, utils_1.parseTab)(activeTabPath) : null;
    const tableParams = { position, limit, searchValue };
    return { schoolIds, activeTab, tableParams };
}
