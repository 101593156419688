"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFileButton = void 0;
const React = __importStar(require("react"));
const button_1 = require("@freckle/educator-materials/ts/components/button");
const style = __importStar(require("./upload-file-button.module.scss"));
function UploadFileButton(props) {
    const { isDisabled = false, buttonText, onPostFileUpload } = props;
    let hiddenInputElement = null;
    function uploadFile(e) {
        // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
        const fileInputValue = e.target.files[0];
        onPostFileUpload(fileInputValue);
    }
    function onButtonClick() {
        if (hiddenInputElement !== null) {
            //used to force onChange event to fire on every file upload even if file is the same.
            hiddenInputElement.value = '';
            hiddenInputElement.click();
        }
    }
    return (React.createElement("div", { className: style.uploadInputWrapper },
        React.createElement("input", { type: "file", onChange: uploadFile, ref: elem => (hiddenInputElement = elem), className: style.inputElement }),
        React.createElement(button_1.Button, { style: "secondary", onClick: onButtonClick, disabled: isDisabled, dataTest: "upload-file-button" }, buttonText)));
}
exports.UploadFileButton = UploadFileButton;
