"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTeachersAsASchoolAdmin = void 0;
const use_async_1 = require("@freckle/educator-entities/ts/common/hooks/use-async");
const teacher_1 = require("@freckle/educator-entities/ts/users/models/teacher");
function useTeachersAsASchoolAdmin(options) {
    const defaultOptions = { staleTime: 60 * 1000 };
    return (0, use_async_1.useAsync)(teacher_1.fetchTeachersAsASchoolAdmin, ['fetchTeachersAsASchoolAdmin'], Object.assign(Object.assign({}, defaultOptions), options));
}
exports.useTeachersAsASchoolAdmin = useTeachersAsASchoolAdmin;
