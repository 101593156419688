"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressBar = void 0;
const React = __importStar(require("react"));
const exhaustive_1 = require("@freckle/exhaustive");
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const progress_bar_module_scss_1 = require("./progress-bar.module.scss");
function ProgressBar(props) {
    const { label, showLabel = false, labelPosition = 'dynamic', value, min = 0, max = 100, wrapperAddClass, addClass, style = 'success', size = 'md', filledWidth, } = props;
    const labelClass = (0, classnames_1.addIfClassNames)([
        [true, progress_bar_module_scss_1.labelText],
        [labelPosition === 'static', progress_bar_module_scss_1.staticLabelPositionStyle],
        [!showLabel, progress_bar_module_scss_1.screenReaderOnly],
    ]);
    const className = (0, classnames_1.addIfClassNames)([
        [true, getStyleClassName(style)],
        [size === 'lg', progress_bar_module_scss_1.lgProgressBar],
        [addClass !== undefined, addClass],
    ]);
    const computedWidth = ((value - min) / (max - min)) * 100;
    const widthAttr = filledWidth !== null && filledWidth !== void 0 ? filledWidth : { width: `${computedWidth}%` };
    return (React.createElement("div", { className: (0, classnames_1.addMaybeClassName)(progress_bar_module_scss_1.progressBarWrapper, wrapperAddClass), "data-test": 'progress-bar-wrapper', role: "progressbar", "aria-valuemin": min, "aria-valuemax": max, "aria-valuenow": value },
        React.createElement("figure", { role: "progressbar", className: `${progress_bar_module_scss_1.progressBar} ${className}`, "aria-valuenow": value, "aria-valuemin": min, "aria-valuemax": max, style: Object.assign({}, widthAttr) },
            React.createElement(typography_1.Text, { style: "caption-1", addClass: labelClass, htmlElement: "h1" }, label))));
}
exports.ProgressBar = ProgressBar;
function getStyleClassName(style) {
    switch (style) {
        case 'default':
            return progress_bar_module_scss_1.defaultProgressBar;
        case 'success':
            return progress_bar_module_scss_1.successProgressBar;
        case 'primary':
            return progress_bar_module_scss_1.primaryProgressBar;
        case 'warning':
            return progress_bar_module_scss_1.warningProgressBar;
        case 'danger':
            return progress_bar_module_scss_1.dangerProgressBar;
        case 'info':
            return progress_bar_module_scss_1.infoProgressBar;
        default:
            return (0, exhaustive_1.exhaustive)(style, 'StyleT');
    }
}
