"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatOperations = exports.mathFactPracticeOperationTypeParser = void 0;
const maybe_1 = require("@freckle/maybe");
const string_helper_1 = require("@freckle/educator-entities/ts/common/helpers/string-helper");
const fact_practice_operation_1 = require("@freckle/educator-entities/ts/curricula-api/generated-client/enums/fact-practice-operation");
const parser_1 = require("@freckle/parser");
exports.mathFactPracticeOperationTypeParser = (0, parser_1.mapStatic)((0, parser_1.string)(), fact_practice_operation_1.FactPracticeOperations.unsafeParse);
function formatOperations(allOperations, operations) {
    const opStrings = operations.map(operation => (0, maybe_1.fromJust)((0, maybe_1.mmap)(fullOp => fullOp.description, allOperations.find(fullOp => fullOp.operation === operation)), `Can't find ${operation} in all operations`));
    return (0, maybe_1.fromJust)((0, string_helper_1.humanReadableCommaJoin)(opStrings), `Fact Practice assignment with empty operations array`);
}
exports.formatOperations = formatOperations;
