"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCourses = void 0;
const use_async_1 = require("@freckle/educator-entities/ts/common/hooks/use-async");
const course_1 = require("@freckle/educator-entities/ts/roster/models/course");
function useCourses(options) {
    const defaultOptions = { staleTime: 60 * 1000 };
    return (0, use_async_1.useAsync)(course_1.fetchCourses, ['fetchCourses'], Object.assign(Object.assign({}, defaultOptions), options));
}
exports.useCourses = useCourses;
