"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseResumableArticleContents = exports.parseArticleContents = void 0;
const parser_1 = require("@freckle/parser");
exports.parseArticleContents = (0, parser_1.record)({
    articleUuid: (0, parser_1.string)(),
    articleLevelId: (0, parser_1.string)(),
    startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
});
exports.parseResumableArticleContents = (0, parser_1.record)({
    articleUuid: (0, parser_1.string)(),
    articleLevelId: (0, parser_1.string)(),
    startsAt: (0, parser_1.nullable)((0, parser_1.date)()),
    coinsEarned: (0, parser_1.number)(),
});
