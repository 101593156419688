"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpandChevronButton = void 0;
const React = __importStar(require("react"));
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const button_1 = require("@freckle/educator-materials/ts/components/button");
const expand_chevron_button_module_scss_1 = require("./expand-chevron-button.module.scss");
function ExpandChevronButton(props) {
    const { expand, addClass, iconExpand = 'down', iconCollapse = 'up', disabled, screenReaderText, ariaControls, } = props;
    const [onClick, expandButtonBaseClass] = disabled === true ? [() => { }, expand_chevron_button_module_scss_1.disabledButton] : [props.onClick, expand_chevron_button_module_scss_1.expandButton];
    const additionalButtonProps = Object.assign(Object.assign({}, (screenReaderText !== undefined
        ? { ariaLabel: expand ? screenReaderText.expand : screenReaderText.collapse }
        : {})), (ariaControls !== undefined ? { ariaControls } : {}));
    const buttonClass = (0, classnames_1.addMaybeClassName)(expandButtonBaseClass, addClass);
    return (React.createElement(button_1.Button, Object.assign({ onClick: onClick, style: "tertiary", addClass: buttonClass, dataTest: "expand-chevron-button", disabled: disabled, ariaExpanded: expand }, additionalButtonProps), expand ? renderIconWithDirection(iconExpand) : renderIconWithDirection(iconCollapse)));
}
exports.ExpandChevronButton = ExpandChevronButton;
function renderIconWithDirection(direction) {
    switch (direction) {
        case 'up':
            return React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faChevronUp });
        case 'down':
            return React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faChevronDown });
        case 'left':
            return React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faChevronLeft });
        case 'right':
            return React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faChevronRight });
        default:
            throw new Error(`Unrecognized chevron direction: ${direction}`);
    }
}
