"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cellFromRow = exports.columnWhen = exports.idAccessorFromProp = exports.disableSortBy = exports.aligned = void 0;
function aligned(alignment) {
    return { alignment };
}
exports.aligned = aligned;
exports.disableSortBy = true;
function idAccessorFromProp(prop) {
    return {
        accessor: originalRow => originalRow[prop],
        id: prop.toString(),
    };
}
exports.idAccessorFromProp = idAccessorFromProp;
function columnWhen(condition, column) {
    return condition ? [column] : [];
}
exports.columnWhen = columnWhen;
function cellFromRow(mkComponent) {
    function Cell(props) {
        const original = props.cell.row.original;
        return mkComponent(original);
    }
    return Cell;
}
exports.cellFromRow = cellFromRow;
