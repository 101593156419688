"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromParams = void 0;
const query_params_1 = require("@freckle/query-params");
const maybe_1 = require("@freckle/maybe");
/**
 * Turn a ParamsT into a query string to be tacked onto a generic assignment sessions URL
 */
function fromParams(params) {
    const pairs = (0, maybe_1.catMaybes)([
        `math-standard-set-id=${params.mathStandardSetId}`,
        (0, maybe_1.mmap)(elaStandardSetId => `ela-standard-set-id=${elaStandardSetId}`, params.elaStandardSetId),
        (0, maybe_1.mmap)(isRetry => (isRetry ? 'is-assignment-retry' : 'is-not-assignment-retry'), params.isRetry),
        (0, maybe_1.mmap)(isSelfAssigned => (isSelfAssigned ? 'self-assigned' : 'teacher-assigned'), params.isSelfAssigned),
        (0, maybe_1.mmap)(isComplete => (isComplete ? 'complete' : 'incomplete'), params.isComplete),
        (0, maybe_1.mmap)(isTeacherAssignedOrRetry => (isTeacherAssignedOrRetry ? 'is-teacher-assigned-or-retry' : ''), params.isTeacherAssignedOrRetry),
        (0, maybe_1.mmap)(({ from, to }) => `from=${from.valueOf()}&to=${to.valueOf()}`, params.timeSpan),
        (0, maybe_1.mmap)(x => (0, query_params_1.createAPIQueryParams)((0, query_params_1.toQueryParamObj)('completed-at', x)), params.completedAt),
        (0, maybe_1.mmap)(assignmentIds => {
            const queryParams = { assignmentIds };
            return (0, query_params_1.createAPIQueryParams)(queryParams);
        }, params.assignmentIds),
        (0, maybe_1.mmap)(x => (0, query_params_1.createAPIQueryParams)((0, query_params_1.toQueryParamObj)('accuracy', x)), params.accuracy),
        (0, maybe_1.mmap)(decodablesUuid => `metadata.decodables-uuid=${decodablesUuid}`, params.decodablesUuid),
        (0, maybe_1.mmap)(studentId => `student-id=${studentId}`, params.studentId),
    ]);
    return pairs.length === 0 ? '' : `?${pairs.join('&')}`;
}
exports.fromParams = fromParams;
