"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = void 0;
const React = __importStar(require("react"));
const exhaustive_1 = require("@freckle/exhaustive");
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const typography_1 = require("@freckle/educator-materials/ts/components/typography");
const label_module_scss_1 = require("./label.module.scss");
/**
 * This is the standard component used for Labels.
 *
 */
function Label(props) {
    var _a;
    const { children, variant = 'label-1', addClass, htmlFor, dataTest } = props;
    const tag = (_a = props.as) !== null && _a !== void 0 ? _a : 'span';
    const baseName = getClassName(variant);
    const className = (0, classnames_1.addMaybeClassName)(baseName, addClass);
    const dataTestAttribute = dataTest !== undefined ? { 'data-test': dataTest } : {};
    return (React.createElement("label", Object.assign({ className: label_module_scss_1.baseLabel, htmlFor: htmlFor }, dataTestAttribute),
        React.createElement(typography_1.Text, { style: variant, htmlElement: tag, addClass: className }, children)));
}
exports.Label = Label;
function getClassName(variant) {
    switch (variant) {
        case 'label-1':
            return label_module_scss_1.label1;
        case 'label-2':
            return label_module_scss_1.label2;
        case 'label-3':
            return label_module_scss_1.label3;
        default:
            return (0, exhaustive_1.exhaustive)(variant);
    }
}
