"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCourseMemberships = void 0;
const use_async_1 = require("@freckle/educator-entities/ts/common/hooks/use-async");
const course_membership_1 = require("@freckle/educator-entities/ts/roster/models/course-membership");
function useCourseMemberships(options) {
    const defaultOptions = { staleTime: 60 * 1000 };
    return (0, use_async_1.useAsync)(course_membership_1.fetchCourseMemberships, ['fetchCourseMemberships'], Object.assign(Object.assign({}, defaultOptions), options));
}
exports.useCourseMemberships = useCourseMemberships;
