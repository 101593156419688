"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toString = exports.fromString = exports.parse = void 0;
const parser_1 = require("@freckle/parser");
exports.parse = parser_1.Parser.map((0, parser_1.string)(), 'RlSkillUspIdT.fromString', fromString);
function fromString(rlSkillUspId) {
    return rlSkillUspId;
}
exports.fromString = fromString;
const toString = (rlSkillUspId) => rlSkillUspId;
exports.toString = toString;
