"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Badge = void 0;
const React = __importStar(require("react"));
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const exhaustive_1 = require("@freckle/exhaustive");
const classnames_1 = require("@freckle/educator-entities/ts/common/helpers/classnames");
const badge_module_scss_1 = require("./badge.module.scss");
function Badge(props) {
    const { children, style = 'default', rounded = false, addClass, dataTest, leftIcon } = props;
    const leftIconNode = leftIcon !== undefined ? (React.createElement("span", { className: badge_module_scss_1.leftIconContainer },
        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: leftIcon }))) : null;
    const className = (0, classnames_1.addMaybeClassName)(getClassName(style, rounded), addClass);
    return (React.createElement("div", { className: className, "data-test": dataTest },
        leftIconNode,
        children));
}
exports.Badge = Badge;
function getClassName(style, rounded) {
    switch (style) {
        case 'default':
            return addBaseClass(badge_module_scss_1.defaultBadge, rounded);
        case 'primary':
            return addBaseClass(badge_module_scss_1.primary, rounded);
        case 'success':
            return addBaseClass(badge_module_scss_1.success, rounded);
        case 'warning':
            return addBaseClass(badge_module_scss_1.warning, rounded);
        case 'info':
            return addBaseClass(badge_module_scss_1.info, rounded);
        case 'danger':
            return addBaseClass(badge_module_scss_1.danger, rounded);
        case 'tag':
            return addBaseClass(badge_module_scss_1.tag, rounded);
        case 'premium':
            return addBaseClass(badge_module_scss_1.premium, rounded);
        case 'prerequisite':
            return addBaseClass(badge_module_scss_1.prerequisite, rounded);
        case 'mastered':
            return addBaseClass(badge_module_scss_1.mastered, rounded);
        case 'practicing':
            return addBaseClass(badge_module_scss_1.practicing, rounded);
        case 'not_practiced':
            return addBaseClass(badge_module_scss_1.notPracticed, rounded);
        default:
            return (0, exhaustive_1.exhaustive)(style, `Invalid badge style ${style}`);
    }
}
function addBaseClass(className, rounded) {
    return rounded ? `${badge_module_scss_1.pill} ${className}` : `${badge_module_scss_1.badge} ${className}`;
}
