"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchWithRetryAfter = exports.startOperation = exports.startOperationForm = void 0;
const parser_1 = require("@freckle/parser");
// N.B.: Currently we're only using delay-seconds; extra work is required to distinguish between date and string
const parseRetryAfter = (0, parser_1.firstOf)((0, parser_1.mapStatic)((0, parser_1.string)(), delaySeconds => ({
    tag: 'delay-seconds',
    contents: parseInt(delaySeconds, 10),
})), (0, parser_1.mapStatic)((0, parser_1.date)(), httpDate => ({ tag: 'http-date', contents: httpDate })));
class MissingOperationResponseHeaderError extends Error {
    constructor(expectedHeader, url) {
        super(`Expected header "${expectedHeader}" was not returned in by operation kickoff API: #{url}`);
        this.name = 'MissingOperationResponseHeaderError';
        this.expectedHeader = expectedHeader;
        this.url = url;
    }
}
const parseOperationResponse = (request, url, responseParser) => new Promise((resolve, reject) => {
    request
        .then((response, _textStatus, jqXHR) => {
        const requireHeader = (header) => {
            const value = jqXHR.getResponseHeader(header);
            if (typeof value !== 'string') {
                throw new MissingOperationResponseHeaderError(header, url);
            }
            return value;
        };
        try {
            resolve({
                response: parser_1.Parser.run(response, responseParser),
                retryAfter: parser_1.Parser.run(requireHeader('Retry-After'), parseRetryAfter),
                operationLocation: requireHeader('Operation-Location'),
            });
        }
        catch (error) {
            reject(error);
        }
    })
        .fail(jqXHR => {
        reject(jqXHR);
    });
});
const startOperationForm = (url, responseParser, data) => parseOperationResponse($.ajax({
    url,
    type: 'POST',
    data,
    contentType: false,
    processData: false,
    timeout: 0,
}), url, responseParser);
exports.startOperationForm = startOperationForm;
const startOperation = (method, url, responseParser) => parseOperationResponse($.ajax({
    url,
    type: method,
}), url, responseParser);
exports.startOperation = startOperation;
const parsableOperationStatuses = [
    'not_started',
    'running',
    'succeeded',
    'failed',
    // 'disappeared' is not present because it indicates 404 from the endpoint
];
const operationParser = (0, parser_1.record)({
    updatedAt: (0, parser_1.nullable)((0, parser_1.date)()),
    status: (0, parser_1.oneOf)('OperationStatus', parsableOperationStatuses),
});
const fetchWithRetryAfter = (url) => new Promise((resolve, reject) => {
    $.ajax({
        url,
        type: 'GET',
    })
        .then((response, _textStatus, jqXHR) => {
        try {
            const retryAfter = jqXHR.getResponseHeader('Retry-After');
            resolve({
                response: parser_1.Parser.run(response, operationParser),
                retryAfter: parser_1.Parser.run(retryAfter, (0, parser_1.nullable)(parseRetryAfter)),
            });
        }
        catch (error) {
            reject(error);
        }
    })
        .fail((jqXHR, _textStatus, errorThrown) => {
        if (jqXHR.status === 404) {
            resolve({
                response: {
                    updatedAt: null,
                    status: 'disappeared',
                },
                retryAfter: null,
            });
        }
        else {
            reject(new Error(errorThrown));
        }
    });
});
exports.fetchWithRetryAfter = fetchWithRetryAfter;
