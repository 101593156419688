"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdminSupportEmail = exports.getFAQLink = void 0;
const ADMIN_SUPPORT_EMAIL = 'mailto:customersuccess@freckle.com';
const UK_ADMIN_SUPPORT_EMAIL = 'mailto:support@renlearn.co.uk';
function getFAQLink(countryCode) {
    switch (countryCode) {
        case 'GBR':
        case 'IRL':
            return 'https://help.renlearn.co.uk/freckle';
        default:
            return 'https://help2.renaissance.com/freckle';
    }
}
exports.getFAQLink = getFAQLink;
function getAdminSupportEmail(countryCode) {
    switch (countryCode) {
        case 'GBR':
        case 'IRL':
            return UK_ADMIN_SUPPORT_EMAIL;
        default:
            return ADMIN_SUPPORT_EMAIL;
    }
}
exports.getAdminSupportEmail = getAdminSupportEmail;
