"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCourseTableRows = void 0;
const parser_1 = require("@freckle/parser");
const identity_provider_1 = require("@freckle/educator-entities/ts/roster/identity-provider");
function parse(response) {
    const studentSimplifiedAttrs = (0, parser_1.record)({
        id: (0, parser_1.number)(),
        firstName: (0, parser_1.string)(),
        lastName: (0, parser_1.string)(),
    });
    const attrs = (0, parser_1.record)({
        id: (0, parser_1.number)(),
        name: (0, parser_1.string)(),
        teacher: (0, parser_1.record)({
            id: (0, parser_1.number)(),
            firstName: (0, parser_1.string)(),
            lastName: (0, parser_1.string)(),
        }),
        students: (0, parser_1.array)(studentSimplifiedAttrs),
        grades: (0, parser_1.array)((0, parser_1.number)()),
        sisId: (0, parser_1.nullable)((0, parser_1.string)()),
        identityProvider: (0, parser_1.nullable)(identity_provider_1.parser),
    });
    return parser_1.Parser.run(response, attrs);
}
function parseCourseTableRows(response) {
    return response.map(parse);
}
exports.parseCourseTableRows = parseCourseTableRows;
